import { Injectable } from "@angular/core";
import { SharedService } from "../../core/services/shared.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Injectable()
export class ManagerProfileService {
  private apiUrl = "";

  constructor(private sharedService: SharedService, private http: HttpClient) {
    this.apiUrl = this.sharedService.getAPIUrl();
  }

  GetManagerProfileDetails() {
    return this.http.get(
      this.apiUrl + "/api/ManagerProfile/GetManagerProfileDetails"
    );
  }

  GetLevelLists(requestbody) {
    // const options = {
    //   headers: new HttpHeaders({
    //     'content-type': 'application/json'
    //   })
    // }
    // let body = JSON.stringify(requestbody);
    // return this.http.post(this.apiUrl + '/api/ManagerProfile/GetLevelLists', body, options);
    return this.http.get(
      this.apiUrl +
        "/api/ManagerProfile/GetLevelLists?fromDate=" +
        requestbody.fromDate +
        "&toDate=" +
        requestbody.toDate +
        "&levelSelected=" +
        requestbody.levelSelected
    );
  }

  GetComments(requestbody) {
    const options = {
      headers: new HttpHeaders({
        "content-type": "application/json",
      }),
    };
    let body = JSON.stringify(requestbody);
    return this.http.post(
      this.apiUrl + "/api/ManagerProfile/GetComments",
      body,
      options
    );
  }

  GetManagerResponseRate(requestbody) {
    const options = {
      headers: new HttpHeaders({
        "content-type": "application/json",
      }),
    };
    let body = JSON.stringify(requestbody);
    return this.http.post(
      this.apiUrl + "/api/ManagerProfile/GetManagerResponseRate",
      body,
      options
    );
  }

  GetDimensionWiseScore(requestbody) {
    const options = {
      headers: new HttpHeaders({
        "content-type": "application/json",
      }),
    };
    let body = JSON.stringify(requestbody);
    return this.http.post(
      this.apiUrl + "/api/ManagerProfile/GetDimensionWiseScore",
      body,
      options
    );
  }

  GetGMScoreAndManagerDetails(requestbody) {
    const options = {
      headers: new HttpHeaders({
        "content-type": "application/json",
      }),
    };
    let body = JSON.stringify(requestbody);
    return this.http.post(
      this.apiUrl + "/api/ManagerProfile/GetGMScoreAndManagerDetails",
      body,
      options
    );
  }

  GetStatementTopAndBottomThree(requestbody) {
    const options = {
      headers: new HttpHeaders({
        "content-type": "application/json",
      }),
    };
    let body = JSON.stringify(requestbody);
    return this.http.post(
      this.apiUrl + "/api/ManagerProfile/GetStatementTopAndBottomThree",
      body,
      options
    );
  }
  CheckAndCreateChatRequest(commentId, RecEmployeeId, ReqEmployeeId) {
    return this.http.get(
      this.apiUrl +
        "/api/Comments/CheckAndCreateChatRequest?commentId=" +
        commentId +
        "&RecEmployeeId=" +
        RecEmployeeId +
        "&ReqEmployeeId=" +
        ReqEmployeeId
    );
  }

  GetStatementWiseScore(requestbody) {
    const options = {
      headers: new HttpHeaders({
        "content-type": "application/json",
      }),
    };
    let body = JSON.stringify(requestbody);
    return this.http.post(
      this.apiUrl + "/api/ManagerProfile/GetStatementWiseScore",
      body,
      options
    );
  }
}
