import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PerceptionRoutingModule } from './perception-routing.module';
import { PerceptionComponent } from './perception.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from '@app/shared/modules/angular-material/material.module';

export const routes = [
  { path: '', component: PerceptionComponent, pathMatch: 'full' }
];

@NgModule({
  declarations: [PerceptionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RouterModule,
    PerceptionRoutingModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule
   //  SidenavModule
  ]
})
export class PerceptionModule {
  static routes = routes;
}

