import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { multi } from './data';

import { SurveyAPI } from '@app/shared/apis/survey.api';

@Component({
  selector: 'app-perception',
  templateUrl: './perception.component.html',
  styleUrls: ['./perception.component.scss']
})
export class PerceptionComponent implements OnInit {
  multi: any[];
  view: any[] = [1200, 600];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Culture Dimension';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Count';
  legendTitle: string = 'Your Scores vs. The Group';
  showDataLabel: boolean;
  showGridLines: boolean;
  legend: boolean = true;

  public employeeInformation: any;
  public employeeId: any;
  public clientId: any;
  public listOfTags: any;
  public listOfDimensions: any;
  public selectedGroup: any;
  public loading: boolean = false;
  public employeeScoresHttpObject: any = {};
  public groupScoresHttpObject: any = {};
  public graphData: any = [];
  public graphData2: any = [];
  public timerId: any;
  public counter1 = 0;
  public counter2 = 0;


  colorScheme = {
    domain: ['#08B3AB', '#FFDE08', '#ff0000']
  };

  constructor(private surveyApi: SurveyAPI) {
    this.view = [innerWidth, 400];
  }

  ngOnInit() {
    this.loading = true;
    this.employeeId = localStorage.getItem('employeeId');
    this.clientId = localStorage.getItem('clientId');

    // Fetching details of the employee
    this.surveyApi.getSurveyEmployeeTags(this.employeeId).subscribe((response) => {
      this.employeeInformation = response;
    }, (error) => {
      console.log(error);
    });


    this.employeeScoresHttpObject.surveyId = localStorage.getItem('surveyId');
    this.employeeScoresHttpObject.employeeId = this.employeeId;
    this.employeeScoresHttpObject.startDate = '2018-01-01';
    this.employeeScoresHttpObject.endDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.groupScoresHttpObject.clientId = localStorage.getItem('clientId');
    this.groupScoresHttpObject.employeeIdToBeExcluded = this.employeeId;
    this.groupScoresHttpObject.startDate = '2018-01-01';
    this.groupScoresHttpObject.endDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.getTagsList();


  }

  getTagsList() {
    this.surveyApi.getAllSubDimensionsAndTags(this.clientId).subscribe((response) => {
      this.listOfDimensions = response[0];
      this.listOfTags = [];
      for (let i = 1; i < Object.keys(response).length; i++) {
        if (!response[i][0].includes('Tag')) {
          this.listOfTags.push(response[i]);
        }
      }
      this.getIndividualScores();
    }, (error) => {});
  }

  onGroupSelect(selectedGroup: any) {

    if (selectedGroup !== undefined) {

      this.selectedGroup = selectedGroup;
      const listOfTags = [];
      this.listOfTags.forEach(element => {
        listOfTags.push(element[0]);
      });
      const indexOfSelectedTag = listOfTags.indexOf(selectedGroup);

      delete this.groupScoresHttpObject['tag1'];
      delete this.groupScoresHttpObject['tag2'];
      delete this.groupScoresHttpObject['tag3'];
      delete this.groupScoresHttpObject['tag4'];
      delete this.groupScoresHttpObject['tag5'];
      delete this.groupScoresHttpObject['tag6'];
      delete this.groupScoresHttpObject['tag7'];
      delete this.groupScoresHttpObject['tag8'];

      switch (indexOfSelectedTag) {
        case 0:
          this.groupScoresHttpObject['tag1'] = this.employeeInformation['tag1'];
          break;
        case 1:
          this.groupScoresHttpObject['tag2'] = this.employeeInformation['tag2'];
          break;
        case 2:
          this.groupScoresHttpObject['tag3'] = this.employeeInformation['tag3'];
          break;
        case 3:
          this.groupScoresHttpObject['tag4'] = this.employeeInformation['tag4'];
          break;
        case 4:
          this.groupScoresHttpObject['tag5'] = this.employeeInformation['tag5'];
          break;
        case 5:
          this.groupScoresHttpObject['tag6'] = this.employeeInformation['tag6'];
          break;
        case 6:
          this.groupScoresHttpObject['tag7'] = this.employeeInformation['tag7'];
          break;
        case 7:
          this.groupScoresHttpObject['tag8'] = this.employeeInformation['tag8'];
          break;
        default:
          //
      }
      // console.log(this.groupScoresHttpObject);
      this.graphData = [];
      this.graphData2 = [];

      this.getIndividualScores();
    }
  }

  showOrgScores() {
    this.selectedGroup = 'Org';
    delete this.groupScoresHttpObject['tag1'];
    delete this.groupScoresHttpObject['tag2'];
    delete this.groupScoresHttpObject['tag3'];
    delete this.groupScoresHttpObject['tag4'];
    delete this.groupScoresHttpObject['tag5'];
    delete this.groupScoresHttpObject['tag6'];
    delete this.groupScoresHttpObject['tag7'];
    delete this.groupScoresHttpObject['tag8'];
    this.graphData = [];
    this.graphData2 = [];
    this.getIndividualScores();
  }

  getIndividualScores() {
    this.loading = true;
    // Fetching the Dimensions & Tags
    this.listOfDimensions.forEach(element => {
      this.counter1++;
      this.employeeScoresHttpObject.dimension = element.key;
      this.surveyApi.getIndividualEngagementTrend(this.employeeScoresHttpObject).subscribe((data) => {
        let singleBarData: any = {};
        singleBarData.name = element.key;
        singleBarData.series = [{
          name: '',
          value : 0
        }];
        singleBarData.series[0].name = 'Your Score';
        singleBarData.series[0].value = Math.round(data[0][1]);
        this.graphData.push(singleBarData);
        this.counter1--;
      }, (error) => {
        console.log(error);
      });
    });
    this.getGroupScores();
  }

  public getGroupScores() {

    this.listOfDimensions.forEach(element => {
      this.counter2++;
      this.groupScoresHttpObject.dimension = element.key;
      this.surveyApi.GetSlicedScoresExcludingSpecificEmployee(this.groupScoresHttpObject).subscribe((data) => {
        let singleBarData: any = {};
        singleBarData.name = element.key;
        singleBarData.series = [{
          name: '',
          value : 0
        }];
        singleBarData.series[0].name = 'Group Score';
        singleBarData.series[0].value = Math.round(data[0][1]);
        this.graphData2.push(singleBarData);
        this.counter2--;
      }, (error) => {
        console.log(error);
      });
    });
    this.arrayMerger();
  }

  public arrayMerger() {
    this.timerId = setInterval(() => {
      if (this.counter1 === 0 && this.counter2 === 0) {

        for (let i = 0; i < this.graphData.length; i++) {
          for (let j = 0; j < this.graphData2.length; j++) {
            if (this.graphData[i].name === this.graphData2[j].name) {
              this.graphData[i].series.push(this.graphData2[j].series[0]);
            }
          }
        }
        this.graphData.sort();
        this.multi = this.graphData;
        // console.log(this.graphData);
        this.loading = false;
        clearInterval(this.timerId);
      }
      }, 1000);
  }

  onResize(event) {
    this.view = [event.target.innerWidth / 0.5, 150];
  }

}

  // updateXAxisWidth({width}): void {
  //   this.showXAxisLabel = width;
  //   this.update();
  // }

