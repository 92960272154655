<div class="my-container">
  <mat-sidenav-container class="my-sidenav-container">
     <mat-sidenav #sidenav closed mode="over" role="navigation" [fixedInViewport]="true">
     <!-- <mat-sidenav #sidenav mode="side" fixedInViewport fixedTopGap="72" role="navigation"> -->
     <app-sidebar *ngIf="sharedService.isUserLoggedIn" (closeSidenav)="sidenav.toggle()"></app-sidebar>
     </mat-sidenav>
     <mat-sidenav-content>
        <!-- && sharedService.currentLocation === '/survey-dashboard' -->
        <div class="main-content" style="z-index: 22222">
           <div style="background:#fff!important" *ngIf="sharedService.isUserLoggedIn">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10" [ngClass.gt-sm]="{ 'padding-10': true }">
                 <div *ngIf="clientId != 124">
                    <img  *ngIf="showProductBranding == true"
                       src="../../../assets/logo/logo_large_96x96.png"
                       style="height: 3rem;padding-top: 0.2rem;"
                       />
                 </div>
                 <span  *ngIf="clientId == 124" fxFlex></span>
                 <div class="search-div padding-10" *ngIf="value === 'show'" [@slideInOut] [ngClass.gt-sm]="{ 'padding-10': false, 'width-90': true }" fxLayout="row" fxLayoutAlign="space-between center">
                 <mat-icon fxFlex="10%" style="color:#999" matPrefix>search</mat-icon>
                 <input fxFlex class="search-input cursor-class" type="text" placeholder="Search"/>
                 <div fxFlex="8%" class="cursor-class" *ngIf="value === 'show'" (click)="value = ''">
                 <img src="../../../assets/icons/close.png" style="max-width: 1.25rem;float:right"/>
              </div>
           </div>
           <div fxLayout="row" fxLayoutGap="0.75rem">
            <div *ngIf="clientId != 124 && IsChatEnabled" fxLayout="row" fxLayoutAlign="center center" routerLink="chat" class="cursor-class">
               <mat-icon matBadge="{{ChatCount}}" [matBadgeHidden]="hideChatBadge" matBadgeOverlap="false" matBadgeColor="warn" class="matalign"> chat</mat-icon> 
           </div>
           <!-- <div *ngIf="IsManagerProfileEnabled" fxLayout="row" fxLayoutAlign="center center" routerLink="managerprofile" class="cursor-class">
             Manager Profile
        </div> -->
              <div *ngIf="clientId != 124" [matMenuTriggerFor]="menu" fxLayout="row" fxLayoutAlign="center center" class="cursor-class">
                 <!-- <img src="../../../assets/icons/user-icon.png" style="border-radius: 50%;" class="icon-style"> -->
                 <mat-icon>account_circle</mat-icon>
                 <span style="padding-left:0.5rem;line-height: 1.75rem">{{getEmployeeName()}}</span>
              </div>
              <div *ngIf="Logo">
                 <!-- <img src="../../../assets/icons/menu.png" (click)="sidenav.toggle()" class="icon-style cursor-class"> -->
                 <img src="{{ getLogo() }}" style="height: 3rem;padding-top: 0.2rem;"/>
              </div>
              <div *ngIf="!Logo" style="height: 3rem; width: 3rem;">
                 <img *ngIf="showProductBranding == true" src="../../../assets/icons/Lissen_io_default.png" style="height: 3rem;padding-top: 0.2rem;"/>
              </div>
              
           </div>
        </div>
</div>
</div>
<div id="main-app">
<app-main></app-main>
</div>
</mat-sidenav-content>
</mat-sidenav-container>
</div>
<mat-menu #menu="matMenu">
  <button
  *ngIf="isEngagementTrendButtonVisible"
  mat-menu-item
  (click)="engagement()"
  fxLayoutAlign="start center"
  fxLayoutGap="0.5rem"
  >
  <img
     src="../../../assets/images/graph.png"
     alt="log-out"
     style="width: 1.3rem;"
     />
  <span>{{ languageService.EngagementTrends }}</span>
  </button>
  <button
  *ngIf="IsManagerProfileEnabled && IsManager" 
  mat-menu-item
  routerLink="managerprofile"
  fxLayoutAlign="start center"
  fxLayoutGap="0.5rem"
  >
  <img
     src="../../../assets/images/manager.png"
     alt="log-out"
     style="width: 1.3rem;"
     />
  <span>Your Profile</span>
  </button>
  <button
  *ngIf="isPerceptionButtonVisible"
  mat-menu-item
  (click)="perception()"
  fxLayoutAlign="start center"
  fxLayoutGap="0.5rem"
  >
  <img
     src="../../../assets/images/perception.png"
     alt="log-out"
     style="width: 1.3rem;"
     />
  <span>{{ languageService.Perceptions }}</span>
  </button>
  <button
  *ngIf="showBadgeFeature === true"
  mat-menu-item
  (click)="badgesPage()"
  fxLayoutAlign="start center"
  fxLayoutGap="0.5rem"
  >
  <img
     src="../../../assets/images/badge-icon.png"
     alt=""
     style="width: 1.3rem;"
     />
  <span>{{ languageService.Badge }}</span>
  </button>
  <button
  mat-menu-item
  *ngIf="isSelectLanguageActive()"
  (click)="emitSelectedLanguageValue()"
  fxLayoutGap="0.5rem"
  fxLayoutAlign="start center"
  >
  <!-- <mat-icon style="margin-right: 0.5rem;color: #757575;">info</mat-icon> -->
  <img
     src="../../../assets/images/select-language.png"
     alt="select-language"
     style="width: 1.35rem;"
     />
  <span>{{ languageService.selectLanguage }}</span>
  </button>
  <button
     mat-menu-item
     (click)="openDialog(aboutUs)"
     fxLayoutAlign="start center"
     fxHide.gt-xs
     >
     <mat-icon style="margin-right: 0.5rem;color: #757575;">info</mat-icon>
     <span>{{ languageService.aboutUsText }}</span>
  </button>
  <button *ngIf="!hideLogout"
     mat-menu-item
     [routerLink]="['account/change-password']"
     fxLayoutAlign="start center"
     fxLayoutGap="0.5rem"
     >
  <img
     src="../../../assets/images/changePass.png"
     alt="change-password"
     style="width: 1.3rem;"
     />
  <span>{{ languageService.changePassword }}</span>
  </button>
  <button *ngIf="!hideLogout"
     mat-menu-item
     (click)="logout()"
     fxLayoutAlign="start center"
     fxLayoutGap="0.5rem"
     >
  <img
     src="../../../assets/images/log-out.png"
     alt="log-out"
     style="width: 1.3rem;"
     />
  <span>{{ languageService.logOut }}</span>
  </button>
</mat-menu>
<!-- ABOUT US MODAL -->
<ng-template #aboutUs>
  <div style="word-wrap: break-word;">
     <div fxLayout="row" class="text-mid">
        <div fxFlex="90%" fxLayoutAlign="center center">
           {{ languageService.aboutUsText }}
        </div>
        <div fxFlex="10%" fxLayoutAlign="end center">
           <button mat-icon-button (click)="closeModal()">
              <mat-icon>
                 close
              </mat-icon>
           </button>
        </div>
     </div>
     <div *ngIf="showProductBranding == true">
     <div  fxLayout="column" fxLayoutGap="0.5rem">
        <a 
           href="https://www.greatplacetowork.in/privacy-policy/"
           target="_blank"
           >{{ languageService.privacyPolicy }}</a
           >
        {{ languageService.emailText }}:
        <a (click)="mailUs()" class="cursor-class"
           >admin@ceoculturedashboard.com</a
           >
     </div>
   </div>
  </div>
</ng-template>
<div *ngIf="sharedService.isUserLoggedIn" fxHide.lt-sm>
  <app-footer></app-footer>
</div>
