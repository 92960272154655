import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { StorageService } from "@app/core/services/storage.service";
import { SharedService } from "@app/core/services/shared.service";
import { SurveyAPI } from "@app/shared/apis/survey.api";
import { EmployeeDataService } from "@app/core/services/employee-data.service";
import { LayoutComponent } from "../layout/layout.component";
import * as Bowser from "bowser";
import { formatDate } from "@angular/common";
import * as moment from "moment";
import swal from "sweetalert2";

import { AccountAPI } from "@app/shared/apis/account.api";
import { Router } from "@angular/router";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MainComponent implements OnInit {
  public employeeId: any;
  public browserName: any;
  public operatingSysyem: any;
  public devicePlatform: any;
  public ipAddress: any;
  today = new Date();
  userAgent = Bowser.parse(window.navigator.userAgent);
  @Output() valueChange = new EventEmitter();
  Logo: any;
  todaysDateTime: any;
  clientId: any;
  userLogRequestObject: {
    clientId: any;
    userId: any;
    loginDateTime: any;
    userRole: string;
    ipAddress: any;
    browser: any;
    platform: any;
    device: any;
  };
  expiryMessageShown = false;
  // tslint:disable-next-line: max-line-length
  constructor(
    private storageService: StorageService,
    private sharedService: SharedService,
    public surveyService: SurveyAPI,
    public employeeDataService: EmployeeDataService,
    public layoutComp: LayoutComponent,
    public accountApi: AccountAPI,
    public router: Router
  ) {}
  showSearch = false;
  ngOnInit() {
    this.valueChange.emit((this.showSearch = true));
  }

  onActivate(event) {}
}
