import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccountAPI } from '@app/shared/apis/account.api';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(public router: Router,  private activatedRoute: ActivatedRoute, private accountAPI: AccountAPI) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1)
    ).subscribe(() => {
      const url = this.activatedRoute.snapshot.url.join('/');
      const urlHasMagicKey = this.activatedRoute.snapshot.queryParamMap.has('magickey');

      if (urlHasMagicKey) {
        const logDetails = {
          errorMessage: 'Page Not Found Error',
          responseMessage: 'Page Not Found Error',
          magicURL: url,
          appType: this.isWebApp() ? 'Web App' : 'Desktop App'
        };
        this.saveErrorLogs(logDetails);
        this.router.navigate(["/incorrectlogindetails"], { replaceUrl: true });
      }

    });
    this.router.navigate(['/login']);
    localStorage.clear();
  }


  async saveErrorLogs(logDetails) {
    await this.accountAPI.saveErrorLogs(logDetails).subscribe(res => {
      
      console.log("Error Log saved Successfully");
    },
    error => {
      console.log("Getting error while saving logs", error);
      
    });
  }

  isWebApp(): boolean {
    return !navigator.userAgent.includes('Electron');
  }

}
