<div class="container" class="main-margin">
  <div align="center">
    <mat-card-title>
      <div>Engagement Trends</div>
    </mat-card-title>
    <mat-card-subtitle>
      <h3>Engagement trends display your feedback pattern over time. Interesting, isn’t it? Do you have any takeaways from what you see?</h3>
    </mat-card-subtitle>
    <br /> <br />
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutGap="1.5rem"
      fxLayoutGap.lt-sm="0.5rem"
      style="width:80%"
      fxLayoutAlign="left left"
    >
      <!-- CULTURE DIMENSION DROPDOWN LIST -->
      <mat-form-field fxFlex="39%">
        <mat-label style="font-weight: 500">Select Culture Dimension</mat-label>
        <mat-select
          style="font-weight: 500"
          [(ngModel)]="selectedCulture"
          id="selectedCulture"
          (ngModelChange)="onCultureSelected($event)"
        >
          <mat-option *ngFor="let dim of listofAllDimensions" [value]="dim">
            {{ dim }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div>
      <div fxLayoutAlign="center center" *ngIf="loading">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
      <div *ngIf="!loading">
        <ngx-charts-line-chart
          [view]="view"
          [scheme]="colorScheme"
          [results]="single"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"

          [curve]="curve"
          [autoScale]="autoScale"
          (window:resize)="onResize($event)"
        >
        </ngx-charts-line-chart>

      </div>
    </div>
  </div>
</div>
