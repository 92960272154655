import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    Route,
    Router,
    CanLoad
} from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor( private route: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let url: string = state.url;
        return this.checkLogin(url);
    }
    
    checkLogin(url: string) {
        // console.log(localStorage.getItem('access_token'));
        if (localStorage.getItem('access_token')) {
            return true;
        }
        else {
            this.route.navigate(['login']);
            return false;
        }
    }

}
