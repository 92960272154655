import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectivityService {
  isOnline: boolean = true;

  constructor() {
    this.isOnline = navigator.onLine; // Initial check when the service is instantiated

    // Add event listeners to monitor changes in online/offline status
    window.addEventListener('online', () => this.updateOnlineStatus(true));
    window.addEventListener('offline', () => this.updateOnlineStatus(false));
  }

  private updateOnlineStatus(status: boolean) {
    this.isOnline = status;
  }
}
