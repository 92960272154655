import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { EmployeeDataService } from '@app/core/services/employee-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-incorrectlogindetails',
  templateUrl: './incorrectlogindetails.component.html',
  styleUrls: ['./incorrectlogindetails.component.css']
})
export class IncorrectlogindetailsComponent implements OnInit {

  isApp: boolean = false;
  private hideLogoutSubscription: Subscription;
  message = 'redirecting to login page ...';

  constructor(public router: Router, private empService: EmployeeDataService) { }

  ngOnInit() {
    this.hideLogoutSubscription = this.empService.hideLogout.subscribe(value => {
      this.isApp = value;

      // let isWebApp = !navigator.userAgent.includes('Electron');

      /* If a user is using Desktop App and enters incorrect username or password 
      then its automatically closed the application in 5 seconds (For Cocoblue) */
      if (this.isApp) {
        this.message = 'Please wait...'
        return; 
      }
    
        
     setTimeout(() => {
        this.router.navigate(['/login'], { replaceUrl: true });
      }, 2000);
      

 
   
    });
  }


  // goToLoginPage() {
  //   this.router.navigate(['/login']);
  // }

}
