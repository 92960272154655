import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EngagementTrendComponent } from './engagement-trend.component';

const routes: Routes = [
  {path: 'engagement-trend', component: EngagementTrendComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EngagementTrendRoutingModule { }
