import { Component, OnInit, NgZone } from "@angular/core";
import { SwPush, SwUpdate } from "@angular/service-worker";
import { NotificationMiddlewareService } from "./core/services/notification-middleware.service";
import { environment } from "src/environments/environment";
import { RoutingState } from "./core/services/history-routing";
import Swal from "sweetalert2";
import { Router, NavigationEnd } from '@angular/router';
import { InternetConnectivityService } from "./internet-connectivity.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(
    private swPush: SwPush,
    private swUpdate: SwUpdate,
    private notificationMiddlewareService: NotificationMiddlewareService,
    private router: Router,
    private internetConnectivityService: InternetConnectivityService,
    routingState: RoutingState
  ) 
  {
    this.checkInternetConnection();
    routingState.loadRouting();}

  ngOnInit() {
    // if ('Notification' in window) {
    //   Notification.requestPermission(function (result) {
    //     console.log(result);
    //     if (result == "granted") {
    //      let noti = new Notification('You subscribed Successfully');
    //      noti.
    //     }

    //   })
    // }
    if (environment.production) {
      // this.notificationMiddlewareService.subscribeToNotification();

      // angular service worker has inbuilt mechanism to display notification. so no need to subscribe to notic=fication messages. Uncomment below line if you want custom notification display.

      // this.subscribeToMessages();

      // Notification click should me managed in service worker. Because when browser is closed, only code in service worker can execute. This is an upcoming feature in angular. Till then, let it be commnted.

      this.handleNotificationClick();

      // the below lines checks for updated version of tap-in. It auto-refreshes the app. To disable auto refresh, please comment window.location.reload() in the below function (checkForNewVersion).
      this.checkForNewVersion();
      this.swUpdate.checkForUpdate();
    }
  }

  /*****************************************************************
  checkForNewVersion()
  purpose : this method will check for updated service worker and prompt user to update the page
  *******************************************************************/
  public checkForNewVersion() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        Swal.fire(
          'Newer version of this application is now available. Please click on "OK" button to update the application.'
        ).then(() => {
          window.location.reload();
        });
      });
    }
  }

  /*****************************************************************
  subscribeToMessages()
  purpose : this method display call my custom middleware to show notifications. As angular service worker has inbuilt functionality to show push notification, this is not required now
  *******************************************************************/
  public subscribeToMessages() {
    this.swPush.messages.subscribe((response) => {
      console.log(response);
      this.notificationMiddlewareService.showNotification(
        response["title"],
        response["message"]
      );
    });
  }

  /*****************************************************************
  handleNotificationClick()
  purpose :  Method to trigger custom events after clicking on notification
  *******************************************************************/
  public handleNotificationClick() {
    this.swPush.notificationClicks.subscribe((response) => {
      console.log(response);
    });
  }


  private checkInternetConnection() {
    // Check internet connection when the app starts
    this.handleInternetConnection();

    // Monitor internet connection status
    window.addEventListener('online', () => this.handleInternetConnection());
    window.addEventListener('offline', () => this.handleInternetConnection());

    // Subscribe to router events to handle redirection when the user is on a different page
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleInternetConnection();
      }
    });
  }

  private handleInternetConnection() {
    if (!this.internetConnectivityService.isOnline) {
      // User is offline
      const currentUrl = this.router.url;
      if (currentUrl !== '/thank-you') {
        // Redirect to the "No Internet" page only if not already on that page
        this.router.navigate(['/thank-you'], { queryParams: { isInternetLost: true }});
      }
    }
  }
}
