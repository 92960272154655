import { Component, OnInit } from "@angular/core";
import * as shape from "d3-shape";
import { formatDate } from "@angular/common";

import { SurveyAPI } from "../../shared/apis/survey.api";

@Component({
  selector: "app-engagement-trend",
  templateUrl: "./engagement-trend.component.html",
  styleUrls: ["./engagement-trend.component.css"],
})
export class EngagementTrendComponent implements OnInit {
  public selectedCulture: string;
  culture: any[];

  single: any[];
  public view: any[] = [1000, 400];
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = false;
  public showXAxisLabel = true;

  public graphDataChart: any[];
  autoScale: true;
  public colorScheme = {
    domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
  };

  curve: any = shape.curveLinear;
  constructor(public surveyAPI: SurveyAPI) {
    this.view = [innerWidth / 1.3, 300];
  }

  public httpRequestObject = {
    surveyId: "",
    dimension: "",
    employeeId: "",
    startDate: "",
    endDate: "",
    frequency: "",
  };

  public listofAllDimensions: any = ["All"];
  public clientId: number;
  public data: any = [];
  public loading = true;

  ngOnInit() {
    this.clientId = +localStorage.getItem("clientId");
    this.getSubDimensions();

    // Subscribe to getEngagementTrendFlag ApI
    this.surveyAPI.getEngagementTrendFlag(this.clientId).subscribe(
      (response: any) => {
        console.log("API Response--" + response);
        let res = response;
        if (res == false) {
          console.log("INVALID clientID..");
        }
      },
      (error) => {
        console.log("Something went wrong!");
      }
    );
  }

  // public checkClientIdStatus() {
  //   this.surveyAPI.getEngagementTrendFlag(this.clientId).subscribe((response) => {
  //     console.log(response);
  //   });
  // }

  public getSubDimensions() {
    this.surveyAPI.getAllSubDimensionsAndTags(this.clientId).subscribe(
      (response) => {
        let dimAndSubDimArray: any[];
        dimAndSubDimArray = response[0];
        dimAndSubDimArray.forEach((element) => {
          this.listofAllDimensions.push(element.key);
        });
        this.httpRequestObject.surveyId = localStorage.getItem("surveyId");
        this.httpRequestObject.dimension = this.listofAllDimensions[1];
        this.httpRequestObject.employeeId = localStorage.getItem("employeeId");
        this.httpRequestObject.startDate = "2019-01-01";
        this.httpRequestObject.endDate = formatDate(
          new Date(),
          "yyyy-MM-dd",
          "en"
        );
        this.httpRequestObject.frequency = "month";
        this.selectedCulture = this.listofAllDimensions[1];
        this.getDataForGraph();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getDataForGraph() {
    let object = {};
    this.surveyAPI
      .getIndividualEngagementTrend(this.httpRequestObject)
      .subscribe(
        (response) => {
          object["name"] = "";
          object["series"] = [];

          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ];

          let responseArray: any = [];
          responseArray = response;

          responseArray.forEach((element3) => {
            const month =
              monthNames[element3[0].split("/")[0] - 1] +
              "-" +
              element3[0].split("/")[2].substring(2, 4);
            if (element3[1] === "NaN") {
              object["series"].push({
                name: month,
                value: 0,
              });
            } else {
              object["series"].push({
                name: month,
                value: Math.round(element3[1]),
              });
            }
          });
          this.data = [];
          this.data.push(object);
          object = {};
          this.single = this.data;
          this.loading = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // CULTURE DIMENSION CLICK EVENT
  onCultureSelected(selectedCulture: any) {
    this.loading = true;
    if (selectedCulture === "All") {
      delete this.httpRequestObject.dimension;
    } else {
      this.httpRequestObject.dimension = selectedCulture;
    }
    this.selectedCulture = selectedCulture;
    this.getDataForGraph();
  }

  onResize(event) {
    this.view = [event.target.innerWidth / 1.35, 400];
  }
}
