<div class="container" class="main-margin">

  <div align="center">
    <mat-card-title>
      <div>Perceptions</div>
    </mat-card-title>
    <mat-card-subtitle>
      <h3>The ‘Perceptions’ feature reflects your feedback pattern as compared to your peer group (Department, Grade, Team Members, Location, etc.). Do you have any takeaways from what you see?</h3>
    </mat-card-subtitle>
    <br />
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutGap="1.5rem"
      fxLayoutGap.lt-sm="0.5rem"
      style="width:90%"
      fxLayoutAlign="left left"
    >
      <!-- CULTURE DIMENSION DROPDOWN LIST -->
      <mat-form-field fxFlex="30%">
        <mat-label style="font-weight: 500">Select Grouping</mat-label>
        <mat-select
          style="font-weight: 500"
          [(ngModel)]="selectedGroup"
          id="selectedGroup"
          (ngModelChange)="onGroupSelect($event)"
        >
        <mat-option (click)="showOrgScores()">Org</mat-option>
          <mat-option *ngFor="let tag of listOfTags" [value]="tag[0]">
            {{ tag[0] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div>
      <div fxLayoutAlign="center center" *ngIf="loading">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
      <div *ngIf="!loading">
        <ngx-charts-bar-vertical-2d
          fxLayoutAlign="center"
          [view]="view"
          [scheme]="colorScheme"
          [results]="multi"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showGridLines]="true"
          [yScaleMax]=""
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [legend]="legend"
          [legendTitle]="legendTitle"
          [legendPosition]="'below'"
          [showDataLabel]="true"
          [barPadding]="5"
          [groupPadding]="7"
          [maxXAxisTickLength]="35"
          (window:resize)="onResize($event)"
        >
        </ngx-charts-bar-vertical-2d>
        <br />
        <br />
      </div>
    </div>
  </div>
</div>
