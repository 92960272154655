<mat-toolbar color="primary" >
  <mat-toolbar-row>
   <button mat-icon-button>
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <h1>GPTW</h1>
    <span class="topnav-right"></span>
    <div>
      <img  src="../../../assets/images/gptw-logo.png" style="height: 3rem;padding-top: 0.2rem;">
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<!-- SideBar -->
<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>

      <a mat-list-item [routerLink]="'/perception'" style="font-weight: 500"> Your Perception </a>
      <a mat-list-item [routerLink]="'/engagement-trend'" style="font-weight: 500"> Your Engagement Trend </a>
     
      <a mat-list-item (click)="sidenav.toggle()" mat-list-item>Close</a>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 88vh;">

      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>