import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharedService } from "@app/core/services/shared.service";

@Injectable({
  providedIn: "root"
})

export class PollsAPI {
    private apiUrl = "";
    private stsUrl = "";
  
    constructor(private sharedService: SharedService, private http: HttpClient) {
      this.apiUrl = this.sharedService.getAPIUrl();
      this.stsUrl = this.sharedService.getSTSUrl();
    }


  /*************************************************************************
        Description: This method will return true or false based on employee ID
    ************************************************************************/
   getRunningPolls(reqObject) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(reqObject);
    // console.log(body);
    return this.http.post(
      this.apiUrl + '/api/Polls/GetRunningPolls',
      body, options
    );
  }

    /********************************************************************
        Description: This method will get the Polls Question And Answer
    *********************************************************************/
   getPollToAnswer(reqObject) {
    const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
    const body = JSON.stringify(reqObject);
  // console.log(body);
    return this.http.post(
    this.apiUrl + '/api/PollQuestionBank/GetPollToAnswer',
    body,
    options
  );
  }

    /********************************************************************
        Description: This method will get the Polls Question And Answer
    *********************************************************************/
   submitPoll(reqObject) {
    const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
    const body = JSON.stringify(reqObject);
  // console.log(body);
    return this.http.post(
    this.apiUrl + '/api/Polls/SubmitPollResponse',
    body,
    options
  );
  }

    /********************************************************************
        Description: This method will mark Poll Completed for an employee
    *********************************************************************/
   markPollCompletedApi(reqObject) {
    const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
    const body = JSON.stringify(reqObject);
  // console.log(body);
    return this.http.post(
    this.apiUrl + '/api/Polls/MarkPollCompleted',
    body,
    options
  );
  }

    /*********************************************************************************************************
     Purpose: Get poll description
   **********************************************************************************************************/
     getPollDescription(surveyId) {
      return this.http.get(this.apiUrl + '/api/PollQuestionBank/GetPollDescription?surveyId=' + surveyId);
    }

}