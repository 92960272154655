import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as Bowser from 'bowser';
import { HttpClient } from '@angular/common/http';
import { SurveyAPI } from '@app/shared/apis/survey.api';
@Injectable({
    providedIn: 'root',
})
export class SharedService {

    private apiUrl = '';
    private stsUrl = '';

  // isUserLoggedIn : global variable to be used across app to check if user is logged in.
  public isUserLoggedIn = false;
  public isLoggedInSubject: BehaviorSubject<boolean>;
  public IsUser_LoggedIn: Observable<boolean>;
  public currentLocation = "";
  public isSurveyFetchedSubject: BehaviorSubject<boolean>;
  public isSurvey_Available: Observable<boolean>;
  public devicePlatform: string;
  public userAgent = Bowser.parse(window.navigator.userAgent);
    didYouKnowImageUrl: string;
    randomFactMessage: string;
    language: string;

    isMoodFlagSet = false;
  moodId: any;




    constructor(private snackBar: MatSnackBar, private http: HttpClient,) {
        this.apiUrl = environment.appConstants.apiUrl;
        this.stsUrl = environment.appConstants.stsUrl;

        this.language = localStorage.getItem('languange');

        if (localStorage.getItem('access_token') !== null) {
            this.isUserLoggedIn = true;
        }
        this.isLoggedInSubject = new BehaviorSubject<boolean>(this.isUserLoggedIn);
        this.IsUser_LoggedIn = this.isLoggedInSubject.asObservable();

        this.isSurveyFetchedSubject = new BehaviorSubject<boolean>(false);
        this.isSurvey_Available = this.isSurveyFetchedSubject.asObservable();



    }


    public setMood(val) {
       this.isMoodFlagSet = val;
  }
   public moodFlagCheck() {
    return this.isMoodFlagSet;
  }

  public setMoodId(val)
  {
    this.moodId = val;
  }

  public getMoodId()
  {
    return this.moodId;
  }



    /*****************************************************************
        getAPIUrl()
        purpose : this method will return the value of apiUrl
    *******************************************************************/
    public getAPIUrl() {
        return this.apiUrl;
    }

    /*****************************************************************
        getAPIUrl()
        purpose : this method will return the value of stsUrl
    *******************************************************************/
    public getSTSUrl() {
        return this.stsUrl;
    }
    /*****************************************************************
openSnackBar()
purpose : this method will openSnackBar
*******************************************************************/
    openSnackBar(message: string, action: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['snackbar-bg'];
        config.duration = 2000;
        this.snackBar.open(message, action, config);
    }
    /*****************************************************************
    openSnackBarError()
    purpose : this method will openSnackBarError
    *******************************************************************/
    openSnackBarError(message: string, action: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['snackbar-error'];
        config.duration = 2000;
        this.snackBar.open(message, action, config);
    }

   /*****************************************************************
    getUserDevice()
    purpose : this method will get the user device type from which the survey is accessed
    *******************************************************************/
   getUserDevice() : string {
    this.devicePlatform = this.userAgent.platform.type;
    // console.log(this.userAgent.platform.type);
    return this.devicePlatform;
}
    /********************************************************************************
   setDidYouKnowImageUrl(), getDidYouKnowImageUrl()
   purpose : this method will set and get the did You Know ImageUrl of Thank You Page
   ***********************************************************************************/
    public setDidYouKnowImageUrl(val) {
        this.didYouKnowImageUrl = val;
    }
    public getDidYouKnowImageUrl() {
        return this.didYouKnowImageUrl;
    }


    /********************************************************************************
  setRandomFact(), getRandomFact()
  purpose : this method will set and get the random fact
  ***********************************************************************************/

    public setRandomFact(val) {
        this.randomFactMessage = val;
    }

    public getRandomFact() {
        return this.randomFactMessage;
    }


    // This method will retun the random facts from the fact table
    getRandomFactData() {
        let obj: any = [];
        this.language = localStorage.getItem('languange');

        return this.http.get(this.apiUrl + '/api/SurveyApp/GetRandomFact').subscribe((res: any) => {
            let factsData = res.data;
            if(factsData)
            {
              let imgUlr = factsData[0].url;
              this.didYouKnowImageUrl = imgUlr;
            }
            // let imgUlr = factsData[0].url;

            factsData.forEach(element => {
                obj.push(element.facts);
            });
            if(this.language != "null") {
                let mesg = obj.find(f => f.languageCode == this.language).factTranslationMessage
                this.randomFactMessage = mesg
            }
           
        },
            error => {
                console.log("Api Error", error);
            });
    }

    getRandomFactDataForThankYouPage() {
        return new Promise((resolve, reject) => {
            let obj: any = [];
            this.language = localStorage.getItem('languange');
    
            this.http.get(this.apiUrl + '/api/SurveyApp/GetRandomFact').subscribe((res: any) => {
                let factsData = res.data;
                if(factsData)
                {
                  let imgUlr = factsData[0].url;
                  this.didYouKnowImageUrl = imgUlr;
                }
                // let imgUlr = factsData[0].url;
    
                factsData.forEach(element => {
                    obj.push(element.facts);
                });
                if(this.language != "null") {
                    let mesg = obj.find(f => f.languageCode == this.language).factTranslationMessage
                    this.randomFactMessage = mesg
                }
                resolve(true);
               
            },
                error => {
                    console.log("Api Error", error);
                    reject(false)
                });
        })
       
    }



}
