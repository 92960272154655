import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopnavComponent } from './topnav/topnav.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutComponent } from './layout/layout.component';
import { CustomMaterialModule } from '@app/shared/modules/angular-material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [TopnavComponent, SidebarComponent, FooterComponent, MainComponent, LayoutComponent],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
   // RouterModule.forChild(routes),
    CustomMaterialModule,
    FlexLayoutModule
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule {
  //  static routes = routes;
 }
