import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Renderer2,
  AfterViewInit,
} from "@angular/core";
import { SharedService } from "@app/core/services/shared.service";
import { trigger, transition, animate, style } from "@angular/animations";
import { NavigationEnd, Router } from "@angular/router";
import { EmployeeDataService } from "@app/core/services/employee-data.service";
import { SurveyAPI } from "@app/shared/apis/survey.api";
import { LanguageService } from "@app/core/services/language.services";
import { StorageService } from "@app/core/services/storage.service";
import { MatDialog } from "@angular/material/dialog";
import { AccountAPI } from "../../shared/apis/account.api";
import * as Bowser from "bowser";
import { formatDate } from "@angular/common";
import * as moment from "moment";
import swal from "sweetalert2";
import { FeedbackLoopAPI } from "@app/shared/apis/feedbackloop.api";
import { PollsAPI } from "@app/shared/apis/polls.api";
import { ManagerProfileService } from "@app/core/services/managerprofile.service";
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.css"],
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ opacity: "0" }),
        animate("500ms ease-in", style({ opacity: "1" })),
      ]),
      transition(":leave", [animate("200ms ease-in", style({ opacity: "0" }))]),
    ]),
  ],
})
export class LayoutComponent implements OnInit {
  showIcon = true;
  value;
  data = 6;
  public employeeName = "";
  public languages = [];
  public selectedLanguage;
  public clientId: number;
  isEngagementTrendButtonVisible = false;
  isPerceptionButtonVisible = false;
  public ChatCount;
  public isActionPointTrendVisible: any;
  Logo = true;
  Logo2: any;

  public pathName;
  public employeeId: any;
  public browserName: any;
  public operatingSysyem: any;
  public devicePlatform: any;
  public ipAddress: any;
  today = new Date();
  userAgent = Bowser.parse(window.navigator.userAgent);
  todaysDateTime: any;
  userLogRequestObject: {
    clientId: any;
    userId: any;
    loginDateTime: any;
    userRole: string;
    ipAddress: any;
    browser: any;
    platform: any;
    device: any;
  };

  showBadgeFeature: any;
  showMoodFeture: any;
  surveyId: any;
  employeeIdForPolls: any;
  pollIds: any = [];
  IsChatEnabled: boolean = false;
  hideChatBadge: boolean = true;
  IsManagerProfileEnabled: boolean = false;
  IsManager: boolean = false;
  showProductBranding: any;
  hideLogout = false;

  // tslint:disable-next-line:max-line-length
  constructor(
    public sharedService: SharedService,
    private router: Router,
    public employeeDataService: EmployeeDataService,
    public surveyService: SurveyAPI,
    public languageService: LanguageService,
    public storageService: StorageService,
    public dialog: MatDialog,
    public accountApi: AccountAPI,
    private changeDetRef: ChangeDetectorRef,
    public feedbackLoopAPI: FeedbackLoopAPI,
    private pollsAPI: PollsAPI,
    private managerprofileservice: ManagerProfileService,
    private titleService: Title, private metaService: Meta,
    private renderer: Renderer2,
    private empService: EmployeeDataService,
    private cdr: ChangeDetectorRef
  ) {
    this.languageService.setLanguagesText(
      this.storageService.getValue("languange")
    );
    var surveyAvailable = this.sharedService.isSurvey_Available.subscribe(
      (res) => {
        if (res) {
          // surveyAvailable.unsubscribe();
          this.clientId = +localStorage.getItem("clientId");
          // this.IsChatEnabledForClient();
          this.getManagerProfileDetails();

          // this.chatDetails();
          //this.fetchFlagsForLayoutComponent();
        }
      }
    );
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url == "/chat") {
          this.hideChatBadge = true;
        } else {
          this.hideChatBadge = false;
        }
        /* Your code goes here on every router change */
      }
    });
  }

  getManagerProfileDetails() {
    this.managerprofileservice.GetManagerProfileDetails().subscribe((res: any) => {
      if (res.status) {
        this.IsManagerProfileEnabled = res.isEnabledManagerProfile;
        this.IsManager = res.isManager;
      }
    });
  }

  chatDetails(): void {
    this.surveyService
      .GetChatDetails(0, localStorage.getItem("employeeId"), 0, 0)
      .subscribe(
        (response: any) => {
          let ongoingChatCount = 0;
          for (
            let index = 0;
            index < response.OnGoingChatDetails.length;
            index++
          ) {
            if (response.OnGoingChatDetails[index].unReadCount > 0) {
              ongoingChatCount +=
                response.OnGoingChatDetails[index].unReadCount;
            }
          }
          this.ChatCount = response.ReqChatDetails.length + ongoingChatCount;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  async ngOnInit() {

   let hideLogoutButton = JSON.parse(localStorage.getItem('hideLogout'));

    this.empService.hideLogout.subscribe(hideLogout => {
      this.hideLogout = hideLogout;
    });
    if(hideLogoutButton && hideLogoutButton == true) {
      this.hideLogout = true;
    }
    
    let token = this.storageService.getValue("access_token");
    let loginStatus = this.sharedService.isUserLoggedIn;
    if (token === null || loginStatus === false) {
      document.getElementById("main-app").classList.remove("main-margin");
    } else {
      document.getElementById("main-app").classList.add("main-margin");
    }
    this.getUserLoggedIn();

    this.sharedService.IsUser_LoggedIn.subscribe(async (resp) => {
      if (resp) {
        await this.getSurveyEmployeeInfo();
      }
    });
  }

  getLogo() {
    if (
      localStorage.getItem("Logo") === "null" ||
      localStorage.getItem("Logo") === null ||
      !localStorage.getItem("Logo") ||
      localStorage.getItem("Logo") == "undefined"
    ) {
      this.Logo = false;
      // console.log(localStorage.getItem("Logo"));
    } else {
      this.Logo = true;
      return localStorage.getItem("Logo");
    }

    this.changeDetRef.detectChanges();
  }

  fetchFlagsForLayoutComponent() {
    /* Subscribe to getEngagementTrendFlag ApI &
      check if IsEngagementFlag response is false then hide the Engagement Button   */
    this.surveyService.getEngagementTrendFlag(this.clientId).subscribe(
      (response: any) => {
        const res = response;
        if (res === false) {
          this.isEngagementTrendButtonVisible = false;
        } else {
          this.isEngagementTrendButtonVisible = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );

    /* Subscribe to getPerceptionFlag ApI &
      check if IsPerceptionFlag response is false then hide the Perception button   */
    this.surveyService.getPerceptionFlag(this.clientId).subscribe(
      (response: any) => {
        const res = response;
        if (res === false) {
          this.isPerceptionButtonVisible = false;
          // console.log('INVALID Client..')
        } else {
          this.isPerceptionButtonVisible = true;
          // console.log('VALID Client')
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.feedbackLoopAPI
      .getShowActionPointFlagValue(this.clientId)
      .subscribe((res) => {
        this.isActionPointTrendVisible = res["showActionPointFlag"];
      });
  }

  openDialog(modal): void {
    const dialogRef = this.dialog.open(modal, {
      width: "30rem",
    });
  }
  closeModal() { }
  // openLanguageDialog(modal): void {
  //   this.getLanguagesArray();

  //   const dialogRef = this.dialog.open(modal, {
  //     width: '20rem',
  //     height: '20rem'
  //   });
  // }

  mailUs() {
    const mailText = "mailto:admin@ceoculturedashboard.com";
    window.location.href = mailText;
  }

  emitSelectedLanguageValue() {
    this.languageService.isLanguageSelectedEventEmitter.emit(false);
  }

  getLanguagesArray() {
    let lang = this.storageService.getValue("languageFromApi").split(",");
    // console.log(lang);

    let object;
    let tempArray = [];

    for (let i = 0; i < lang.length; i++) {
      if (lang[i].trim() === "en") {
        object = { value: "en", viewValue: "English" };
        tempArray.push(object);
      } else if (lang[i].trim() === "hi") {
        object = { value: "hi", viewValue: "Hindi" };
        tempArray.push(object);
      } else if (lang[i].trim() === "mr") {
        object = { value: "mr", viewValue: "Marathi" };
        tempArray.push(object);
      } else if (lang[i].trim() === "bn") {
        object = { value: "bn", viewValue: "Bengali" };
        tempArray.push(object);
      } else if (lang[i].trim() === "te") {
        object = { value: "te", viewValue: "Telugu" };
        tempArray.push(object);
      } else if (lang[i].trim() === "ta") {
        object = { value: "ta", viewValue: "Tamil" };
        tempArray.push(object);
      } else if (lang[i].trim() === "gu") {
        object = { value: "gu", viewValue: "Gujarati" };
        tempArray.push(object);
      } else if (lang[i].trim() === "kn") {
        object = { value: "kn", viewValue: "Kannada" };
        tempArray.push(object);
      } else if (lang[i].trim() === "ml") {
        object = { value: "ml", viewValue: "Malayalam" };
        tempArray.push(object);
      } else if (lang[i].trim() === "pa") {
        object = { value: "ml", viewValue: "Punjabi" };
        tempArray.push(object);
      } else if (lang[i].trim() === "si") {
        object = { value: "si", viewValue: "Sinhala" };
        tempArray.push(object);
      } else if (lang[i].trim() === "ja") {
        object = { value: "ja", viewValue: "Japanese" };
        tempArray.push(object);
      } else if (lang[i].trim() === "ko") {
        object = { value: "ko", viewValue: "Korean" };
        tempArray.push(object);
      }
    }
    this.languages = tempArray;
  }

  isSelectLanguageActive() {
    let languageFromApi = localStorage.getItem("languageFromApi");
    if (languageFromApi !== null) {
      if (
        window.location.pathname === "/survey-dashboard" &&
        languageFromApi.includes(",")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  selectLanguageActive() {
    if (window.location.pathname === "/survey") {
      return true;
    } else {
      return false;
    }
  }

  getEmployeeName() {
    return localStorage.getItem("employeeName");
  }

  logout() {
    this.accountApi.logout().subscribe((response) => {
      this.accountApi.removeTokens();
      this.storageService.removeAll();
      this.sharedService.isUserLoggedIn = false;
      this.router.navigate(["/login"]);
    });
  }

  badges() {
    this.router.navigate(["/badges"]);
  }

  engagement() {
    this.router.navigate(["/engagement-trend"]);
  }

  perception() {
    this.router.navigate(["/perception"]);
  }
  actionCorner() {
    this.router.navigate(["/actioncorner"]);
  }
  async getSurveyEmployeeInfo() {
    this.sharedService.currentLocation = window.location.pathname;
    this.surveyService.getSurveyEmployeeInfo().subscribe(
      async (response) => {
        let surveyId = response["surveyId"];
        this.storageService.setValue("surveyId", response["surveyId"]);
        this.storageService.setValue("employeeName", response["name"]);
        this.storageService.setValue("employeeId", response["employeeId"]);
        this.storageService.setValue("clientId", response["clientId"]);
        this.employeeId = response["employeeId"];
        this.clientId = response["clientId"];

        if(this.clientId == 137) {
          //- changes made by Avi Verma
          //- date : 25-july-23
          //- reason : cocoblu has desktop app, and they must not logout from their app.
          //- As they logout, they do not know their cedentials , so they can't login again.
          //- discussed with client and amiya.

          //- We will pass this as a parameter in new version of Desktop App.
          this.hideLogout = true;
        }

        this.surveyId = response["surveyId"];
        this.employeeIdForPolls = response["employeeId"];
        await this.getSurvey(surveyId)
        await this.getPrefferedLanguage();
        await this.IsChatEnabledForClient();

        let showWelcomePage = localStorage.getItem("showWelcomePage");
        if (showWelcomePage != null && showWelcomePage == String(false)) {
          this.getLogo();
          this.router.navigate(["/survey"]);
          return;
        }


        let promiseList = [];
        // promiseList.push(this.getSurvey(surveyId));
        this.employeeDataService.employeeName = response["name"];
        // this.getPrefferedLanguage();
        this.getLogo();
        this.getRunningPollList();

        //promiseList.push(this.getPrefferedLanguage());
        // Promise.all(promiseList).then(() => {
        this.sharedService.isSurveyFetchedSubject.next(true);
        // });
        this.userLogData();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  userLogData() {
    let userAgentDetails = this.userAgent;
    // console.log("Log-Data" + userAgentDetails);
    this.browserName = userAgentDetails.browser.name;
    this.operatingSysyem = userAgentDetails.os.name;
    this.devicePlatform = userAgentDetails.platform.type;
    this.todaysDateTime = formatDate(
      this.today,
      "dd-MM-yyyy HH:mm:ss",
      "en-US",
      "+0530"
    );

    this.userLogRequestObject = {
      clientId: +this.clientId,
      userId: +this.employeeId,
      loginDateTime: this.todaysDateTime,
      userRole: "employee",
      ipAddress: this.ipAddress,
      browser: this.browserName,
      platform: this.operatingSysyem,
      device: this.devicePlatform,
    };

    this.surveyService
      .addUserLog(JSON.stringify(this.userLogRequestObject))
      .subscribe(
        (res) => {
          console.log("User log Entered Successfully");
        },
        (error) => {
          console.log("Api-Error- " + error);
        }
      );
  }

  getUserLoggedIn() {
    let token = localStorage.getItem("access_token");
    if (token !== null && window.location.pathname !== "/login") {
      this.sharedService.isUserLoggedIn = true;
      this.sharedService.isLoggedInSubject.next(true);
      return true;
    } else {
      this.sharedService.isUserLoggedIn = false;
      this.sharedService.isLoggedInSubject.next(false);
      return true;
    }
  }

  async getSurvey(surveyId) {
    return new Promise((resolve, reject) => {
      this.surveyService.getSurvey(surveyId).subscribe(
        async (response: any) => {
          // console.log(response);
          if (response.status) {
            let survey = response.data;
            this.Logo2 = survey;
            localStorage.setItem("showBranding", survey["showBranding"]);

            this.storageService.setValue(
              "languageFromApi",
              survey["languages"]
            );

            /// let logoUrl =  survey.logoUrl ? survey.logoUrl : ""
            this.storageService.setValue("surveyName", survey["name"]);
            let logoUrl = survey.logoUrl ? survey.logoUrl : ""
            localStorage.setItem(
              "Logo",
              logoUrl
            );
            this.storageService.setValue("surveyenddate", survey["endDate"]);
            this.storageService.setValue(
              "showfunfacts",
              survey["showfunfacts"]
            );
            localStorage.setItem("showBadges", survey["showBadges"]);
            this.showBadgeFeature = survey["showBadges"];

            localStorage.setItem("showMood", survey["showMood"]);
            // this.showMoodFeture = survey["showMood"];

            localStorage.setItem(
              "6th-option",
              survey["allOption"]
            );
            localStorage.setItem(
              "OptionChoice",
              survey["selectedOptions"]
            );

            const surveyEndDate = survey["endDate"];
            if (
              moment(surveyEndDate)
                .add(23, "hours")
                .add(59, "minutes")
                .isBefore(moment())
            ) {
              swal.fire({
                position: "center",
                title: "Oops...",
                html: "Your organization's subscription of the CEO Culture Dashboard has expired!",
                type: "warning",
              });
              this.accountApi.logout().subscribe((response) => {
                this.accountApi.removeTokens();
                this.storageService.removeAll();
                this.sharedService.isUserLoggedIn = false;
                this.router.navigate(["/login"]);
              });
            }

            let showBranding = localStorage.getItem("showBranding");
            this.showProductBranding = JSON.parse(showBranding);
            if ( this.showProductBranding == false) {
              this.titleService.setTitle('Survey App');
              this.metaService.removeTag('rel="icon"');
      

            }

            if (survey.showWelcomePage) {
              localStorage.setItem("showWelcomePage", survey.showWelcomePage);

              resolve(true);

            } else {
              localStorage.setItem("showWelcomePage", survey.showWelcomePage);
            }
            resolve(true);
          } else {
            this.sharedService.openSnackBarError(response.message, "");
          }
        },
        (error) => {
          console.log(error);
          reject(false);
        }
      );
    });
  }



  getPrefferedLanguage() {
    return new Promise((resolve, reject) => {
      this.employeeId = localStorage.getItem("employeeId");
      this.surveyService.getLanguage(this.employeeId).subscribe(
        (response) => {
          const lang = response;
          if (lang != null) {
            if (lang.length > 0) {
              localStorage.setItem("languange", lang);
            } else {
              localStorage.setItem("languange", "null");
            }
          }
          else {
            localStorage.setItem("languange", "null");

          }

          resolve(true);
        },
        (error) => {
          console.log("Invalid Language--" + error);
          reject(true);
        }
      );
    });
  }

  badgesPage() {
    this.router.navigate(["/badges"]);
  }

  getRunningPollList() {
    let runningPollObject = {
      clientId: +this.clientId,
      surveyId: +this.surveyId,
      employeeId: +this.employeeIdForPolls,
      date: new Date(),
    };

    this.pollsAPI.getRunningPolls(runningPollObject).subscribe(
      (res: any) => {
        this.pollIds = res;
        localStorage.setItem("pollId", JSON.stringify(this.pollIds));
      },
      (error) => {
        console.log("Api Error" + error);
      }
    );
  }

  /*Calling Chat Related API*/
  async IsChatEnabledForClient() {
    this.surveyService
      .IsChatEnabledForClient(localStorage.getItem("clientId"))
      .subscribe(
        (res: boolean) => {
          if (res) {
            this.IsChatEnabled = true;
            this.chatDetails();
          }
        },
        (error) => {
          console.log("Api Error--", error);
        }
      );
  }


}
