import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SharedService } from "@app/core/services/shared.service";

@Injectable({
  providedIn: "root"
})

export class FeedbackLoopAPI {
  private apiUrl = "";
  private stsUrl = "";

  constructor(private sharedService: SharedService, private http: HttpClient) {
    this.apiUrl = this.sharedService.getAPIUrl();
    this.stsUrl = this.sharedService.getSTSUrl();
  }

  /*************************************************************************************************
        Description: This method will fecth the flag value of ShowActionPoint
    ************************************************************************************************/

  getShowActionPointFlagValue(clientId) {
    return this.http.get(this.apiUrl + '/api/FeedbackLoop/getActionPointFlagsValue?clientId=' + clientId);
  }

  getActionPointAgainstSubDimension(object) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.http.post(
      this.apiUrl + '/api/FeedbackLoop/getSubDimensionActionPoints',
      object,
      options
    );
  }

  setActionPointStarRating(object) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.http.post(
      this.apiUrl + '/api/FeedbackLoop/setFeedbackLoopResponse',
      object,
      options
    );
  }

  /*************************************************************
Description: This method will submit action taken comment
**************************************************************/
  setActionPointComments(object) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.http.post(
      this.apiUrl + '/api/FeedbackLoop/setFeedbackLoopResponseComment',
      object,
      options
    );
  }

  getActionPointTrendTable(object) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.http.post(
      this.apiUrl + '/api/FeedbackLoop/getActionPointTrend',
      object,
      options
    );
  }
}
