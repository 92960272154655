import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CustomMaterialModule } from '@app/shared/modules/angular-material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EngagementTrendComponent } from './engagement-trend.component';
import { EngagementTrendRoutingModule } from './engagement-trend-routing.module';


export const routes = [
  { path: '', component: EngagementTrendComponent, pathMatch: 'full' }
];
@NgModule({
  declarations: [EngagementTrendComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RouterModule,
    NgxChartsModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    EngagementTrendRoutingModule
  ]
})
export class EngagementTrendModule {
  static routes = routes;
}
