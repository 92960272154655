import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from '@app/core/services/shared.service';
import { StorageService } from '../../core/services/storage.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AccountAPI {

  private apiUrl = '';
  private stsUrl = '';
  private readonly JWT_TOKEN = 'access_token';
  private readonly REFRESH_TOKEN = 'refresh_token';
  private readonly CLIENT_ID = 'ro.client';
  private readonly CLIENT_SECRET = 'secret';

  constructor(public sharedService: SharedService, private http: HttpClient, private storageService: StorageService) {
    this.apiUrl = this.sharedService.getAPIUrl();
    this.stsUrl = this.sharedService.getSTSUrl();
  }

  /*************************************************************
   Parameter: email and password
   Description: This method check for user login credentials
  **************************************************************/

  callLoginAPI(mail, pwd) {
    //const body = 'username=' + mail + '&password=' + pwd +
    //    '&grant_type=password&client_id=ro.client&client_secret=secret&scope=api1';

    const body = 'username=' + mail + '&password=' + pwd +
      '&grant_type=password&client_id=' + this.CLIENT_ID + '&client_secret=' + this.CLIENT_SECRET + '&scope=offline_access';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };
    return this.http.post(this.stsUrl + '/connect/token', body, httpOptions);
  }


  /*************************************************************
   Parameter: Invitatoin code
   Description: This method will get user details by invitation code
  **************************************************************/

  getEmployeeDetailsByInvitationCode(invitationcode: string) {
    return this.http.get(this.apiUrl + '/api/Survey/GetEmployeeDetailsByInvitationCode?code=' + invitationcode);
  }

  /*************************************************************
   Parametre: User registration details
   Description: This method will provide the user registration status
  **************************************************************/

  completeRegistration(registrationDetails: any) {
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    }
    let body = JSON.stringify(registrationDetails);
    return this.http.post(this.apiUrl + '/api/Survey/CompleteRegistration', body, options);
  }

  /*************************************************************
   Parametre: User email id
   Description: This method will check email exist or not
  **************************************************************/
  isEmailExist(email: string) {
    return this.http.get(this.apiUrl + '/api/Users/IsEmailExists?emailId=' + email)
  }



  /*************************************************************
     Parametre: forgetPasswordRequest
     Description: This method will request for reset the password
    **************************************************************/

  forgetPasswordRequest(request) {
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    }
    let body = JSON.stringify(request);
    return this.http.post(this.apiUrl + '/api/Employees/ForgotPasswordRequest', body, options);
  }

  /*************************************************************
     Parametre: resetPassword
     Description: This method will reset the password
    **************************************************************/
  resetPassword(request) {
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    }
    let body = JSON.stringify(request);
    return this.http.post(this.apiUrl + '/api/Employees/ResetForgotPassword', body, options);
  }

  /*************************************************************
     Parametre: changePassPassword
     Description: This method will change the password
    **************************************************************/
  changePassPassword(request) {
    const options = {
      headers: new HttpHeaders({
        'content-type': 'application/json'
      })
    }
    let body = JSON.stringify(request);
    return this.http.post(this.apiUrl + '/api/Employees/ChangePassword', body, options);
  }

  //New Added by Ashok on 15-07-2020

  getJwtToken() {
    return this.storageService.getValue(this.JWT_TOKEN);
  }

  getRefreshToken() {
    return this.storageService.getValue(this.REFRESH_TOKEN);
  }

  storeJwtToken(jwt: string) {
    this.storageService.setValue(this.JWT_TOKEN, jwt);
  }

  storeTokens(jwt: string, refreshToken: string) {
    this.storageService.setValue(this.JWT_TOKEN, jwt);
    this.storageService.setValue(this.REFRESH_TOKEN, refreshToken);
  }

  removeTokens() {
    this.storageService.removeKey(this.JWT_TOKEN);
    this.storageService.removeKey(this.REFRESH_TOKEN);
  }

  refreshToken() {
    const body = 'grant_type=refresh_token&client_id=' + this.CLIENT_ID + '&client_secret=' + this.CLIENT_SECRET + '&refresh_token=' + this.getRefreshToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };
    return this.http.post(this.stsUrl + '/connect/token', body, httpOptions).pipe(tap((response) => {
      this.storeTokens(response['access_token'], response['refresh_token']);
    }));
  }

  logout() {
    //const body = 'username=' + mail + '&password=' + pwd +
    //'&grant_type=password&client_id=ro.client&client_secret=secret&scope=api1';

    const body = 'token_type_hint=refresh_token&client_id=' + this.CLIENT_ID + '&client_secret=' + this.CLIENT_SECRET + '&token=' + this.getRefreshToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };
    return this.http.post(this.stsUrl + '/connect/revocation', body, httpOptions);
  }


  getLogInfoFromToken1(auth_token) {
    let webAPIRRL = 'https://www.icicisecurities.com/CandidateDocketAPI/api/Auth/ValidateJWTToken';
    const headers = new HttpHeaders({
      'content-type': 'application/json',
      'authorization': `Bearer ${auth_token}`
    });

    let body = {
      'action': 'ValidateToken'
    };


    return this.http.post(webAPIRRL, body, { headers: headers });
  }

  getLogInfoFromToken(token: string) {
    
    let webUrl = 'https://www.icicisecurities.com/CandidateDocketAPILIVE/api/auth/ValidateToken?token=';

    return this.http.get(webUrl+token);
  }

  saveErrorLogs(reqObject) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // const body = JSON.stringify(reqObject);
    return this.http.post(
      this.apiUrl + '/api/SurveyApp/SurveyAppErrorLogDetails',
      reqObject, options
    );
  }

  saveElectronAppErrorLogs(reqObject) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // const body = JSON.stringify(reqObject);
    return this.http.post(
      this.apiUrl + '/api/SurveyApp/ElectronAppErrorLogDetails',
      reqObject, options
    );
  }

}
