import { Injectable, EventEmitter } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public isLanguageSelectedEventEmitter: EventEmitter<any> = new EventEmitter();

  public languageChangedEventEmmiter: EventEmitter<any> = new EventEmitter();

  public welcomeText = "";
  public startSurveyText = "";
  public surveyCompletedAlready = "";
  public somethingWentWrong = "";
  public nextButton = "";
  public previousButton = "";
  public submitButton = "";
  public submittingButton = "";
  public tryAgainButton = "";
  public logOut = "";
  public selectLanguage = "";
  public writeHerePlaceHolder = "";
  public pleaseWaitWhileFetch = "";
  public aboutUsText = "";
  public thankYouText = "";
  public termsConditions = "";
  public privacyPolicy = "";
  public brandGuidelines = "";
  public contactNumber = "";
  public emailText = "";
  public changePassword = "";
  public Badge = "";
  public EngagementTrends = "";
  public Perceptions = "";
  public ActionCorner = "";
  public InvalidInputError = "";
  public openEndedPollPlaceholder = "";
  public floatingCommentLable ="";
  public actionPointDialogHeader = "";
  public feedbackRecallLabel = "";
  public  badgeTitle: string;
  public orgRank: string;
  public teamRank: string;
  public totalPoints: string;
  public Trailblazer:string;
  public Architect:string;
  public Engineer:string;
  public Chieftain: string;
  public Laureate: string;
  public Wordsmith: string;
  public Youwon: string;
  public badges: string;
  public PointsSystem: string;
  public Points: string;
  public Calculation: string;
  public BadgeName : string;
  public Description : string;
  public Howarepointscalculated : string;
  public Howteamrank : string;
  public HowOrgrank : string;
  public trailblazerContent:string;
  public architectContent:string;
  public engineerContent:string;
  public chieftainContent:string;
  public laureateContent:string;
  public wordsmithContent:string;
  public pointDescription1: string;
  public pointDescription2 : string;
  public pointDescription3 : string;
  public ok : string;
  public congratulations : string;
  public trailblazerCongratulations : string;

  public laureateCongratulations : string;
  public wordsmithCongratulations : string;
  public architectCongratulations : string;
  public chieftainCongratulations : string;

  public submitResponseNote : string;
  //Mood Feature
  public Anxious = "";
  public Uneasy = "";
  public Okay = "";
  public Happy = "";
  public Excited = "";

  public anxiousMoodLabel: string;
  public uneasyMoodLabel: string;
  public okayMoodLabel: string;
  public happyMoodLabel: string;
  public excitedMoodLabel: string;

  public anxiousSnackBar: string;
  public uneasySnackBar: string;
  public okaySnackBar: string;
  public happySnackBar: string;
  public excitedSnackBar: string;

  public whatsYourMood: string;
  public heresYourMood: string;

  public questionsLeft: string;
  public questionLeft: string;
  badgeSingular: string;
  thankYouComment: string;
  actionOnFeedbackCommentPlaceholder: string;
  comment: string;
  request: string;

  public sixthOptionText: string;
  public ICICISurveyCompletedAlready: string;

  public isLanguageSelectedListener() {
    return this.isLanguageSelectedEventEmitter;
  }

  public languageChangedEventListener() {
    return this.languageChangedEventEmmiter;
  }

  public setLanguagesText(language) {
    switch (language) {
      // English - en
      case "en":
        this.welcomeText = "Welcome";
        this.startSurveyText = "start survey now";
        this.surveyCompletedAlready = "Survey questions not available";
        this.somethingWentWrong = "Something went wrong, Please try again";
        this.nextButton = "Next";
        this.previousButton = "Previous";
        this.submitButton = "Submit";
        this.submittingButton = "Submitting";
        this.logOut = "Logout";
        this.selectLanguage = "Select Language";
        this.tryAgainButton = "TRY AGAIN";
        this.writeHerePlaceHolder = "Please enter comment";
        this.pleaseWaitWhileFetch = "Please wait while we fetch your survey";
        this.thankYouText = "Thank you for your participation in this survey";
        this.termsConditions = "Terms & Conditions";
        this.privacyPolicy = "Privacy Policy";
        this.brandGuidelines = "Brand Guidelines";
        this.contactNumber = "Contact Number";
        this.emailText = "Email";
        this.aboutUsText = "About Us";
        this.changePassword = "Change Password";
        this.Badge = "Badges";
        this.EngagementTrends = "Engagement Trend";
        this.Perceptions = "Perceptions";
        this.ActionCorner = "Action Corner"
        this.InvalidInputError = "Html & Script tags not allowed (<>)";
        this.openEndedPollPlaceholder = "Please enter your feedback";
        this.floatingCommentLable = "please elaborate further...";
        this.actionPointDialogHeader = " The management has taken following action(s) basis feedback received";
        this.feedbackRecallLabel="Last time you responded to this statement with * on #. How would you respond to this statement today and why?"

        this.badgeTitle = "Your Badges";
        this.orgRank = "Organization Rank";
        this.teamRank = "Team Rank";
        this.totalPoints = "Total Points";
        this.Youwon = "You won";
        this.PointsSystem = "Point System";
        this.Points = "Points";
        this.Calculation = "Calculation";
        this.BadgeName = "Badge Name";
        this.Description = "Description";
        this.Howarepointscalculated = "How are points calculated?";
        this.Howteamrank = "How do we decide your team rank?";
        this.HowOrgrank = "How do we decide your organization rank?";
        this.Trailblazer = "Trailblazer";
        this.Architect = "Architect";
        this.Chieftain = "Chieftain";
        this.Engineer = "Engineer";
        this.Laureate = "Laureate";
        this.Wordsmith = "Wordsmith";
        this.trailblazerContent = "This badge is yours for taking the first step to becoming a Culture Pioneer. You win 1 point for logging into the app.";
        this.architectContent = "Earn this badge by maintaining a streak and answering every question of the week without missing any. Keep your consistency and show your dedication!";
        this.chieftainContent = "Achieve the highest honor with the Chieftain Badge! Earn 30 points for your active participation and for commenting on all questions throughout the week. Your engagement sets you apart!";
        this.engineerContent = "Win 10 points with this badge by claiming your weekly streak at least 3 times in a month";
        this.laureateContent = "On The Spot A Laureate Leaving a detailed comment or feedback? Win 15 points instantly with this badge..";
        this.wordsmithContent = "This badge wins you 3 points for leaving a brief comment.";
        this.pointDescription1 = "Each Badge has points associated with it. As you receive a badge, you also get the associated points which keep getting added to your existing points. Points are calculated month wise and at the start of every month the score gets reset to 0";
        this.pointDescription2 = "Team Rank is decided basis your points as compared to the scores of others in your team. Team is defined according to the Demography which is picked by your organization to define the teams";
        this.pointDescription3 = "Organization rank is decided basis your points as compared to points of everyone else in your organization..";
        this.ok = "OK";
        this.congratulations = "congratulations";
        this.trailblazerCongratulations = "You have received the Trailblazer Badge";
        this.laureateCongratulations = "Yay! You have received the Laureate Badge";
        this.wordsmithCongratulations = "You have received the Wordsmith Badge";
        this.architectCongratulations = "You have received the Architect Badge";
        this.chieftainCongratulations = "You have received the Chieftain Badge";
        this.submitResponseNote = "* For your response to get recorded, please click on 'Submit' on the final page."

        this.Anxious = "Anxious";
        this.anxiousMoodLabel = "There is no harm in how you are feeling, treat yourself with kindness! Looking forward to hearing from you tomorrow";
        this.anxiousSnackBar = "We understand, we are here for you.";

        this.Uneasy = "Unhappy";
        this.uneasyMoodLabel = "It’s completely normal to have these days, but things will fall in place like they always have! Looking forward to hearing from you tomorrow";
        this.uneasySnackBar = "It’s okay to feel this way. We hope it improves!";

        this.Okay = "Okay";
        this.okayMoodLabel = "Some days are just meh. But others can be exceptional, we hope you have more of the latter! Looking forward to hearing from you tomorrow";
        this.okaySnackBar = "That’s fine, we hope it gets better!";

        this.Happy = "Happy";
        this.happyMoodLabel = "Tomorrow is a new dawn; a new day and we hope you feel even better! Looking forward to hearing from you tomorrow";
        this.happySnackBar="We are happy to see that!";

        this.Excited = "Excited";
        this.excitedMoodLabel ="You feel amazing, embrace it and come back with the same spirit! Looking forward to hearing from you tomorrow";
        this.excitedSnackBar = "That’s great, Lets keep this up!";

        this.heresYourMood = "Here is your todays mood";
        this.whatsYourMood = "What's your mood today?";
        this.questionsLeft = "Questions are left";
        this.questionLeft = "Question is left";
        this.badgeSingular = "Badge";
        this.badges = "badges";

        this.request = "Please rate the action"
        this.thankYouComment = "Thank you so much for giving your honest opinion on our actions. Click the (+) icon to give more..."
        this.actionOnFeedbackCommentPlaceholder = "What do you think of this action?";

        this.sixthOptionText = "Not enough info to rate";
        this.ICICISurveyCompletedAlready = "No pending statements to be answered";
        // this.comment = "What do you think of this action?"
        break;

      //Hindi - hi
      case "hi":
        this.welcomeText = "स्वागत हे";
        this.startSurveyText = "अब सर्वेक्षण शुरू करें";
        this.surveyCompletedAlready = "सर्वेक्षण के प्रश्न उपलब्ध नहीं हैं";
        this.somethingWentWrong = "कुछ गलत हो गया। कृपया पुन: प्रयास करें";
        this.nextButton = "आगामी";
        this.previousButton = "पिछला";
        this.submitButton = "जमा करें";
        this.submittingButton = "प्रस्तुत करने";
        this.logOut = "लोग आउट";
        this.selectLanguage = "भाषा चुनिए";
        this.tryAgainButton = "पुनः प्रयास करें";
        this.writeHerePlaceHolder = "कृपया टिप्पणी दर्ज करें";
        this.pleaseWaitWhileFetch =
          "कृपया प्रतीक्षा करें जब तक हम आपका सर्वेक्षण प्राप्त नहीं कर लेते";
        this.thankYouText = "इस सर्वेक्षण में आपकी भागीदारी के लिए धन्यवाद";
        this.termsConditions = "नियम एवं शर्तें";
        this.privacyPolicy = "गोपनीयता नीति";
        this.brandGuidelines = "ब्रांड दिशानिर्देश";
        this.contactNumber = "संपर्क नंबर";
        this.emailText = "ईमेल";
        this.aboutUsText = "हमारे बारे में";
        this.changePassword = "पासवर्ड बदलें";
        this.Badge = "चिन्ह";
        this.EngagementTrends = "आपका रुझान";
        this.Perceptions = "धारणाएं";
        this.ActionCorner = ""
        this.InvalidInputError = "स्क्रिप्ट टैग की अनुमति नहीं है (<>)";
        this.openEndedPollPlaceholder = "कृपया अपनी प्रतिक्रिया दर्ज करें";
        this.floatingCommentLable = "कृपया आगे विस्तार से बताएं ...";
        this.actionPointDialogHeader = " प्रबंधन ने निम्नलिखित कार्रवाई की है।";
        this.feedbackRecallLabel = "पिछली बार आपने इस कथन का उत्तर * पर # दिया था। आज आप इस कथन पर क्या प्रतिक्रिया देंगे और क्यों?"
        this.badgeTitle = "आपका बैज";
        this.orgRank = "संगठन रैंक";
        this.teamRank = "टीम रैंक";
        this.totalPoints = "कुल अंक";
        this.Youwon = "आप जीते";
        this.badges = "बैज";
        this.badgeSingular = "बैज";
        this.PointsSystem = "अंक प्रणाली";
        this.Points = "अंक";
        this.Calculation="गणना";
        this.BadgeName = "बैज का नाम";
        this.Description = "विवरण";
        this.Howarepointscalculated = "अंक की गणना कैसे की जाती है?";
        this.Howteamrank = "हम आपकी टीम रैंक कैसे तय करते हैं?";
        this.HowOrgrank = "हम आपके संगठन की रैंक कैसे तय करते हैं?";
        this.Trailblazer = "ट्रैल्ब्लैज़र";
        this.Architect = "आर्किटेक्ट";
        this.Chieftain = "चीएफटैन";
        this.Engineer = "इंजीनियर";
        this.Laureate = "लौरेट";
        this.Wordsmith = "वर्डस्मिथ";
        this.trailblazerContent = "यह बैज संस्कृति पायनियर बनने के लिए पहला कदम उठाने के लिए आपका है। आप ऐप में लॉग इन करने के लिए 1 अंक जीतते हैं।";
        this.architectContent = "इस बैज को प्राप्त करें लगातार बनाए रखकर और सप्ताह के हर प्रश्न का उत्तर देकर बिना किसी को छोड़े। अपनी निरंतरता बनाए रखें और अपनी प्रतिबद्धता दिखाएं!";
        this.chieftainContent = "चीफटेन बैज के साथ सर्वोच्च सम्मान प्राप्त करें! सप्ताह भर में सक्रिय भागीदारी और सभी प्रश्नों पर टिप्पणी करने के लिए 30 अंक अर्जित करें। आपकी भागीदारी आपको अलग करती है!";
        this.engineerContent = "एक महीने में कम से कम 3 बार अपनी साप्ताहिक लकीर का दावा करके इस बैज के साथ 10 अंक जीतें।";
        this.laureateContent = "ऑन द स्पॉट लॉरेट एक विस्तृत टिप्पणी या प्रतिक्रिया छोड़ रहा है? इस बैज के साथ तुरंत 15 अंक जीतें ।।";
        this.wordsmithContent = "यह बैज आपको एक संक्षिप्त टिप्पणी छोड़ने के लिए 3 अंक जीतता है।";
        this.pointDescription1 = "प्रत्येक बैज में इससे जुड़े बिंदु होते हैं। जैसे ही आप एक बैज प्राप्त करते हैं, आपको संबंधित बिंदु भी मिलते हैं जो आपके मौजूदा बिंदुओं में जुड़ते रहते हैं। अंक की गणना महीने के हिसाब से की जाती है और हर महीने की शुरुआत में स्कोर 0 पर रीसेट हो जाता है";
        this.pointDescription2 = "आपकी टीम में दूसरों के स्कोर की तुलना में टीम रैंक आपके अंकों के आधार पर तय की जाती है। टीम को डेमोग्राफी के अनुसार परिभाषित किया जाता है जिसे टीमों को परिभाषित करने के लिए आपके संगठन द्वारा चुना जाता है";
        this.pointDescription3 = "आपके संगठन के सभी अन्य लोगों के अंकों की तुलना में संगठन रैंक आपके अंकों के आधार पर तय की जाती है।";
        this.ok = "ठीक है";
        this.congratulations = "बहुत बहुत बधाई";
        this.trailblazerCongratulations = "आपको ट्रेलब्लेज़र बैज मिला है";
        this.laureateCongratulations = "वाह! आपको लॉरेट बैज मिला है";
        this.wordsmithCongratulations = "आपको वर्डस्मिथ बैज मिला है";
        this.architectCongratulations = "आपको आर्किटेक्ट बैज मिला है";
        this.chieftainCongratulations = "आपको सरदार बैज मिला है";
        this.submitResponseNote = "* अपनी प्रतिक्रिया दर्ज करने के लिए, कृपया अंतिम पृष्ठ पर ' सबमिट करें ' पर क्लिक करें"

        this.Anxious = "चिंतित";
        this.anxiousMoodLabel = "आप कैसा महसूस कर रहे हैं, इसमें कोई बुराई नहीं है, अपने आप से दया का व्यवहार करें। तुम से मिलकर प्रसन्नता हुई। कल आपसे सुनने के लिए उत्सुक हैं";
        this.anxiousSnackBar = "हम समझते हैं, हम यहां आपके लिए हैं।";

        this.Uneasy = "अप्रसन्न";
        this.uneasyMoodLabel = "इन दिनों का होना पूरी तरह से सामान्य है, लेकिन चीजें हमेशा की तरह गिरेंगी।आपको देखकर खुशी हुई। कल आपसे सुनने के लिए उत्सुक हैं";
        this.uneasySnackBar = "इस तरह महसूस करना ठीक है। हमें उम्मीद है कि इसमें सुधार होगा!";

        this.Okay = "ठीक";
        this.okayMoodLabel = "कुछ दिन सिर्फ मेह हैं। लेकिन अन्य असाधारण हो सकते हैं, हम आशा करते हैं कि आपके पास बाद वाले अधिक होंगे!आपको देखकर खुशी हुई। कल आपसे सुनने के लिए उत्सुक हैं";
        this.okaySnackBar = "यह ठीक है, हमें उम्मीद है कि यह बेहतर होगा!";

        this.Happy = "प्रसन्न";
        this.happyMoodLabel = "कल एक नया सवेरा है; एक नया दिन और हम आशा करते हैं कि आप और भी बेहतर महसूस करेंगे।आपको देखकर खुशी हुई। कल आपसे सुनने के लिए उत्सुक हैं";
        this.happySnackBar="हम यह देखकर खुश हैं!";

        this.Excited = "उत्साहित";
        this.excitedMoodLabel ="आप अद्भुत महसूस करते हैं, इसे गले लगाओ और उसी भावना के साथ वापस आओ!आपको देखकर खुशी हुई। कल आपसे सुनने के लिए उत्सुक हैं";
        this.excitedSnackBar = "यह बहुत अच्छा है, चलो इसे जारी रखें!";

        this.heresYourMood = "ये रहा आपका आज का मूड";
        this.whatsYourMood = "आज आपका मूड क्या है?";
        this.submitResponseNote = "* अपनी प्रतिक्रिया दर्ज करने के लिए, कृपया अंतिम पृष्ठ पर ' सबमिट करें ' पर क्लिक करें";
        this.questionsLeft = "प्रश्न शेष हैं";
        this.questionLeft = "प्रश्न शेष हैं";

        this.request = "कृपया की गई करवाई को रेट करें"
        this.thankYouComment = "हमारे कार्यों पर अपनी ईमानदार राय देने के लिए आपका बहुत-बहुत धन्यवाद। अधिक जानकारी देने के लिए (+) आइकन पर क्लिक करें..."
        this.actionOnFeedbackCommentPlaceholder = "आप इस करवाई के बारे में क्या सोचते हैं?"
        this.sixthOptionText = "रेट करने के लिए पर्याप्त जानकारी नहीं"

        this.ICICISurveyCompletedAlready = "किसी भी लंबित बयान का जवाब नहीं देना है"
        // this.comment = "टिप्पणी"
        break;

      //Marathi - mr
      case "mr":
        this.welcomeText = "स्वागत आहे";
        this.startSurveyText = "आता सर्वेक्षण सुरू करा";
        this.surveyCompletedAlready = "सर्वेक्षण प्रश्न उपलब्ध नाहीत";
        this.somethingWentWrong = "काहीतरी चूक झाली, कृपया पुन्हा प्रयत्न करा";
        this.nextButton = "पुढे";
        this.previousButton = "मागील";
        this.submitButton = "सबमिट करा";
        this.submittingButton = "सादर करीत आहे";
        this.logOut = "बाहेर पडणे";
        this.selectLanguage = "भाषा निवडा";
        this.tryAgainButton = "पुन्हा प्रयत्न करा";
        this.writeHerePlaceHolder = "कृपया टिप्पणी प्रविष्ट करा";
        this.pleaseWaitWhileFetch =
          "आम्ही आपले सर्वेक्षण आणत असताना कृपया प्रतीक्षा करा";
        this.thankYouText = "या सर्वेक्षणात सहभागी झाल्याबद्दल धन्यवाद";
        this.termsConditions = "अटी व शर्ती";
        this.privacyPolicy = "गोपनीयता धोरण";
        this.brandGuidelines = "ब्रँड मार्गदर्शकतत्त्वे";
        this.contactNumber = "संपर्क क्रमांक";
        this.emailText = "ईमेल";
        this.aboutUsText = "आमच्याबद्दल";
        this.changePassword = "पासवर्ड बदला";
        this.Badge = "चिन्ह";
        this.EngagementTrends = "आपले ट्रेंड";
        this.Perceptions = "समज";
        this.InvalidInputError =
          "एचटीएमएल आणि स्क्रिप्ट टॅगला परवानगी नाही (<>)";
        this.openEndedPollPlaceholder = "कृपया आपला अभिप्राय प्रविष्ट करा";
        this.floatingCommentLable = "कृपया अधिक तपशीलवार सांगा ...";
        this.actionPointDialogHeader = " व्यवस्थापनाने खालील कार्यवाही केली (त) आधारे अभिप्राय";
        this.feedbackRecallLabel = "शेवटच्या वेळी आपण * वर # सह या विधानास प्रत्युत्तर दिले. आज या विधानाला तुम्ही कसा प्रतिसाद द्याल आणि का?"
        this.badgeTitle = "आपले बॅज";
        this.orgRank = "संघटना क्रमांक";
        this.teamRank = "टीम रँक";
        this.totalPoints = "एकूण गुण";
        this.badgeTitle = "आपले बॅज";
        this.orgRank = "संघटना क्रमांक";
        this.teamRank = "टीम रँक";
        this.totalPoints = "एकूण गुण";
        this.Youwon = "तू जिंकलास";
        this.badges = "बॅज";
        this.badgeSingular = "बॅज";
        this.PointsSystem = "पॉइंट सिस्टम";
        this.Points = "गुण";
        this.Calculation = "गणना";
        this.BadgeName = "बॅज नाव";
        this.Description = "वर्णन";
        this.Howarepointscalculated = "गुणांची गणना कशी केली जाते?";
        this.Howteamrank = "आम्ही आपल्या संघाचे रँक कसे ठरवू?";
        this.HowOrgrank = "आम्ही आपल्या संस्थेचे रँक कसे ठरवू?";
        this.Trailblazer = "ट्रेलब्लाझर";
        this.Architect = "आर्किटेक्ट";
        this.Chieftain = "सरदार";
        this.Engineer = "अभियंता";
        this.Laureate = "विजेते";
        this.Wordsmith = "वर्डस्मिथ";
        this.trailblazerContent = "कल्चर पायनियर होण्यासाठी पहिले पाऊल उचलण्यासाठी हा बॅज आपला आहे. अ‍ॅपमध्ये लॉग इन करण्यासाठी आपण 1 गुण जिंकला.";
        this.architectContent = "ही पदक मिळवा एकसंधता राखून आणि आठवड्यातील प्रत्येक प्रश्नाचे उत्तर देऊन काहीही गमावलेले नाही. तुमची सततता राखा आणि तुमची निष्ठा दर्शवा!";
        this.chieftainContent = "चीफटेन पदक मिळवा सर्वोच्च मान मिळवून! सक्रिय सहभागासाठी आणि आठवडाभरातील सर्व प्रश्नांवर टिप्पणी करण्यासाठी 30 अंक मिळवा. तुमची व्यस्तता तुम्हाला वेगळं बनवते!";
        this.engineerContent = "महिन्यातून किमान 3 वेळा आपल्या साप्ताहिक पट्टीवर दावा करुन या बॅजसह 10 गुण जिंकून घ्या";
        this.laureateContent = "स्पॉट वर एक विजेते तपशीलवार टिप्पणी किंवा अभिप्राय सोडत आहेत? या बॅजसह त्वरित 15 गुण जिंकणे ..";
        this.wordsmithContent = "या बॅजने आपल्याला थोडक्यात टिप्पणी देण्यासाठी 3 गुण जिंकले.";
        this.pointDescription1 = "प्रत्येक बॅजशी संबंधित गुण आहेत. आपल्याला बॅज प्राप्त होताच, आपल्यास संबंधित बिंदू देखील मिळतात जे आपल्या विद्यमान मुद्द्यांसह जोडले जातील. पॉइंट्स महिन्यानुसार गणले जातात आणि प्रत्येक महिन्याच्या सुरूवातीस स्कोअर 0 वर रीसेट होतो";
        this.pointDescription2 = "आपल्या संघातील इतरांच्या तुलनेत आपल्या गुणांच्या आधारे टीम रँक ठरविला जातो. आपल्या संघाने संघ निश्चित करण्यासाठी निवडलेल्या लोकसंख्येनुसार टीम परिभाषित केली जाते";
        this.pointDescription3 = "आपल्या संस्थेतील इतर प्रत्येकाच्या गुणांच्या तुलनेत आपल्या गुणांच्या आधारे संस्थेचे स्थान निश्चित केले जाते ..";
        this.ok = "ठीक आहे";
        this.congratulations = "अभिनंदन";
        this.trailblazerCongratulations = "आपल्याला ट्रेलब्लेझर बॅज प्राप्त झाला आहे";
        this.laureateCongratulations = "होय! आपल्याला विजेता बॅज प्राप्त झाला आहे";
        this.wordsmithCongratulations = "आपल्याला वर्डस्मिथ बॅज प्राप्त झाला आहे";
        this.architectCongratulations = "आपल्याला आर्किटेक्ट बॅज प्राप्त झाला आहे";
        this.chieftainCongratulations = "आपल्याला सरदार बॅज प्राप्त झाला आहे";
        this.submitResponseNote = "* आपला प्रतिसाद रेकॉर्ड करण्यासाठी, कृपया अंतिम पृष्ठावरील ' सबमिटवर ' क्लिक करा";

        this.Anxious = "चिंताग्रस्त";
        this.anxiousMoodLabel = "आपण कसे अनुभवत आहात यात काहीच हानी नाही, स्वतःशी दयाळूपणे वागले पाहिजे. तुला पाहून आनंद झाला. उद्या आपल्याकडून सुनावणीची अपेक्षा आहे";
        this.anxiousSnackBar = "आम्हाला समजले, आम्ही तुमच्यासाठी येथे आहोत";

        this.Uneasy = "दुःखी";
        this.uneasyMoodLabel = "हे दिवस असणे अगदी सामान्य आहे, परंतु गोष्टी नेहमीच आल्या त्याप्रमाणे घसरतील.तुम्हाला भेटण्यासाठी आनंद. उद्या आपल्याकडून सुनावणीची अपेक्षा आहे";
        this.uneasySnackBar = "असे वाटणे ठीक आहे. आम्ही आशा करतो की त्यात सुधारणा होईल!";

        this.Okay = "ठीक आहे";
        this.okayMoodLabel = "काही दिवस फक्त मेह असतात. परंतु इतर अपवादात्मक असू शकतात, आम्ही आशा करतो की आपणास नंतरचे जास्त मिळाले आहे! आपल्याला पाहून आनंद झाला. उद्या आपल्याकडून सुनावणीची अपेक्षा आहे";
        this.okaySnackBar = "ते ठीक आहे, आम्ही आशा करतो की हे चांगले होईल!";

        this.Happy = "आनंदी";
        this.happyMoodLabel = "उद्या एक नवीन पहाट आहे; एक नवीन दिवस आणि आम्ही आशा करतो की आपणास आणखी चांगले वाटेल. आपल्याला भेटून आनंद घ्या. उद्या आपल्याकडून सुनावणीची अपेक्षा आहे";
        this.happySnackBar="We are happy to see that!";

        this.Excited = "उत्साहित";
        this.excitedMoodLabel ="आपण आश्चर्यकारक आहात, त्यास मिठी मारता आणि त्याच आत्म्याने परत आला! आपल्याला पाहून आनंद झाला. उद्या आपल्याकडून सुनावणीची अपेक्षा आहे";
        this.excitedSnackBar = "ते छान आहे, हे चालू ठेवू द्या!";

        this.heresYourMood = "हा आहे तुमचा आजचा मूड";
        this.whatsYourMood = "आज तुमचा मूड काय आहे?";
        this.questionsLeft = "प्प्रश्न शिल्लक आहेत";
        this.questionLeft = "प्प्रश्न शिल्लक आहेत";

        this.request = "कृपया केलेल्या कारवाईचे मूल्यमापन करा"
        this.thankYouComment ="आमच्या कृतींवर तुमचे प्रामाणिक मत दिल्याबद्दल तुमचे खूप आभार. अधिक देण्यासाठी (+) चिन्हावर क्लिक करा ..."
        this.actionOnFeedbackCommentPlaceholder="तुम्हाला या कृतीबद्दल काय वाटते?"

        this.sixthOptionText = "रेट करण्यासाठी पुरेशी माहिती नाही"

        this.ICICISurveyCompletedAlready = "कोणतीही प्रलंबित विधाने उत्तरे द्यायची नाहीत"
        // this.comment = "टिप्पणी"
        break;

      //Bengali - bn
      case "bn":
        this.welcomeText = "স্বাগত";
        this.startSurveyText = "এখন জরিপ শুরু";
        this.surveyCompletedAlready = "আপনি ইতিমধ্যে আপনার জরিপ সম্পন্ন হয়েছে";
        this.somethingWentWrong = "কিছু ভুল হয়েছে আবার চেষ্টা করুন";
        this.nextButton = "পরবর্তী";
        this.previousButton = "আগে";
        this.submitButton = "জমা দিন";
        this.submittingButton = "জমা দেওয়ার";
        this.logOut = "প্রস্থান";
        this.selectLanguage = "ভাষা নির্বাচন কর";
        this.tryAgainButton = "আবার চেষ্টা কর";
        this.writeHerePlaceHolder = "মন্তব্য লিখুন দয়া করে";
        this.pleaseWaitWhileFetch =
          "আমরা আপনার জরিপ আনতে যখন অনুগ্রহ করে অপেক্ষা করুন";
        this.thankYouText = "এই জরিপ আপনার অংশগ্রহণের জন্য আপনাকে ধন্যবাদ";
        this.termsConditions = "শর্তাবলী";
        this.privacyPolicy = "গোপনীয়তা নীতি";
        this.brandGuidelines = "ব্র্যান্ড নির্দেশিকা";
        this.contactNumber = "যোগাযোগের নম্বর";
        this.emailText = "ইমেইল";
        this.aboutUsText = "আমাদের সম্পর্কে";
        this.changePassword = "পাসওয়ার্ড পরিবর্তন করুন";
        this.Badge = "ব্যাজ"
        this.EngagementTrends = "আপনার ট্রেন্ডস";
        this.Perceptions = "মালুম";
        this.InvalidInputError =
          "এইচটিএমএল এবং স্ক্রিপ্ট ট্যাগগুলি অনুমোদিত নয় (<>)";
        this.openEndedPollPlaceholder = "আপনার মতামত লিখুন দয়া করে";
        this.floatingCommentLable = "দয়া করে আরও বিস্তারিত ...";
        this.actionPointDialogHeader ="আপনার সৎ প্রতিক্রিয়ার জন্য ধন্যবাদ। ব্যবস্থাপনা নিম্নলিখিত পদক্ষেপ গ্রহণ করেছে (গুলি) ভিত্তিক প্রতিক্রিয়া প্রাপ্ত";
        this.feedbackRecallLabel = "গতবার আপনি এই বিবৃতিটিতে * টি # দিয়ে প্রতিক্রিয়া জানিয়েছেন। আজ আপনি এই বিবৃতিতে কীভাবে প্রতিক্রিয়া জানাবে এবং কেন?"

        this.totalPoints = "মোট পয়েন্ট";
        this.badgeTitle = "আপনার ব্যাজ";
        this.orgRank = "সংস্থা র‌্যাঙ্ক";
        this.teamRank = "দল র‌্যাঙ্ক";
        this.totalPoints = "মোট পয়েন্ট";
        this.Youwon = "তুমি জিতেছিলে";
        this.badges = "ব্যাজ";
        this.badgeSingular = "ব্যাজ";
        this.PointsSystem = "পয়েন্ট সিস্টেম";
        this.Points = "পয়েন্টস";
        this.Calculation = "গণনা";
        this.BadgeName = "ব্যাজ নাম";
        this.Description = "বর্ণনা";
        this.Howarepointscalculated = "পয়েন্টগুলি কীভাবে গণনা করা হয়?";
        this.Howteamrank = "আমরা কীভাবে আপনার দলের র‌্যাঙ্কটি স্থির করব?";
        this.HowOrgrank = "আমরা কীভাবে আপনার সংস্থার পদমর্যাদা স্থির করব?";
        this.Trailblazer = "ট্রেলব্লেজার";
        this.Architect = "স্থপতি";
        this.Chieftain = "সর্দার";
        this.Engineer = "ইঞ্জিনিয়ার";
        this.Laureate = "বিজয়ী";
        this.Wordsmith = "ওয়ার্ডস্মিথ";
        this.trailblazerContent = "সংস্কৃতি পাইওনিয়ার হওয়ার প্রথম পদক্ষেপ গ্রহণের জন্য এই ব্যাজটি আপনার। আপনি অ্যাপটিতে লগ ইন করার জন্য 1 পয়েন্ট জিতেছেন।";
        this.architectContent = "এই ব্যাজটি অর্জন করুন ধারাবাহিকতা বজায় রেখে এবং সপ্তাহের প্রতিটি প্রশ্নের উত্তর দিয়ে কোন কিছু মিস না করে। আপনার ধারাবাহিকতা বজায় রাখুন এবং আপনার প্রতিশ্রুতি প্রদর্শন করুন!";
        this.chieftainContent = "চিফটেইন ব্যাজের সাথে সর্বোচ্চ সম্মান অর্জন করুন! সক্রিয় অংশগ্রহণ এবং সপ্তাহজুড়ে সমস্ত প্রশ্নে মন্তব্য করার জন্য ৩০ পয়েন্ট অর্জন করুন। আপনার সম্পৃক্ততা আপনাকে আলাদা করে তোলে!";        
        this.engineerContent = "একমাসে কমপক্ষে 3 বার আপনার সাপ্তাহিক স্ট্রাইক দাবি করে এই ব্যাজ দিয়ে 10 পয়েন্ট জিতুন";
        this.laureateContent = "স্পট এ একটি বিজয়ী একটি বিস্তারিত মন্তব্য বা প্রতিক্রিয়া ছেড়ে? এই ব্যাজটি সহ তত্ক্ষণাত 15 পয়েন্ট জয় করুন ..";
        this.wordsmithContent = "একটি সংক্ষিপ্ত মন্তব্য দেওয়ার জন্য এই ব্যাজটি আপনাকে 3 পয়েন্ট জিতিয়েছে।";
        this.pointDescription1 = "প্রতিটি ব্যাজের সাথে এর সাথে সম্পর্কিত পয়েন্ট রয়েছে। আপনি যখন কোনও ব্যাজ পাবেন, আপনি যুক্ত পয়েন্টগুলিও পাবেন যা আপনার বিদ্যমান পয়েন্টগুলিতে যুক্ত হতে থাকে। পয়েন্টগুলি মাস অনুসারে গণনা করা হয় এবং প্রতি মাসের শুরুতে স্কোর 0 এ পুনরায় সেট হয়";
        this.pointDescription2 = "আপনার দলের অন্যদের স্কোরের তুলনায় আপনার পয়েন্টের ভিত্তিতে টিম র‌্যাঙ্ক সিদ্ধান্ত নেওয়া হয়েছে। দল সংজ্ঞায়িত করার জন্য আপনার সংস্থা কর্তৃক নির্বাচিত ডেমোগ্রাফিক অনুযায়ী দলকে সংজ্ঞায়িত করা হয়";
        this.pointDescription3 = "আপনার প্রতিষ্ঠানের প্রত্যেকের পয়েন্টের তুলনায় আপনার পয়েন্টের ভিত্তিতে সংস্থার স্থান নির্ধারণ করা হয় ..";
        this.ok = "ঠিক আছে";
        this.congratulations = "অভিনন্দন";
        this.trailblazerCongratulations = "আপনি ট্রেলব্লেজার ব্যাজটি পেয়েছেন";
        this.laureateCongratulations = "হ্যাঁ! আপনি বিজয়ী ব্যাজ পেয়েছেন";
        this.wordsmithCongratulations = "আপনি ওয়ার্ডস্মিথ ব্যাজ পেয়েছেন";
        this.architectCongratulations = "আপনি আর্কিটেক্ট ব্যাজ পেয়েছেন";
        this.chieftainCongratulations = "আপনি চিফটাইন ব্যাজ পেয়েছেন";
        this.submitResponseNote = "* আপনার প্রতিক্রিয়া রেকর্ড করার জন্য, দয়া করে চূড়ান্ত পৃষ্ঠায় জমা দিন ক্লিক করুন"

        this.Anxious = "উদ্বেগজনক";
        this.anxiousMoodLabel = "আপনি যে অনুভব করছেন তাতে কোনও ক্ষতি নেই, নিজেকে সদয় আচরণ করুন। তোমাকে দেখে খুশি হলাম. আগামীকাল আপনার কাছ থেকে শোনার প্রত্যাশায়";
        this.anxiousSnackBar = "আমরা বুঝতে পারি, আমরা এখানে আপনার জন্য আছি।";

        this.Uneasy = "অসুখী";
        this.uneasyMoodLabel = "এই দিনগুলি হওয়া সম্পূর্ণ স্বাভাবিক, তবে জিনিসগুলি সর্বদা তার মতো হয়ে যায়। আপনাকে দেখার জন্য ধন্যবাদ app আগামীকাল আপনার কাছ থেকে শোনার প্রত্যাশায়";
        this.uneasySnackBar = "এইভাবে অনুভব করা ঠিক আছে। আমরা আশা করি এটির উন্নতি হবে!";

        this.Okay = "ঠিক আছে";
        this.okayMoodLabel = "কিছু দিন শুধু মেহ হয়। তবে অন্যরা ব্যতিক্রমী হতে পারে, আমরা আশা করি আপনারা আরও বেশি কিছু পেয়েছেন! আপনাকে দেখে খুশি। আগামীকাল আপনার কাছ থেকে শোনার প্রত্যাশায়";
        this.okaySnackBar = "এটা ঠিক আছে, আমরা আশা করি এটি আরও ভাল হয়ে গেছে!";

        this.Happy = "সুখী";
        this.happyMoodLabel = "কাল একটি নতুন ভোর; একটি নতুন দিন এবং আমরা আশা করি আপনি আরও ভাল বোধ করবেন you আপনাকে দেখার জন্য ধন্যবাদ। আগামীকাল আপনার কাছ থেকে শোনার প্রত্যাশায়";
        this.happySnackBar="আমরা তা দেখে খুশি!";

        this.Excited = "উত্তেজিত";
        this.excitedMoodLabel ="আপনি আশ্চর্যজনক বোধ করেন, এটিকে আলিঙ্গন করেন এবং একই আত্মার সাথে ফিরে আসেন! আপনাকে দেখে খুশি। আগামীকাল আপনার কাছ থেকে শোনার প্রত্যাশায়";
        this.excitedSnackBar = "এটি দুর্দান্ত, এটি চালিয়ে যেতে দিন!";


        this.heresYourMood = "এখানে আপনার আজকের মেজাজ";
        this.whatsYourMood = "আজ তোমার মেজাজ কেমন?";
        this.submitResponseNote = "* আপনার প্রতিক্রিয়া রেকর্ড করার জন্য, দয়া করে চূড়ান্ত পৃষ্ঠায় জমা দিন ক্লিক করুন";
        this.questionsLeft = "টি প্রশ্ন বাকি আছে";
        this.questionLeft = "টি প্রশ্ন বাকি আছে";

        this.request = "দয়া করে গৃহীত ক্রিয়াকলাপকে রেট দিন"
        this.thankYouComment = "আমাদের ক্রিয়াকলাপ সম্পর্কে আপনার সৎ মতামত দেওয়ার জন্য আপনাকে অনেক ধন্যবাদ। আরও দিতে (+) আইকনে ক্লিক করুন..."
        this.actionOnFeedbackCommentPlaceholder = "এই ক্রিয়াকলাপ সম্পর্কে আপনি কী মনে করেন?";

        this.sixthOptionText = "রেট করার জন্য যথেষ্ট তথ্য নেই";

        this.ICICISurveyCompletedAlready = "কোন মুলতুবি বিবৃতি উত্তর করা";

        break;

      //Telugu - te
      case "te":
        this.welcomeText = "స్వాగతం";
        this.startSurveyText = "ఇప్పుడు సర్వే ప్రారంభించండి";
        this.surveyCompletedAlready = "మీరు ఇప్పటికే మీ సర్వే పూర్తి చేశారు";
        this.somethingWentWrong =
          "ఏదో తప్పు జరిగింది, దయచేసి మళ్ళీ ప్రయత్నించండి";
        this.nextButton = "తరువాత";
        this.previousButton = "మునుపటి";
        this.submitButton = "సమర్పించండి";
        this.submittingButton = "సమర్పిస్తోంది";
        this.logOut = "లాగౌట్";
        this.selectLanguage = "భాషను ఎంచుకోండి";
        this.tryAgainButton = "మళ్ళీ ప్రయత్నించండి";
        this.writeHerePlaceHolder = "దయచేసి వ్యాఖ్యను నమోదు చేయండి";
        this.pleaseWaitWhileFetch = "దయచేసి మీ సర్వేని పొందడం కోసం వేచి ఉండండి";
        this.thankYouText = "ఈ సర్వేలో మీరు పాల్గొన్నందుకు ధన్యవాదాలు";
        this.termsConditions = "నిబంధనలు & షరతులు";
        this.privacyPolicy = "గోప్యతా విధానం";
        this.brandGuidelines = "బ్రాండ్ మార్గదర్శకాలు";
        this.contactNumber = "సంప్రదింపు సంఖ్య";
        this.emailText = "ఇమెయిల్";
        this.aboutUsText = "మా గురించి";
        this.changePassword = "పాస్ వర్డ్ ను మార్చండి";
        this.Badge = "బ్యాడ్జ్"
        this.InvalidInputError =
          "Html & స్క్రిప్ట్ ట్యాగ్‌లు అనుమతించబడవు (<>)";
        this.openEndedPollPlaceholder = "దయచేసి మీ అభిప్రాయాన్ని నమోదు చేయండి";
        this.floatingCommentLable = "దయచేసి మరింత వివరించండి ...";
        this.actionPointDialogHeader ="మీ నిజాయితీ అభిప్రాయానికి ధన్యవాదాలు. నిర్వహణ ఈ క్రింది చర్య (లు) ఆధారంగా చూసింది";
        this.feedbackRecallLabel = "చివరిసారి మీరు ఈ ప్రకటనకు * ఆన్ # తో ప్రతిస్పందించారు. ఈ రోజు మీరు ఈ ప్రకటనకు ఎలా స్పందిస్తారు మరియు ఎందుకు?"
        this.badgeTitle = "మీ బ్యాడ్జ్‌లు";
        this.orgRank = "సంస్థ ర్యాంక్";
        this.teamRank = "జట్టు ర్యాంక్";
        this.totalPoints = "మొత్తం పాయింట్లు";

        this.badgeTitle = "మీ బ్యాడ్జ్‌లు";
        this.orgRank = "సంస్థ ర్యాంక్";
        this.teamRank = "జట్టు ర్యాంక్";
        this.totalPoints = "మొత్తం పాయింట్లు";
        this.Youwon = "నువ్వు గెలిచావు";
        this.badges = "బ్యాడ్జ్";
        this.badgeSingular = "బ్యాడ్జ్";
        this.PointsSystem = "పాయింట్ సిస్టమ్";
        this.Points = "పాయింట్లు";
        this.Calculation = "లెక్కింపు";
        this.BadgeName = "బ్యాడ్జ్ పేరు";
        this.Description = "వివరణ";
        this.Howarepointscalculated = "పాయింట్లు ఎలా లెక్కించబడతాయి?";
        this.Howteamrank = "మీ జట్టు ర్యాంకును మేము ఎలా నిర్ణయిస్తాము?";
        this.HowOrgrank = "మీ సంస్థ ర్యాంకును మేము ఎలా నిర్ణయిస్తాము?";
        this.Trailblazer = "ట్రైల్బ్లేజర్";
        this.Architect = "ఆర్కిటెక్ట్";
        this.Chieftain = "అధిపతి";
        this.Engineer = "ఇంజనీర్";
        this.Laureate = "గ్రహీత";
        this.Wordsmith = "వర్డ్స్మిత్";
        this.trailblazerContent = "సంస్కృతి మార్గదర్శకుడిగా మారడానికి మొదటి అడుగు వేసినందుకు ఈ బ్యాడ్జ్ మీదే. అనువర్తనంలోకి లాగిన్ అయినందుకు మీరు 1 పాయింట్‌ను గెలుచుకుంటారు.";
        this.architectContent = "ఈ బ్యాడ్జ్‌ను పొందండి ఒక నిరంతరసూక్తిని ఉంచడం మరియు వారానికి ప్రతి ప్రశ్నకు సమాధానం ఇవ్వడం ద్వారా. మీ స్థిరత్వాన్ని కొనసాగించండి మరియు మీ అంకితభావాన్ని చూపండి!";
        this.chieftainContent = "చీఫ్టైన్ బ్యాడ్జ్‌తో అత్యంత గౌరవం పొందండి! మీ సక్రియ భాగస్వామ్యం మరియు వారాంతం వరకు అన్ని ప్రశ్నలకు కామెంట్ చేయడం కోసం 30 పాయింట్లు సంపాదించండి. మీ నిమగ్నత మీరు ప్రత్యేకతను తెలియజేస్తుంది!";        
        this.engineerContent = "మీ వారపు పరంపరను నెలలో కనీసం 3 సార్లు క్లెయిమ్ చేయడం ద్వారా ఈ బ్యాడ్జ్‌తో 10 పాయింట్లను గెలుచుకోండి";
        this.laureateContent = "స్పాట్ ఎ గ్రహీత వివరణాత్మక వ్యాఖ్య లేదా అభిప్రాయాన్ని వదిలివేస్తున్నారా? ఈ బ్యాడ్జ్‌తో 15 పాయింట్లను తక్షణమే గెలవండి ..";
        this.wordsmithContent = "క్లుప్త వ్యాఖ్యను ఇచ్చినందుకు ఈ బ్యాడ్జ్ మీకు 3 పాయింట్లను గెలుచుకుంటుంది.";
        this.pointDescription1 = "ప్రతి బ్యాడ్జ్ దానితో సంబంధం ఉన్న పాయింట్లను కలిగి ఉంటుంది. మీరు బ్యాడ్జిని అందుకున్నప్పుడు, మీరు ఇప్పటికే ఉన్న మీ పాయింట్లకు జోడించబడే అనుబంధ పాయింట్లను కూడా పొందుతారు. పాయింట్లు నెల వారీగా లెక్కించబడతాయి మరియు ప్రతి నెల ప్రారంభంలో స్కోరు 0 కి రీసెట్ అవుతుంది";
        this.pointDescription2 = "మీ జట్టులోని ఇతరుల స్కోర్‌లతో పోలిస్తే టీమ్ ర్యాంక్ మీ పాయింట్ల ఆధారంగా నిర్ణయించబడుతుంది. జట్లు నిర్వచించడానికి మీ సంస్థ ఎంచుకున్న జనాభా ప్రకారం జట్టు నిర్వచించబడుతుంది";
        this.pointDescription3 = "మీ సంస్థలోని ప్రతి ఒక్కరి పాయింట్లతో పోలిస్తే సంస్థ ర్యాంక్ మీ పాయింట్ల ఆధారంగా నిర్ణయించబడుతుంది ..";
        this.ok = "అలాగే";
        this.congratulations = "అభినందనలు";
        this.trailblazerCongratulations = "మీరు ట్రైల్బ్లేజర్ బ్యాడ్జ్ అందుకున్నారు";
        this.laureateCongratulations = "అవును! మీరు గ్రహీత బ్యాడ్జ్ అందుకున్నారు";
        this.wordsmithCongratulations = "మీరు వర్డ్స్మిత్ బ్యాడ్జ్ అందుకున్నారు";
        this.architectCongratulations = "మీరు ఆర్కిటెక్ట్ బ్యాడ్జ్ అందుకున్నారు";
        this.chieftainCongratulations = "మీరు చీఫ్ బ్యాడ్జ్ అందుకున్నారు";
        this.submitResponseNote = " * మీ స్పందన రికార్డ్ కావడానికి, దయచేసి చివరి పేజీలోని సమర్పించుపై క్లిక్ చేయండి"


        this.Anxious = "ఆందోళన చెందుతున్నారు";
        this.anxiousMoodLabel = "మీరు ఎలా ఫీల్ అవుతున్నారంటే ఎలాంటి హాని లేదు, మిమ్మల్ని మీరు దయతో చూసుకోండి! రేపు మీ నుండి వినడానికి ఎదురు చూస్తున్నాను";
        this.anxiousSnackBar = "మేము అర్థం చేసుకున్నాము, మేము మీ కోసం ఇక్కడ ఉన్నాము";

        this.Uneasy = "సంతోషంగా లేదు";
        this.uneasyMoodLabel = "ఈ రోజుల్లో ఉండటం పూర్తిగా సాధారణం, కానీ విషయాలు ఎప్పటిలాగే జరుగుతాయి! రేపు మీ నుండి వినడానికి ఎదురు చూస్తున్నాను";
        this.uneasySnackBar = "ఈ విధంగా భావించడం సరైందే. ఇది మెరుగుపడుతుందని మేము ఆశిస్తున్నాము!";

        this.Okay = "సరే";
        this.okayMoodLabel = "కొన్ని రోజులు కేవలం మెహే. కానీ ఇతరులు అసాధారణమైనవి కావచ్చు, మీకు రెండోది ఎక్కువగా ఉంటుందని మేము ఆశిస్తున్నాము! రేపు మీ నుండి వినడానికి ఎదురు చూస్తున్నాను";
        this.okaySnackBar = "అది మంచిది, ఇది మెరుగుపడుతుందని మేము ఆశిస్తున్నాము!";

        this.Happy = "సంతోషంగా";
        this.happyMoodLabel = "రేపు ఒక కొత్త డాన్; కొత్త రోజు మరియు మీరు మరింత మెరుగ్గా భావిస్తారని మేము ఆశిస్తున్నాము! రేపు మీ నుండి వినడానికి ఎదురు చూస్తున్నాను";
        this.happySnackBar="మేము దానిని చూసి సంతోషించాము!";

        this.Excited = "ఉత్సాహంగా";
        this.excitedMoodLabel ="మీరు అద్భుతమైన అనుభూతి చెందుతారు, దానిని స్వీకరించండి మరియు అదే స్ఫూర్తితో తిరిగి రండి! రేపు మీ నుండి వినడానికి ఎదురు చూస్తున్నాను";
        this.excitedSnackBar = "ఇది చాలా బాగుంది, దీనిని కొనసాగిద్దాం!";

        this.heresYourMood = "ఇక్కడ మీ నేటి మూడ్ ఉంది";
        this.whatsYourMood = "ఈ రోజు మీ మానసిక స్థితి ఏమిటి?";
        this.submitResponseNote = " * మీ స్పందన రికార్డ్ కావడానికి, దయచేసి చివరి పేజీలోని సమర్పించుపై క్లిక్ చేయండి";
        this.questionsLeft = "ప్రశ్నలు మిగిలి ఉన్నాయి";
        this.questionLeft = "ప్రశ్నలు మిగిలి ఉన్నాయి";

        this.request = "తీసుకున్న చర్యకు దయచేసి రేటింగ్ ఇవ్వండి"
        this.thankYouComment = "మా చర్యలపై మీ నిజాయితీ అభిప్రాయాన్ని ఇచ్చినందుకు చాలా ధన్యవాదాలు. మరింత ఇవ్వడం కొరకు (+) ఐకాన్ మీద క్లిక్ చేయండి..."
        this.actionOnFeedbackCommentPlaceholder = "ఈ చర్య గురించి మీరు ఏమనుకుంటున్నారు?";

        this.sixthOptionText = "రేట్ చేయడానికి తగినంత సమాచారం లేదు";
        this.ICICISurveyCompletedAlready = "పెండింగ్‌లో ఉన్న స్టేట్‌మెంట్‌లకు సమాధానం ఇవ్వాల్సిన అవసరం లేదు";
        break;

      //Tamil - ta
      case "ta":
        this.welcomeText = "வரவேற்பு";
        this.startSurveyText = "இப்போது சர்வே தொடங்கவும்";
        this.surveyCompletedAlready =
          "ஏற்கனவே உங்கள் கணக்கெடுப்பு முடித்துவிட்டீர்கள்";
        this.somethingWentWrong = "ஏதோ தவறாகிவிட்டது, மீண்டும் முயற்சிக்கவும்";
        this.nextButton = "அடுத்த";
        this.previousButton = "முந்தைய";
        this.submitButton = "சமர்ப்பி";
        this.submittingButton = "சமர்ப்பிக்கிறது";
        this.logOut = "வெளியேறு";
        this.selectLanguage = "மொழி தேர்ந்தெடு";
        this.tryAgainButton = "மீண்டும் முயற்சி செய்";
        this.writeHerePlaceHolder = "கருத்துரையை உள்ளிடுக";
        this.pleaseWaitWhileFetch = "உங்கள் கணக்கைப் பெறுகையில் காத்திருங்கள்";
        this.thankYouText = "இந்த ஆய்வில் உங்கள் பங்கிற்கு நன்றி";
        this.termsConditions = "விதிமுறைகளும் நிபந்தனைகளும்";
        this.privacyPolicy = "தனியுரிமை கொள்கை";
        this.brandGuidelines = "பிராண்ட் வழிகாட்டுதல்கள்";
        this.contactNumber = "தொடர்பு எண்";
        this.emailText = "மின்னஞ்சல்";
        this.aboutUsText = "எங்களை பற்றி";
        this.changePassword = "கடவுச்சொல்லை மாற்று";
        this.Badge = "பேட்ஜ்";
        this.EngagementTrends = "நிச்சயதார்த்த போக்கு";
        this.Perceptions = "உணர்வுகள்";
        this.InvalidInputError =
          "HTML & ஸ்கிரிப்ட் குறிச்சொற்கள் அனுமதிக்கப்படவில்லை (<>)";
        this.openEndedPollPlaceholder = "உங்கள் கருத்தை உள்ளிடவும்";
        this.floatingCommentLable = "மேலும் விரிவாகக் கூறுங்கள் ...";
        this.actionPointDialogHeader="உங்கள் நேர்மையான கருத்துக்கு நன்றி. நிர்வாகம் பின்வரும் நடவடிக்கை (கள்) அடிப்படையிலான கருத்துக்களைப் பெற்றுள்ளது ";
        this.feedbackRecallLabel="கடைசியாக நீங்கள் இந்த அறிக்கைக்கு * on # உடன் பதிலளித்தீர்கள். இன்று இந்த அறிக்கைக்கு நீங்கள் எவ்வாறு பதிலளிப்பீர்கள், ஏன்?"
        this.badgeTitle = "உங்கள் பேட்ஜ்கள்";
        this.orgRank = "அமைப்பு தரவரிசை";
        this.teamRank = "அணி தரவரிசை";
        this.totalPoints = "மொத்த புள்ளிகள்";

        this.badgeTitle = "మీ బ్యాడ్జ్‌లు";
        this.orgRank = "సంస్థ ర్యాంక్";
        this.teamRank = "జట్టు ర్యాంక్";
        this.totalPoints = "మొత్తం పాయింట్లు";
        this.Youwon = "నువ్వు గెలిచావు";
        this.badges = "பேட்ஜ்";
        this.badgeSingular = "பேட்ஜ்";
        this.PointsSystem = "బ్యాడ్జ్";
        this.Points = "పాయింట్లు";
        this.Calculation = "లెక్కింపు";
        this.BadgeName = "బ్యాడ్జ్ పేరు";
        this.Description = "వివరణ";
        this.Howarepointscalculated = "పాయింట్లు ఎలా లెక్కించబడతాయి?";
        this.Howteamrank = "మీ జట్టు ర్యాంకును మేము ఎలా నిర్ణయిస్తాము?";
        this.HowOrgrank = "మీ సంస్థ ర్యాంకును మేము ఎలా నిర్ణయిస్తాము?";
        this.Trailblazer = "ట్రైల్బ్లేజర్";
        this.Architect = "ఆర్కిటెక్ట్";
        this.Chieftain = "అధిపతి";
        this.Engineer = "ఇంజనీర్";
        this.Laureate = "గ్రహీత";
        this.Wordsmith = "వర్డ్స్మిత్";
        this.trailblazerContent = "సంస్కృతి మార్గదర్శకుడిగా మారడానికి మొదటి అడుగు వేసినందుకు ఈ బ్యాడ్జ్ మీదే. అనువర్తనంలోకి లాగిన్ అయినందుకు మీరు 1 పాయింట్‌ను గెలుచుకుంటారు.";
        this.architectContent = "ஒரு தொடர்ச்சியை காப்பாற்றி மற்றும் வாரம் முழுவதும் ஒவ்வொரு கேள்விக்கும் பதிலளித்து இந்த சின்னத்தை பெறுங்கள். உங்கள் நிலைத்தன்மையை கொண்டுவந்து உங்கள் அர்ப்பணிப்பை காட்சியளிக்கவும்!";
        this.chieftainContent = "சீப்டெயின் சின்னத்துடன் மிக உயர்ந்த கீர்த்தி பெறுங்கள்! உங்கள் செயற்பாடுகளுக்காக மற்றும் வாரம் முழுவதும் அனைத்து கேள்விகளுக்கும் கருத்து அளிக்க 30 புள்ளிகளை பெற்றுக்கொள்ளுங்கள். உங்கள் ஈடுபாடு உங்களை வித்தியாசமாக காட்டுகிறது!";        
        this.engineerContent = "మీ వారపు పరంపరను నెలలో కనీసం 3 సార్లు క్లెయిమ్ చేయడం ద్వారా ఈ బ్యాడ్జ్‌తో 10 పాయింట్లను గెలుచుకోండి";
        this.laureateContent = "స్పాట్ ఎ గ్రహీత వివరణాత్మక వ్యాఖ్య లేదా అభిప్రాయాన్ని వదిలివేస్తున్నారా? ఈ బ్యాడ్జ్‌తో 15 పాయింట్లను తక్షణమే గెలవండి ..";
        this.wordsmithContent = "క్లుప్త వ్యాఖ్యను ఇచ్చినందుకు ఈ బ్యాడ్జ్ మీకు 3 పాయింట్లను గెలుచుకుంటుంది.";
        this.pointDescription1 = "ప్రతి బ్యాడ్జ్ దానితో సంబంధం ఉన్న పాయింట్లను కలిగి ఉంటుంది. మీరు బ్యాడ్జిని అందుకున్నప్పుడు, మీరు ఇప్పటికే ఉన్న మీ పాయింట్లకు జోడించబడే అనుబంధ పాయింట్లను కూడా పొందుతారు. పాయింట్లు నెల వారీగా లెక్కించబడతాయి మరియు ప్రతి నెల ప్రారంభంలో స్కోరు 0 కి రీసెట్ అవుతుంది";
        this.pointDescription2 = "మీ జట్టులోని ఇతరుల స్కోర్‌లతో పోలిస్తే టీమ్ ర్యాంక్ మీ పాయింట్ల ఆధారంగా నిర్ణయించబడుతుంది. జట్లు నిర్వచించడానికి మీ సంస్థ ఎంచుకున్న జనాభా ప్రకారం జట్టు నిర్వచించబడుతుంది";
        this.pointDescription3 = "మీ సంస్థలోని ప్రతి ఒక్కరి పాయింట్లతో పోలిస్తే సంస్థ ర్యాంక్ మీ పాయింట్ల ఆధారంగా నిర్ణయించబడుతుంది ..";
        this.ok = "అలాగే";
        this.congratulations = "అభినందనలు";
        this.trailblazerCongratulations = "మీరు ట్రైల్బ్లేజర్ బ్యాడ్జ్ అందుకున్నారు";
        this.laureateCongratulations = "ஆம்! நீங்கள் பரிசு பெற்ற பேட்ஜைப் பெற்றுள்ளீர்கள்";
        this.wordsmithCongratulations = "நீங்கள் வேர்ட்ஸ்மித் பேட்ஜைப் பெற்றுள்ளீர்கள்";
        this.architectCongratulations = "நீங்கள் கட்டிடக் கலைஞர் பேட்ஜைப் பெற்றுள்ளீர்கள்";
        this.chieftainCongratulations = "நீங்கள் தலைவர் பேட்ஜைப் பெற்றுள்ளீர்கள்";
        this.submitResponseNote = " * உங்கள் பதில் பதிவு செய்ய, இறுதி பக்கத்தில் சமர்ப்பி என்பதைக் கிளிக் செய்க"

        this.Anxious = "கவலை";
        this.anxiousMoodLabel = "நீங்கள் எப்படி உணர்கிறீர்கள் என்பதில் எந்தத் தீங்கும் இல்லை, உங்களை தயவுடன் நடத்துங்கள்! நாளை உங்களிடமிருந்து கேட்க காத்திருக்கிறேன்";
        this.anxiousSnackBar = "நாங்கள் புரிந்துகொள்கிறோம், நாங்கள் உங்களுக்காக இங்கே இருக்கிறோம்.";

        this.Uneasy = "மகிழ்ச்சியற்றது";
        this.uneasyMoodLabel = "இந்த நாட்களில் இருப்பது முற்றிலும் சாதாரணமானது, ஆனால் விஷயங்கள் எப்போதுமே இருப்பதைப் போலவே நடக்கும்! நாளை உங்களிடமிருந்து கேட்க காத்திருக்கிறேன்";
        this.uneasySnackBar = "இப்படி உணர்ந்தால் பரவாயில்லை. அது மேம்படும் என்று நம்புகிறோம்!";

        this.Okay = "சரி";
        this.okayMoodLabel = "சில நாட்கள் தான். ஆனால் மற்றவர்கள் விதிவிலக்காக இருக்க முடியும், உங்களிடம் பிந்தையது அதிகமாக இருக்கும் என்று நாங்கள் நம்புகிறோம்! நாளை உங்களிடமிருந்து கேட்க காத்திருக்கிறேன்";
        this.okaySnackBar = "அது நல்லது, அது நன்றாக இருக்கும் என்று நம்புகிறோம்!";

        this.Happy = "சந்தோஷமாக";
        this.happyMoodLabel = "நாளை ஒரு புதிய விடியல்; ஒரு புதிய நாள் மற்றும் நீங்கள் இன்னும் நன்றாக உணருவீர்கள் என்று நம்புகிறோம்! நாளை உங்களிடமிருந்து கேட்க காத்திருக்கிறேன்";
        this.happySnackBar="அதைப் பார்த்து நாங்கள் மகிழ்ச்சியடைகிறோம்!";

        this.Excited = "உற்சாகமாக";
        this.excitedMoodLabel ="நீங்கள் ஆச்சரியமாக உணர்கிறீர்கள், அதைத் தழுவி அதே மனநிலையுடன் திரும்பி வாருங்கள்! நாளை உங்களிடமிருந்து கேட்க காத்திருக்கிறேன்";
        this.excitedSnackBar = "அது நன்றாக இருக்கிறது, இதை வைத்துக்கொள்வோம்!";

        this.heresYourMood = "உங்கள் இன்றைய மனநிலை இங்கே";
        this.whatsYourMood = "இன்று உங்கள் மனநிலை என்ன?";
        this.submitResponseNote = " * உங்கள் பதில் பதிவு செய்ய, இறுதி பக்கத்தில் சமர்ப்பி என்பதைக் கிளிக் செய்க";
        this.questionsLeft = "கேள்விகள் மீதமுள்ளன";
        this.questionLeft = "கேள்விகள் மீதமுள்ளன";

        this.request = "எடுக்கப்பட்ட நடவடிக்கையை மதிப்பிடவும்"
        this.thankYouComment = "எங்கள் செயல்கள் குறித்து உங்கள் நேர்மையான கருத்தை அளித்ததற்கு மிகவும் நன்றி. மேலும் கொடுக்க (+) ஐகானை கிளிக் செய்யவும்..."
        this.actionOnFeedbackCommentPlaceholder = "இந்த நடவடிக்கை பற்றி நீங்கள் என்ன நினைக்கிறீர்கள்?";

        this.sixthOptionText = "மதிப்பிட போதுமான தகவல் இல்லை";

        this.ICICISurveyCompletedAlready = "நிலுவையில் உள்ள அறிக்கைகளுக்கு பதிலளிக்க வேண்டியதில்லை";
        break;

      //Gujarati - gu
      case "gu":
        this.welcomeText = "સ્વાગત છે";
        this.startSurveyText = "હવે સર્વે શરૂ કરો";
        this.surveyCompletedAlready =
          "તમે પહેલેથી જ તમારું સર્વેક્ષણ પૂર્ણ કર્યું હતું";
        this.somethingWentWrong =
          "કંઈક ખોટું થયું છે, કૃપા કરીને ફરીથી પ્રયાસ કરો";
        this.nextButton = "આગળ";
        this.previousButton = "અગાઉના";
        this.submitButton = "સબમિટ કરો";
        this.submittingButton = "સબમિટ કરી રહ્યું છે";
        this.logOut = "લૉગ આઉટ";
        this.selectLanguage = "ભાષા પસંદ કરો";
        this.tryAgainButton = "ફરીથી પ્રયત્ન કરો";
        this.writeHerePlaceHolder = "ટિપ્પણી દાખલ કરો";
        this.pleaseWaitWhileFetch =
          "કૃપા કરીને રાહ જુઓ જ્યારે અમે તમારું સર્વેક્ષણ કરીએ છીએ";
        this.thankYouText = "આ સર્વેમાં ભાગ લેવા બદલ આભાર";
        this.termsConditions = "શરતો અને નિયમો";
        this.privacyPolicy = "ગોપનીયતા નીતિ";
        this.brandGuidelines = "બ્રાન્ડ દિશાનિર્દેશો";
        this.contactNumber = "સંપર્ક નંબર";
        this.emailText = "ઇમેઇલ";
        this.aboutUsText = "અમારા વિશે";
        this.changePassword = "પાસવર્ડ બદલો";
        this.Badge = "બેજ";
        this.EngagementTrends = "સુખ વલણો";
        this.Perceptions = "ધારણાઓ";
        this.InvalidInputError =
          "એચટીએમએલ અને સ્ક્રિપ્ટ ટsગ્સને મંજૂરી નથી (<>)";
        this.openEndedPollPlaceholder = "કૃપા કરીને તમારો પ્રતિસાદ દાખલ કરો";
        this.floatingCommentLable = "કૃપા કરી વધુ વિગતવાર ...";
        this.actionPointDialogHeader="તમારા પ્રામાણિક પ્રતિસાદ બદલ આભાર. મેનેજમેન્ટે નીચે મુજબની કાર્યવાહી (ઓ) આધારિત પ્રતિસાદ પ્રાપ્ત કર્યો છે ";
        this.feedbackRecallLabel="છેલ્લી વાર તમે આ નિવેદન પર * ના રોજ # સાથે જવાબ આપ્યો હતો. આજે તમે આ નિવેદન પર કેવી પ્રતિક્રિયા આપશો અને કેમ?"
        this.badgeTitle = "તમારા બેજેસ";
        this.orgRank = "સંસ્થા ક્રમ";
        this.teamRank = "ટીમ ક્રમ";
        this.totalPoints = "કુલ પોઇંટ્સ";


        this.badgeTitle = "તમારા બેજેસ";
        this.orgRank = "સંસ્થા ક્રમ";
        this.teamRank = "ટીમ ક્રમ";
        this.totalPoints = "કુલ પોઇંટ્સ";
        this.Youwon = "કુલ પોઇંટ્સ";
        this.badges = "બેજ";
        this.badgeSingular = "બેજ";
        this.PointsSystem = "પોઇન્ટ સિસ્ટમ";
        this.Points = "પોઇન્ટ્સ";
        this.Calculation = "ગણતરી";
        this.BadgeName = "બેજ નામ";
        this.Description = "વર્ણન";
        this.Howarepointscalculated = "પોઇન્ટની ગણતરી કેવી રીતે કરવામાં આવે છે?";
        this.Howteamrank = "અમે તમારી ટીમ ક્રમ કેવી રીતે નક્કી કરી શકીએ?";
        this.HowOrgrank = "અમે કેવી રીતે તમારી સંસ્થા ક્રમ નક્કી કરી શકું?";
        this.Trailblazer = "ટ્રેઇલબ્લેઝર";
        this.Architect = "આર્કિટેક્ટ";
        this.Chieftain = "સરદાર";
        this.Engineer = "ઇજનેર";
        this.Laureate = "વિજેતા";
        this.Wordsmith = "વર્ડસ્મિથ";
        this.trailblazerContent = "સંસ્કૃતિ પાયોનિયર બનવાનું પ્રથમ પગલું ભરવા માટે આ બેજ તમારો છે. તમે એપ્લિકેશનમાં લgingગ ઇન કરવા માટે 1 પોઇન્ટ જીતશો.";
        this.architectContent = "આ બેજ મેળવવા માટે સતત પ્રયાસ કરો અને અઠવાડિયાની દરેક પ્રશ્નનો જવાબ આપો અને કોઈ પણ પ્રશ્ન ચૂકતા નહિ. તમારી સતતતા જાળવો અને તમારી સમર્પણતા દર્શાવો!";
        this.chieftainContent = "ચીફટેન બેજ સાથે સૌથી ઊંચો સન્માન મેળવો! 30 પોઈન્ટ મેળવો તમારી સક્રિય ભાગીદારી માટે અને અઠવાડિયા દરમિયાન તમામ પ્રશ્નો પર ટિપ્પણીઓ કરવા માટે. તમારી ભાગીદારી તમને અલગ બનાવે છે!";        
        this.engineerContent = "મહિનામાં ઓછામાં ઓછા 3 વાર તમારી સાપ્તાહિક સ્ટ્રીકનો દાવો કરીને આ બેજ સાથે 10 પોઇન્ટ જીતવા";
        this.laureateContent = "આ સ્પોટ પર એક વિશિષ્ટ ટિપ્પણી અથવા પ્રતિસાદ છોડીને? આ બેજ સાથે તરત જ 15 પોઇન્ટ જીતવા ..";
        this.wordsmithContent = "આ બેજ તમને ટૂંકી ટિપ્પણી આપવા માટે 3 પોઇન્ટ જીતે છે.";
        this.pointDescription1 = "દરેક બેજ તેની સાથે સંકળાયેલ પોઇન્ટ ધરાવે છે. જેમ જેમ તમને બેજ મળે છે, તેમ તમને સંકળાયેલ પોઇન્ટ પણ મળે છે જે તમારા હાલના પોઇન્ટ્સમાં ઉમેરતા રહે છે. પોઇન્ટની ગણતરી મહિના મુજબ કરવામાં આવે છે અને દર મહિનાની શરૂઆતમાં સ્કોર 0 પર ફરીથી સેટ થાય છે";
        this.pointDescription2 = "તમારી ટીમના બીજાના સ્કોર્સની તુલનામાં ટીમ રેન્ક તમારા પોઇન્ટના આધારે નક્કી કરવામાં આવે છે. ટીમને ડેમોગ્રાફી અનુસાર વ્યાખ્યાયિત કરવામાં આવે છે જે ટીમોને નિર્ધારિત કરવા માટે તમારી સંસ્થા દ્વારા લેવામાં આવે છે";
        this.pointDescription3 = "તમારી સંસ્થાના દરેકના પોઇન્ટની તુલનામાં તમારા પોઇન્ટના આધારે સંસ્થા ક્રમ નક્કી કરવામાં આવે છે ..";
        this.ok = "બરાબર";
        this.congratulations = "અભિનંદન";
        this.trailblazerCongratulations = "તમને ટ્રેઇલબ્લેઝર બેજ મળ્યો છે";
        this.laureateCongratulations = "હા! તમને વિજેતા બેજ મળ્યો છે";
        this.wordsmithCongratulations = "તમને વર્ડસ્મિથ બેજ મળ્યો છે";
        this.architectCongratulations = "તમને આર્કિટેક બેજ મળ્યો છે";
        this.chieftainCongratulations = "તમને સરદાર બેજ મળ્યો છે";
        this.submitResponseNote = "* તમારા પ્રતિસાદને રેકોર્ડ કરવા માટે, કૃપા કરીને અંતિમ પૃષ્ઠ પર સબમિટ પર ક્લિક કરો"

        this.Anxious = "બેચેન";
        this.anxiousMoodLabel = "તમને કેવું લાગે છે તેમાં કોઈ હાનિ નથી, તમારી સાથે દયાથી વર્તે! કાલે તમારી પાસેથી સુનાવણીની રાહ જોઈ રહ્યા છીએ";
        this.anxiousSnackBar = "અમે સમજીએ છીએ, અમે તમારા માટે અહીં છીએ.";

        this.Uneasy = "નાખુશ";
        this.uneasyMoodLabel = "આ દિવસો હોય તે સંપૂર્ણપણે સામાન્ય છે, પરંતુ વસ્તુઓ હંમેશાની જેમ જ પડી જશે! કાલે તમારી પાસેથી સુનાવણીની રાહ જોઈ રહ્યા છીએ";
        this.uneasySnackBar = "આ રીતે અનુભવવું ઠીક છે. અમે આશા રાખીએ છીએ કે તે સુધરે!";

        this.Okay = "બરાબર";
        this.okayMoodLabel = "કેટલાક દિવસો માત્ર મેહ છે. પરંતુ અન્ય અપવાદરૂપ હોઈ શકે છે, અમે આશા રાખીએ છીએ કે તમારી પાસે બાદમાં વધુ હશે! કાલે તમારી પાસેથી સુનાવણીની રાહ જોઈ રહ્યા છીએ";
        this.okaySnackBar = "તે સારું છે, અમને આશા છે કે તે વધુ સારું થશે!";

        this.Happy = "સુખી";
        this.happyMoodLabel = "કાલે નવી પરો છે; નવો દિવસ અને અમે આશા રાખીએ છીએ કે તમને વધુ સારું લાગશે! કાલે તમારી પાસેથી સુનાવણીની રાહ જોઈ રહ્યા છીએ";
        this.happySnackBar="અમે તે જોઈને ખુશ છીએ!";

        this.Excited = "ઉત્સાહિત";
        this.excitedMoodLabel ="તમે આશ્ચર્યજનક અનુભવો છો, તેને સ્વીકારો અને તે જ ભાવના સાથે પાછા આવો! કાલે તમારી પાસેથી સુનાવણીની રાહ જોઈ રહ્યા છીએ";
        this.excitedSnackBar = "તે મહાન છે, ચાલો આ ચાલુ રાખીએ!";

        this.heresYourMood = "આ રહ્યો તમારો આજનો મૂડ";
        this.whatsYourMood = "આજે તમારો મૂડ કેવો છે?";
        this.submitResponseNote = "* તમારા પ્રતિસાદને રેકોર્ડ કરવા માટે, કૃપા કરીને અંતિમ પૃષ્ઠ પર સબમિટ પર ક્લિક કરો";
        this.questionsLeft = "પ્રશ્નો બાકી છે";
        this.questionLeft = "પ્રશ્નો બાકી છે";

        this.request = "કૃપા કરીને લેવામાં આવેલી ક્રિયાને રેટ કરો"
        this.thankYouComment = "અમારા કાર્યો પર તમારો પ્રામાણિક અભિપ્રાય આપવા બદલ તમારો ખૂબ ખૂબ આભાર. વધુ આપવા માટે (+) ચિહ્ન પર ક્લિક કરો..."
        this.actionOnFeedbackCommentPlaceholder = "તમે આ ક્રિયા વિશે શું વિચારો છો?";

        this.sixthOptionText = "રેટ કરવા માટે પૂરતી માહિતી નથી";

        this.ICICISurveyCompletedAlready = "જવાબ આપવા માટે કોઈ બાકી નિવેદનો નથી";
        break;

      // Kannada - kn
      case "kn":
        this.welcomeText = "ಸ್ವಾಗತ";
        this.startSurveyText = "ಈಗ ಸಮೀಕ್ಷೆ ಪ್ರಾರಂಭಿಸಿ";
        this.surveyCompletedAlready =
          "ನೀವು ಈಗಾಗಲೇ ನಿಮ್ಮ ಸಮೀಕ್ಷೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ";
        this.somethingWentWrong =
          "ಯಾವುದೋ ತಪ್ಪು ಸಂಭವಿಸಿದೆ, ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ";
        this.nextButton = "ಮುಂದೆ";
        this.previousButton = "ಹಿಂದಿನದು";
        this.submitButton = "ಸಲ್ಲಿಸಲು";
        this.submittingButton = "ಸಲ್ಲಿಸಲಾಗುತ್ತಿದೆ";
        this.logOut = "ಲಾಗ್ ಔಟ್";
        this.selectLanguage = "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ";
        this.tryAgainButton = "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು";
        this.writeHerePlaceHolder = "ದಯವಿಟ್ಟು ಕಾಮೆಂಟ್ ನಮೂದಿಸಿ";
        this.pleaseWaitWhileFetch =
          "ನಾವು ನಿಮ್ಮ ಸಮೀಕ್ಷೆಯನ್ನು ಪಡೆದುಕೊಳ್ಳುವವರೆಗೆ ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ";
        this.thankYouText = "ಈ ಸಮೀಕ್ಷೆಯಲ್ಲಿ ನೀವು ಭಾಗವಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು";
        this.termsConditions = "ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು";
        this.privacyPolicy = "ಗೌಪ್ಯತಾ ನೀತಿ";
        this.brandGuidelines = "ಬ್ರ್ಯಾಂಡ್ ಮಾರ್ಗಸೂಚಿಗಳು";
        this.contactNumber = "ಸಂಪರ್ಕ ಸಂಖ್ಯೆ";
        this.emailText = "ಇಮೇಲ್";
        this.aboutUsText = "ನಮ್ಮ ಬಗ್ಗೆ";
        this.changePassword = "ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ";
        this.Badge = "ಬ್ಯಾಡ್ಜ್";
        this.EngagementTrends = "ನಿಶ್ಚಿತಾರ್ಥದ ಪ್ರವೃತ್ತಿಗಳು";
        this.Perceptions = "ಗ್ರಹಿಕೆಗಳು";
        this.InvalidInputError =
          "HTML ಮತ್ತು ಸ್ಕ್ರಿಪ್ಟ್ ಟ್ಯಾಗ್‌ಗಳನ್ನು ಅನುಮತಿಸಲಾಗುವುದಿಲ್ಲ (<>)";
        this.openEndedPollPlaceholder =
          "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನಮೂದಿಸಿ";
          this.floatingCommentLable = "ದಯವಿಟ್ಟು ಇನ್ನಷ್ಟು ವಿಸ್ತಾರವಾಗಿ ...";
        this.actionPointDialogHeader="ನಿಮ್ಮ ಪ್ರಾಮಾಣಿಕ ಪ್ರತಿಕ್ರಿಯೆಗೆ ಧನ್ಯವಾದಗಳು. ನಿರ್ವಹಣೆಯು ಈ ಕೆಳಗಿನ ಕ್ರಮ (ಗಳ) ಆಧಾರದ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಸ್ವೀಕರಿಸಿದೆ ";
        this.badgeTitle = "ನಿಮ್ಮ ಬ್ಯಾಡ್ಜ್‌ಗಳು";
        this.orgRank = "ಸಂಸ್ಥೆ ಶ್ರೇಣಿ";
        this.teamRank = "ತಂಡದ ಶ್ರೇಣಿ";
        this.totalPoints = "ಒಟ್ಟು ಅಂಕಗಳು";

        this.badgeTitle = "ನಿಮ್ಮ ಬ್ಯಾಡ್ಜ್‌ಗಳು";
        this.orgRank = "ಸಂಸ್ಥೆ ಶ್ರೇಣಿ";
        this.teamRank = "ತಂಡದ ಶ್ರೇಣಿ";
        this.totalPoints = "ಒಟ್ಟು ಅಂಕಗಳು";
        this.Youwon = "ನೀನು ಗೆದ್ದೆ";
        this.badges = "ಬ್ಯಾಡ್ಜ್";
        this.badgeSingular = "બેજ";
        this.PointsSystem = "ಪಾಯಿಂಟ್ ಸಿಸ್ಟಮ್";
        this.Points = "ಅಂಕಗಳು";
        this.Calculation = "ಲೆಕ್ಕಾಚಾರ";
        this.BadgeName = "ಬ್ಯಾಡ್ಜ್ ಹೆಸರು";
        this.Description = "ವಿವರಣೆ";
        this.Howarepointscalculated = "ಅಂಕಗಳನ್ನು ಹೇಗೆ ಲೆಕ್ಕಹಾಕಲಾಗುತ್ತದೆ?";
        this.Howteamrank = "ನಿಮ್ಮ ತಂಡದ ಶ್ರೇಣಿಯನ್ನು ನಾವು ಹೇಗೆ ನಿರ್ಧರಿಸುತ್ತೇವೆ?";
        this.HowOrgrank = "ನಿಮ್ಮ ಸಂಸ್ಥೆಯ ಶ್ರೇಣಿಯನ್ನು ನಾವು ಹೇಗೆ ನಿರ್ಧರಿಸುತ್ತೇವೆ?";
        this.Trailblazer = "ಟ್ರೈಲ್ಬ್ಲೇಜರ್";
        this.Architect = "ವಾಸ್ತುಶಿಲ್ಪಿ";
        this.Chieftain = "ಮುಖ್ಯಸ್ಥ";
        this.Engineer = "ಎಂಜಿನಿಯರ್";
        this.Laureate = "ಪ್ರಶಸ್ತಿ ವಿಜೇತ";
        this.Wordsmith = "ವರ್ಡ್ಸ್ಮಿತ್";
        this.trailblazerContent = "ಸಂಸ್ಕೃತಿ ಪ್ರವರ್ತಕನಾಗಲು ಮೊದಲ ಹೆಜ್ಜೆ ಇಟ್ಟಿದ್ದಕ್ಕಾಗಿ ಈ ಬ್ಯಾಡ್ಜ್ ನಿಮ್ಮದಾಗಿದೆ. ಅಪ್ಲಿಕೇಶನ್‌ಗೆ ಲಾಗ್ ಇನ್ ಮಾಡಲು ನೀವು 1 ಪಾಯಿಂಟ್ ಗೆಲ್ಲುತ್ತೀರಿ.";
        this.architectContent = "ಈ ಬ್ಯಾಡ್ಜ್ ಅನ್ನು ಸತತವಾಗಿ ಉಳಿಸಿಕೊಂಡು ವಾರದ ಪ್ರತಿಯೊಂದು ಪ್ರಶ್ನೆಗೆ ಉತ್ತರ ನೀಡುವುದರಿಂದ ಗಳಿಸಬಹುದು. ನಿಮ್ಮ ನಿರಂತರತೆಯನ್ನು ಕಾಪಾಡಿ ಮತ್ತು ನಿಮ್ಮ ಬದ್ಧತೆಯನ್ನು ತೋರಿಸಿ!";
        this.chieftainContent = "ಚೀಫ್‌ಟೇನ್ ಬ್ಯಾಡ್ಜ್‌ನೊಂದಿಗೆ ಅತ್ಯುತ್ತಮ ಗೌರವವನ್ನು ಪಡೆಯಿರಿ! ಸಕ್ರಿಯ ಪಾಲ್ಗೊಳ್ಳುವಿಕೆಗೆ ಮತ್ತು ವಾರದ ಎಲ್ಲಾ ಪ್ರಶ್ನೆಗಳಿಗೆ ಕಾಮೆಂಟ್ ಮಾಡುವುದಕ್ಕೆ 30 ಅಂಕಗಳನ್ನು ಗಳಿಸಿ. ನಿಮ್ಮ ನಿರಂತರತೆಯು ನಿಮಗೆ ವಿಭಿನ್ನತೆಯನ್ನು ನೀಡುತ್ತದೆ!";        
        this.engineerContent = "ನಿಮ್ಮ ಸಾಪ್ತಾಹಿಕ ಸರಣಿಯನ್ನು ತಿಂಗಳಲ್ಲಿ ಕನಿಷ್ಠ 3 ಬಾರಿ ಕ್ಲೈಮ್ ಮಾಡುವ ಮೂಲಕ ಈ ಬ್ಯಾಡ್ಜ್‌ನೊಂದಿಗೆ 10 ಅಂಕಗಳನ್ನು ಗೆದ್ದಿರಿ";
        this.laureateContent = "ಸ್ಪಾಟ್‌ನಲ್ಲಿ ಪ್ರಶಸ್ತಿ ವಿಜೇತರು ವಿವರವಾದ ಕಾಮೆಂಟ್ ಅಥವಾ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಬಿಡುತ್ತಾರೆಯೇ? ಈ ಬ್ಯಾಡ್ಜ್‌ನೊಂದಿಗೆ ತಕ್ಷಣ 15 ಅಂಕಗಳನ್ನು ಗೆದ್ದಿರಿ ..";
        this.wordsmithContent = "ಸಂಕ್ಷಿಪ್ತ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನೀಡಲು ಈ ಬ್ಯಾಡ್ಜ್ ನಿಮಗೆ 3 ಅಂಕಗಳನ್ನು ಗೆಲ್ಲುತ್ತದೆ.";
        this.pointDescription1 = "ಪ್ರತಿಯೊಂದು ಬ್ಯಾಡ್ಜ್‌ಗೆ ಸಂಬಂಧಿಸಿದ ಪಾಯಿಂಟ್‌ಗಳಿವೆ. ನೀವು ಬ್ಯಾಡ್ಜ್ ಸ್ವೀಕರಿಸಿದಂತೆ, ನಿಮ್ಮ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಬಿಂದುಗಳಿಗೆ ಸೇರಿಸುವ ಸಂಬಂಧಿತ ಅಂಕಗಳನ್ನು ಸಹ ನೀವು ಪಡೆಯುತ್ತೀರಿ. ಅಂಕಗಳನ್ನು ತಿಂಗಳ ಪ್ರಕಾರ ಲೆಕ್ಕಹಾಕಲಾಗುತ್ತದೆ ಮತ್ತು ಪ್ರತಿ ತಿಂಗಳ ಪ್ರಾರಂಭದಲ್ಲಿ ಸ್ಕೋರ್ 0 ಕ್ಕೆ ಮರುಹೊಂದಿಸಲಾಗುತ್ತದೆ";
        this.pointDescription2 = "ನಿಮ್ಮ ತಂಡದ ಇತರರ ಸ್ಕೋರ್‌ಗಳಿಗೆ ಹೋಲಿಸಿದರೆ ತಂಡದ ಶ್ರೇಣಿಯನ್ನು ನಿಮ್ಮ ಅಂಕಗಳ ಆಧಾರದ ಮೇಲೆ ನಿರ್ಧರಿಸಲಾಗುತ್ತದೆ. ತಂಡಗಳನ್ನು ವ್ಯಾಖ್ಯಾನಿಸಲು ನಿಮ್ಮ ಸಂಸ್ಥೆಯಿಂದ ಆರಿಸಲ್ಪಟ್ಟ ಜನಸಂಖ್ಯಾಶಾಸ್ತ್ರದ ಪ್ರಕಾರ ತಂಡವನ್ನು ವ್ಯಾಖ್ಯಾನಿಸಲಾಗಿದೆ";
        this.pointDescription3 = "ನಿಮ್ಮ ಸಂಸ್ಥೆಯ ಎಲ್ಲರ ಅಂಕಗಳಿಗೆ ಹೋಲಿಸಿದರೆ ನಿಮ್ಮ ಅಂಕಗಳನ್ನು ಆಧರಿಸಿ ಸಂಸ್ಥೆಯ ಶ್ರೇಣಿಯನ್ನು ನಿರ್ಧರಿಸಲಾಗುತ್ತದೆ ..";
        this.ok = "ಸರಿ";
        this.congratulations = "ಅಭಿನಂದನೆಗಳು";
        this.trailblazerCongratulations = "ನೀವು ಟ್ರೈಲ್ಬ್ಲೇಜರ್ ಬ್ಯಾಡ್ಜ್ ಅನ್ನು ಸ್ವೀಕರಿಸಿದ್ದೀರಿ";
        this.laureateCongratulations = "ವಾಹ್! ನೀವು ಪ್ರಶಸ್ತಿ ವಿಜೇತ ಬ್ಯಾಡ್ಜ್ ಸ್ವೀಕರಿಸಿದ್ದೀರಿ";
        this.wordsmithCongratulations = "ನೀವು ವರ್ಡ್ಸ್‌ಮಿತ್ ಬ್ಯಾಡ್ಜ್ ಸ್ವೀಕರಿಸಿದ್ದೀರಿ";
        this.architectCongratulations = "ನೀವು ಆರ್ಕಿಟೆಕ್ಟ್ ಬ್ಯಾಡ್ಜ್ ಸ್ವೀಕರಿಸಿದ್ದೀರಿ";
        this.chieftainCongratulations = "ನೀವು ಮುಖ್ಯಸ್ಥ ಬ್ಯಾಡ್ಜ್ ಸ್ವೀಕರಿಸಿದ್ದೀರಿ";
        this.submitResponseNote = "* ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ ರೆಕಾರ್ಡ್ ಆಗಲು, ದಯವಿಟ್ಟು ಅಂತಿಮ ಪುಟದಲ್ಲಿ ಸಲ್ಲಿಸು ಕ್ಲಿಕ್ ಮಾಡಿ"


        this.Anxious = "ಆತಂಕಕಾರಿ";
        this.anxiousMoodLabel = "ನೀವು ಹೇಗೆ ಭಾವಿಸುತ್ತೀರಿ ಎಂಬುದರಲ್ಲಿ ಯಾವುದೇ ಹಾನಿ ಇಲ್ಲ, ನಿಮ್ಮನ್ನು ದಯೆಯಿಂದ ನೋಡಿಕೊಳ್ಳಿ! ನಾಳೆ ನಿಮ್ಮಿಂದ ಕೇಳಲು ಎದುರು ನೋಡುತ್ತಿದ್ದೇನೆ";
        this.anxiousSnackBar = "ನಾವು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇವೆ, ನಾವು ನಿಮಗಾಗಿ ಇಲ್ಲಿದ್ದೇವೆ";

        this.Uneasy = "ಅತೃಪ್ತಿ";
        this.uneasyMoodLabel = "ಈ ದಿನಗಳಲ್ಲಿ ಇದು ಸಂಪೂರ್ಣವಾಗಿ ಸಾಮಾನ್ಯವಾಗಿದೆ, ಆದರೆ ಅವುಗಳು ಯಾವಾಗಲೂ ಇರುವಂತೆ ಸ್ಥಳದಲ್ಲಿ ಬೀಳುತ್ತವೆ! ನಾಳೆ ನಿಮ್ಮಿಂದ ಕೇಳಲು ಎದುರು ನೋಡುತ್ತಿದ್ದೇನೆ";
        this.uneasySnackBar = "ಈ ರೀತಿ ಭಾವಿಸುವುದು ತಪ್ಪಲ್ಲ. ಇದು ಸುಧಾರಿಸುತ್ತದೆ ಎಂದು ನಾವು ಭಾವಿಸುತ್ತೇವೆ!";

        this.Okay = "ಸರಿ";
        this.okayMoodLabel = "ಕೆಲವು ದಿನಗಳು ಕೇವಲ. ಆದರೆ ಇತರರು ಅಸಾಧಾರಣವಾಗಿರಬಹುದು, ನೀವು ಎರಡನೆಯದನ್ನು ಹೊಂದಿದ್ದೀರಿ ಎಂದು ನಾವು ಭಾವಿಸುತ್ತೇವೆ! ನಾಳೆ ನಿಮ್ಮಿಂದ ಕೇಳಲು ಎದುರು ನೋಡುತ್ತಿದ್ದೇನೆ";
        this.okaySnackBar = "ಅದು ಒಳ್ಳೆಯದು, ಅದು ಉತ್ತಮಗೊಳ್ಳುತ್ತದೆ ಎಂದು ನಾವು ಭಾವಿಸುತ್ತೇವೆ!";

        this.Happy = "ಸಂತೋಷ";
        this.happyMoodLabel = "ನಾಳೆ ಹೊಸ ಮುಂಜಾನೆ; ಹೊಸ ದಿನ ಮತ್ತು ನೀವು ಇನ್ನೂ ಉತ್ತಮವಾಗಿದ್ದೀರಿ ಎಂದು ನಾವು ಭಾವಿಸುತ್ತೇವೆ! ನಾಳೆ ನಿಮ್ಮಿಂದ ಕೇಳಲು ಎದುರು ನೋಡುತ್ತಿದ್ದೇನೆ";
        this.happySnackBar="ಅದನ್ನು ನೋಡಿ ನಮಗೆ ಸಂತೋಷವಾಗಿದೆ!";

        this.Excited = "ಹರ್ಷ";
        this.excitedMoodLabel ="ನೀವು ಅದ್ಭುತವಾಗಿದ್ದೀರಿ, ಅದನ್ನು ಸ್ವೀಕರಿಸಿ ಮತ್ತು ಅದೇ ಆತ್ಮದಿಂದ ಹಿಂತಿರುಗಿ! ನಾಳೆ ನಿಮ್ಮಿಂದ ಕೇಳಲು ಎದುರು ನೋಡುತ್ತಿದ್ದೇನೆ";
        this.excitedSnackBar = "ಇದು ಅದ್ಭುತವಾಗಿದೆ, ಇದನ್ನು ಮುಂದುವರಿಸೋಣ!";

        this.heresYourMood = "ನಿಮ್ಮ ಇಂದಿನ ಮನಸ್ಥಿತಿ ಇಲ್ಲಿದೆ";
        this.whatsYourMood = "ಇಂದು ನಿಮ್ಮ ಮನಸ್ಥಿತಿ ಏನು?";
        this.submitResponseNote = "* ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ ರೆಕಾರ್ಡ್ ಆಗಲು, ದಯವಿಟ್ಟು ಅಂತಿಮ ಪುಟದಲ್ಲಿ ಸಲ್ಲಿಸು ಕ್ಲಿಕ್ ಮಾಡಿ";
        this.questionsLeft = "પ્ಪ್ರಶ್ನೆಗಳು ಉಳಿದಿವೆ";
        this.questionLeft = "પ્ಪ್ರಶ್ನೆಗಳು ಉಳಿದಿವೆ";


        this.request = "ತೆಗೆದುಕೊಂಡ ಕ್ರಮವನ್ನು ದಯವಿಟ್ಟು ರೇಟ್ ಮಾಡಿ"
        this.thankYouComment = "ನಮ್ಮ ಕ್ರಿಯೆಗಳ ಬಗ್ಗೆ ನಿಮ್ಮ ಪ್ರಾಮಾಣಿಕ ಅಭಿಪ್ರಾಯವನ್ನು ನೀಡಿದ್ದಕ್ಕಾಗಿ ತುಂಬಾ ಧನ್ಯವಾದಗಳು. ಹೆಚ್ಚಿನದನ್ನು ನೀಡಲು (+) ಐಕಾನ್ ಕ್ಲಿಕ್ ಮಾಡಿ..."
        this.actionOnFeedbackCommentPlaceholder = "ಈ ಕ್ರಿಯೆಯ ಬಗ್ಗೆ ನಿಮ್ಮ ಆಲೋಚನೆ ಏನು?";

        this.sixthOptionText = "ರೇಟ್ ಮಾಡಲು ಸಾಕಷ್ಟು ಮಾಹಿತಿ ಇಲ್ಲ";

        this.ICICISurveyCompletedAlready = "ಯಾವುದೇ ಬಾಕಿ ಉಳಿದಿರುವ ಹೇಳಿಕೆಗಳಿಗೆ ಉತ್ತರಿಸಲಾಗುವುದಿಲ್ಲ";
        break;

      //Odiya - or
      case "or":
        this.welcomeText = "ସ୍ୱାଗତ";
        this.startSurveyText = "ବର୍ତ୍ତମାନ ସର୍ଭେ ଆରମ୍ଭ କରନ୍ତୁ |";
        this.surveyCompletedAlready = "ସର୍ଭେ ପ୍ରଶ୍ନ ଉପଲବ୍ଧ ନାହିଁ |";
        this.somethingWentWrong =
          "କିଛି ଭୁଲ ହୋଇଗଲା, ଦୟାକରି ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |";
        this.nextButton = "ପରବର୍ତ୍ତୀ";
        this.previousButton = "ପୂର୍ବ";
        this.submitButton = "ଦାଖଲ କର |";
        this.submittingButton = "ଦାଖଲ";
        this.logOut = "ପ୍ରସ୍ଥାନ କର";
        this.selectLanguage = "ଭାଷା ଚୟନ କରନ୍ତୁ |";
        this.tryAgainButton = "ପୁଣିଥରେ ଚେଷ୍ଟା କରନ୍ତୁ";
        this.writeHerePlaceHolder = "ଦୟାକରି ମନ୍ତବ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |";
        this.pleaseWaitWhileFetch =
          "ଆମେ ଆପଣଙ୍କର ସର୍ଭେ ଆଣିବାବେଳେ ଦୟାକରି ଅପେକ୍ଷା କରନ୍ତୁ |";
        this.thankYouText = "ଏହି ସର୍ଭେରେ ଆପଣଙ୍କ ଅଂଶଗ୍ରହଣ ପାଇଁ ଧନ୍ୟବାଦ |";
        this.termsConditions = "ସର୍ତ୍ତାବଳୀ";
        this.privacyPolicy = "ଗୋପନୀୟତା ନୀତି";
        this.brandGuidelines = "ବ୍ରାଣ୍ଡ ଗାଇଡ୍ଲାଇନ୍ସ |";
        this.contactNumber = "ଯୋଗାଯୋଗ ନମ୍ବର |";
        this.emailText = "ଇମେଲ୍ |";
        this.aboutUsText = "ଆମ ବିଷୟରେ";
        this.changePassword = "ପାସୱାର୍ଡ ପରିବର୍ତ୍ତନ କରନ୍ତୁ";
        this.Badge = "ବ୍ୟାଜ୍";
        this.EngagementTrends = "ଯୋଗଦାନ ଧାରା |";
        this.Perceptions = "ଧାରଣା";
        this.InvalidInputError =
          "Html ଏବଂ ସ୍କ୍ରିପ୍ଟ ଟ୍ୟାଗ୍ ଅନୁମୋଦିତ ନୁହେଁ (<>)";
        this.openEndedPollPlaceholder = "ଦୟାକରି ଆପଣଙ୍କର ମତାମତ ପ୍ରବେଶ କରନ୍ତୁ |";
        this.floatingCommentLable = "ଦୟାକରି ଆଗକୁ ବିସ୍ତାର କରନ୍ତୁ ...";
        this.actionPointDialogHeader="ଆପଣଙ୍କର ସଚ୍ଚୋଟ ମତାମତ ପାଇଁ ଧନ୍ୟବାଦ | ଗ୍ରହଣ କରାଯାଇଥିବା କାର୍ଯ୍ୟାନୁଷ୍ଠାନ (ଗୁଡିକ) ଆଧାର ମତାମତ ଗ୍ରହଣ କରିଛି ପରିଚାଳନା";
        this.badgeTitle = "ତୁମର ବ୍ୟାଜ୍";
        this.orgRank = "ସଂଗଠନ ମାନ୍ୟତା |";
        this.teamRank = "ଦଳ ମାନ୍ୟତା |";
        this.totalPoints = "ମୋଟ ପଏଣ୍ଟ |";

        this.badgeTitle = "ତୁମର ବ୍ୟାଜ୍";
        this.orgRank = "ସଂଗଠନ ରାଙ୍କ";
        this.teamRank = "ଦଳ ମାନ୍ୟତା |";
        this.totalPoints = "ମୋଟ ପଏଣ୍ଟ |";
        this.Youwon = "ତୁମେ ଜିତିଲ";
        this.badges = "ବ୍ୟାଜ୍";
        this.badgeSingular = "ବ୍ୟାଜ୍";
        this.PointsSystem = "ପଏଣ୍ଟ ସିଷ୍ଟମ୍ |";
        this.Points = "ପଏଣ୍ଟସ୍";
        this.Calculation = "ଗଣନା";
        this.BadgeName = "ବ୍ୟାଜ୍ ନାମ";
        this.Description = "ବର୍ଣ୍ଣନା";
        this.Howarepointscalculated = "ପଏଣ୍ଟଗୁଡିକ କିପରି ଗଣନା କରାଯାଏ?";
        this.Howteamrank = "ଆମେ କିପରି ତୁମର ଦଳ ମାନ୍ୟତା ସ୍ଥିର କରିବୁ?";
        this.HowOrgrank = "ଆମେ କିପରି ତୁମର ସଂଗଠନ ମାନ୍ୟତା ସ୍ଥିର କରିବୁ?";
        this.Trailblazer = "ଟ୍ରେଲବ୍ଲେଜର";
        this.Architect = "ସ୍ଥପତି";
        this.Chieftain = "ମୁଖିଆ";
        this.Engineer = "ଇଞ୍ଜିନିୟର";
        this.Laureate = "ବିଜେତା";
        this.Wordsmith = "ୱାର୍ଡସ୍ମିଥ୍ |";
        this.trailblazerContent = "ଏକ ସଂସ୍କୃତି ଅଗ୍ରଦୂତ ହେବାର ପ୍ରଥମ ପଦକ୍ଷେପ ନେବା ପାଇଁ ଏହି ବ୍ୟାଜ୍ ଆପଣଙ୍କର ଅଟେ | ଆପରେ ଲଗ୍ କରିବା ପାଇଁ ଆପଣ 1 ପଏଣ୍ଟ ଜିତନ୍ତି |";
        this.architectContent = "ଆପଣ ସଂବିଧାନ ରଖି ସପ୍ତାହର ପ୍ରତ୍ୟେକ ପ୍ରଶ୍ନକୁ ଉତ୍ତର ଦେଇ ଏହି ବ୍ୟାଜ୍ ଅର୍ଜନ କରନ୍ତୁ। ଆପଣଙ୍କର ନିରନ୍ତରତାକୁ ରକ୍ଷା କରନ୍ତୁ ଏବଂ ଆପଣଙ୍କର ଦୟା ଦର୍ଶାନ୍ତୁ!";
        this.chieftainContent = "ଚୀଫଟେନ ବ୍ୟାଜ୍ ସହିତ ସର୍ବୋଚ୍ଚ ସମ୍ମାନ ପାଆନ୍ତୁ! ସକ୍ରିୟ ଅଂଶଗ୍ରହଣ ଓ ସପ୍ତାହ ଭରି ସମସ୍ତ ପ୍ରଶ୍ନମାନେ କମେଣ୍ଟ କରି 30 ଅଙ୍କ ଅର୍ଜନ କରନ୍ତୁ। ଆପଣଙ୍କର ସହଭାଗୀ ଆପଣଙ୍କୁ ଅନ୍ୟ ଭାବରେ ପ୍ରଦର୍ଶିତ କରେ!";        
        this.engineerContent = "ଏକ ମାସରେ ଅତି କମରେ ଆପଣଙ୍କର ସାପ୍ତାହିକ ଧାରା ଦାବି କରି ଏହି ବ୍ୟାଜ୍ ସହିତ 10 ପଏଣ୍ଟ ଜିତନ୍ତୁ |";
        this.laureateContent = "ଏକ ସ୍ପଟ୍ ଉପରେ ଜଣେ ବିଜେତା ଏକ ବିସ୍ତୃତ ମନ୍ତବ୍ୟ କିମ୍ବା ମତାମତ ଛାଡିଛନ୍ତି କି? ଏହି ବ୍ୟାଜ୍ ସହିତ ତୁରନ୍ତ 15 ପଏଣ୍ଟ ଜିତନ୍ତୁ ..";
        this.wordsmithContent = "ଏକ ସଂକ୍ଷିପ୍ତ ମନ୍ତବ୍ୟ ଛାଡିବା ପାଇଁ ଏହି ବ୍ୟାଜ୍ ଆପଣଙ୍କୁ 3 ପଏଣ୍ଟ ଜିତେ |";
        this.pointDescription1 = "ପ୍ରତ୍ୟେକ ବ୍ୟାଜ୍ ସହିତ ଏହା ସହିତ ଜଡିତ ପଏଣ୍ଟ ଅଛି | ଯେହେତୁ ତୁମେ ଏକ ବ୍ୟାଜ୍ ଗ୍ରହଣ କରୁଛ, ତୁମେ ମଧ୍ୟ ସଂପୃକ୍ତ ପଏଣ୍ଟ ପାଇବ ଯାହା ତୁମର ବିଦ୍ୟମାନ ପଏଣ୍ଟରେ ଯୋଡି ହୋଇ ରହିବ | ପଏଣ୍ଟଗୁଡିକ ମାସ ଜ୍ଞାନୀ ଭାବରେ ଗଣନା କରାଯାଏ ଏବଂ ପ୍ରତ୍ୟେକ ମାସ ଆରମ୍ଭରେ ସ୍କୋର 0 କୁ ପୁନ et ସେଟ୍ ହୁଏ |";
        this.pointDescription2 = "ଆପଣଙ୍କ ଦଳର ଅନ୍ୟମାନଙ୍କ ସ୍କୋର ତୁଳନାରେ ଟିମ୍ ରାଙ୍କ୍ ଆପଣଙ୍କ ପଏଣ୍ଟ ଆଧାରରେ ସ୍ଥିର ହୋଇଛି | ଦଳ ଡେମୋଗ୍ରାଫି ଅନୁଯାୟୀ ବ୍ୟାଖ୍ୟା କରାଯାଇଛି ଯାହା ଦଳଗୁଡ଼ିକୁ ବ୍ୟାଖ୍ୟା କରିବାକୁ ଆପଣଙ୍କ ସଂସ୍ଥା ଦ୍ୱାରା ବାଛିଥାଏ |";
        this.pointDescription3 = "ତୁମର ସଂଗଠନର ଅନ୍ୟ ସମସ୍ତଙ୍କ ପଏଣ୍ଟ ତୁଳନାରେ ତୁମର ପଏଣ୍ଟ ଆଧାରରେ ସଂଗଠନ ର୍ୟାଙ୍କ ସ୍ଥିର ହୋଇଛି ..";
        this.ok = "ଠିକ୍ ଅଛି";
        this.congratulations = "ଅଭିନନ୍ଦନ";
        this.trailblazerCongratulations = "ଆପଣ ଟ୍ରେଲବ୍ଲେଜର ବ୍ୟାଜ୍ ଗ୍ରହଣ କରିଛନ୍ତି |";
        this.laureateCongratulations = "ବାଃ! ଆପଣ ବିଜେତା ବ୍ୟାଜ୍ ଗ୍ରହଣ କରିଛନ୍ତି |";
        this.wordsmithCongratulations = "ଆପଣ ୱାର୍ଡସ୍ମିଟ୍ ବ୍ୟାଜ୍ ଗ୍ରହଣ କରିଛନ୍ତି |";
        this.architectCongratulations = "ଆପଣ ଆର୍କିଟେକ୍ଟ ବ୍ୟାଜ୍ ଗ୍ରହଣ କରିଛନ୍ତି |";
        this.chieftainCongratulations = "ତୁମେ ଚିଫ୍ ବ୍ୟାଜ୍ ଗ୍ରହଣ କରିଛ |";
        this.submitResponseNote = "* ରେକର୍ଡ ହେବା ପାଇଁ ଆପଣଙ୍କର ପ୍ରତିକ୍ରିୟା ପାଇଁ, ଦୟାକରି ଅନ୍ତିମ ପୃଷ୍ଠାରେ ଦାଖଲ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |"

        this.Anxious = "ଚିନ୍ତିତ";
        this.anxiousMoodLabel = "ତୁମେ କିପରି ଅନୁଭବ କରୁଛ ସେଥିରେ କ harm ଣସି କ୍ଷତି ନାହିଁ, ନିଜକୁ ଦୟା ସହିତ ବ୍ୟବହାର କର! ଆସନ୍ତାକାଲି ତୁମଠାରୁ ଶୁଣିବାକୁ ଅପେକ୍ଷା କରିଛି |";
        this.anxiousSnackBar = "ଆମେ ବୁ understand ୁ, ଆମେ ଆପଣଙ୍କ ପାଇଁ ଏଠାରେ ଅଛୁ |";

        this.Uneasy = "ଅସନ୍ତୁଷ୍ଟ";
        this.uneasyMoodLabel = "ଏହି ଦିନଗୁଡିକ ରହିବା ସମ୍ପୂର୍ଣ୍ଣ ସ୍ normal ାଭାବିକ, କିନ୍ତୁ ଜିନିଷଗୁଡିକ ପୂର୍ବପରି ସ୍ଥାନରେ ପଡ଼ିବ! ଆସନ୍ତାକାଲି ତୁମଠାରୁ ଶୁଣିବାକୁ ଅପେକ୍ଷା କରିଛି |";
        this.uneasySnackBar = "ଏହିପରି ଅନୁଭବ କରିବା ଠିକ୍ ଅଟେ | ଆମେ ଆଶା କରୁ ଏହା ଉନ୍ନତ ହେବ!";

        this.Okay = "ଠିକ ଅଛି";
        this.okayMoodLabel = "କିଛି ଦିନ କେବଳ ମେହେ | କିନ୍ତୁ ଅନ୍ୟମାନେ ଅତୁଳନୀୟ ହୋଇପାରନ୍ତି, ଆମେ ଆଶା କରୁଛୁ ତୁମର ପରବର୍ତ୍ତୀଗୁଡ଼ିକ ଅଧିକ ଅଛି! ଆସନ୍ତାକାଲି ତୁମଠାରୁ ଶୁଣିବାକୁ ଅପେକ୍ଷା କରିଛି |";
        this.okaySnackBar = "ତାହା ଭଲ, ଆମେ ଆଶା କରୁଛୁ ଏହା ଭଲ ହୋଇଯିବ!";

        this.Happy = "ଖୁସି";
        this.happyMoodLabel = "ଆସନ୍ତାକାଲି ଏକ ନୂତନ ପ୍ରଭାତ; ଏକ ନୂଆ ଦିନ ଏବଂ ଆମେ ଆଶା କରୁଛୁ ଆପଣ ଆହୁରି ଭଲ ଅନୁଭବ କରିବେ! ଆସନ୍ତାକାଲି ତୁମଠାରୁ ଶୁଣିବାକୁ ଅପେକ୍ଷା କରିଛି |";
        this.happySnackBar="ଆମେ ଏହା ଦେଖି ଖୁସି!";

        this.Excited = "ଉତ୍ସାହିତ |";
        this.excitedMoodLabel ="ତୁମେ ଆଶ୍ଚର୍ଯ୍ୟଜନକ ଅନୁଭବ କର, ଏହାକୁ ଆଲିଙ୍ଗନ କର ଏବଂ ସମାନ ଆତ୍ମା ସହିତ ଫେରି ଆସ! ଆସନ୍ତାକାଲି ତୁମଠାରୁ ଶୁଣିବାକୁ ଅପେକ୍ଷା କରିଛି |";
        this.excitedSnackBar = "ତାହା ବହୁତ ଭଲ, ଚାଲ ଏହାକୁ ରଖିବା!";

        this.heresYourMood = "ଏଠାରେ ତୁମର ଆଜିର ମନୋବଳ |";
        this.whatsYourMood = "ଆଜି ତୁମର ମନୋବଳ କ’ଣ?";
        this.submitResponseNote = "* ରେକର୍ଡ ହେବା ପାଇଁ ଆପଣଙ୍କର ପ୍ରତିକ୍ରିୟା ପାଇଁ, ଦୟାକରି ଅନ୍ତିମ ପୃଷ୍ଠାରେ ଦାଖଲ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |";
        this.questionsLeft = "ଟି ପ୍ରଶ୍ନ ବାକି ଅଛି";
        this.questionLeft = "ଟି ପ୍ରଶ୍ନ ବାକି ଅଛି";

        this.request = "ଦୟାକରି ନିଆଯାଇଥିବା କାର୍ଯ୍ୟକୁ ମୂଲ୍ୟାଙ୍କନ କରନ୍ତୁ"
        this.thankYouComment = "ଆମର କାର୍ଯ୍ୟ ଉପରେ ଆପଣଙ୍କର ସଚ୍ଚୋଟ ମତାମତ ଦେଇଥିବାରୁ ଆପଣଙ୍କୁ ବହୁତ ଧନ୍ୟବାଦ | ଅଧିକ ଦେବା ପାଇଁ (+) ଆଇକନ୍ କ୍ଲିକ୍ କରନ୍ତୁ..."
        this.actionOnFeedbackCommentPlaceholder = "ଆପଣ ଏହି କାର୍ଯ୍ୟ ବିଷୟରେ କ'ଣ ଭାବନ୍ତି?";

        this.sixthOptionText = "ରେଟ୍ କରିବାକୁ ପର୍ଯ୍ୟାପ୍ତ ସୂଚନା ନୁହେଁ |";

        this.ICICISurveyCompletedAlready = "କ pending ଣସି ବିଚାରାଧୀନ ବିବୃତ୍ତିର ଉତ୍ତର ଦେବାକୁ ହେବ ନାହିଁ |";
        break;

      // Malayalam - ml
      case "ml":
        this.welcomeText = "സ്വാഗതം";
        this.startSurveyText = "ഇപ്പോൾ സർവേ ആരംഭിക്കുക";
        this.surveyCompletedAlready =
          "നിങ്ങൾ ഇതിനകം തന്നെ നിങ്ങളുടെ സർവേ പൂർത്തിയാക്കി";
        this.somethingWentWrong = "എന്തോ കുഴപ്പം സംഭവിച്ചു, വീണ്ടും ശ്രമിക്കുക";
        this.nextButton = "അടുത്തത്";
        this.previousButton = "മുമ്പത്തെ";
        this.submitButton = "സമർപ്പിക്കുക";
        this.submittingButton = "സമർപ്പിക്കുന്നു";
        this.logOut = "പുറത്തുകടക്കുക";
        this.selectLanguage = "ഭാഷ തിരഞ്ഞെടുക്കുക";
        this.tryAgainButton = "വീണ്ടും ശ്രമിക്ക്";
        this.writeHerePlaceHolder = "അഭിപ്രായം രേഖപ്പെടുത്തുക";
        this.pleaseWaitWhileFetch =
          "നിങ്ങളുടെ സർവ്വേ തേടുമ്പോൾ ദയവായി കാത്തിരിക്കുക";
        this.thankYouText = "ഈ സർവേയിൽ നിങ്ങളുടെ പങ്കാളിത്തത്തിന് നന്ദി";
        this.termsConditions = "വ്യവസ്ഥകളും ഉപാധികളും";
        this.privacyPolicy = "സ്വകാര്യതാനയം";
        this.brandGuidelines = "ബ്രാൻഡ് മാർഗ്ഗനിർദ്ദേശങ്ങൾ";
        this.contactNumber = "കോൺടാക്റ്റ് നമ്പർ";
        this.emailText = "ഇമെയിൽ";
        this.aboutUsText = "ഞങ്ങളേക്കുറിച്ച്";
        this.changePassword = "പാസ്വേഡ് മാറ്റുക";
        this.Badge = "ബാഡ്ജ്";
        this.EngagementTrends = "ഇടപഴകൽ ട്രെൻഡുകൾ";
        this.Perceptions = "ധാരണകൾ";
        this.InvalidInputError = "HTML & സ്ക്രിപ്റ്റ് ടാഗുകൾ അനുവദനീയമല്ല (<>)";
        this.openEndedPollPlaceholder = "നിങ്ങളുടെ ഫീഡ്‌ബാക്ക് നൽകുക";
        this.floatingCommentLable = "കൂടുതൽ വിശദീകരിക്കുക ...";
        this.actionPointDialogHeader="നിങ്ങളുടെ സത്യസന്ധമായ ഫീഡ്‌ബാക്കിന് നന്ദി. മാനേജ്മെന്റ് ഇനിപ്പറയുന്ന നടപടികളുടെ അടിസ്ഥാന ഫീഡ്‌ബാക്ക് സ്വീകരിച്ചു ";
        this.badgeTitle = "നിങ്ങളുടെ ബാഡ്ജുകൾ";
        this.orgRank = "ഓർഗനൈസേഷൻ റാങ്ക്";
        this.teamRank = "ടീം റാങ്ക്";
        this.totalPoints = "ആകെ പോയിന്റുകൾ";

        this.badgeTitle = "നിങ്ങളുടെ ബാഡ്ജുകൾ";
        this.orgRank = "ഓർഗനൈസേഷൻ റാങ്ക്";
        this.teamRank = "ടീം റാങ്ക്";
        this.totalPoints = "ആകെ പോയിന്റുകൾ";
        this.Youwon = "നിങ്ങൾ വിജയിച്ചു";
        this.badges = "ബാഡ്ജ്";
        this.badgeSingular = "ബാഡ്ജ്";
        this.PointsSystem = "പോയിന്റ് സിസ്റ്റം";
        this.Points = "പോയിന്റുകൾ";
        this.Calculation = "കണക്കുകൂട്ടല്";
        this.BadgeName = "ബാഡ്ജ് നാമം";
        this.Description = "വിവരണം";
        this.Howarepointscalculated = "പോയിന്റുകൾ എങ്ങനെയാണ് കണക്കാക്കുന്നത്?";
        this.Howteamrank = "നിങ്ങളുടെ ടീം റാങ്ക് ഞങ്ങൾ എങ്ങനെ തീരുമാനിക്കും?";
        this.HowOrgrank = "നിങ്ങളുടെ ഓർഗനൈസേഷൻ റാങ്ക് ഞങ്ങൾ എങ്ങനെ തീരുമാനിക്കും?";
        this.Trailblazer = "ട്രയൽബ്ലേസർ";
        this.Architect = "വാസ്തുശില്പി";
        this.Chieftain = "തലവൻ";
        this.Engineer = "എഞ്ചിനീയർ";
        this.Laureate = "പുരസ്കാര ജേതാവ്";
        this.Wordsmith = "വേഡ്സ്മിത്ത്";
        this.trailblazerContent = "ഒരു സാംസ്കാരിക പയനിയർ ആകുന്നതിനുള്ള ആദ്യപടി സ്വീകരിച്ചതിന് ഈ ബാഡ്ജ് നിങ്ങളുടേതാണ്. അപ്ലിക്കേഷനിൽ ലോഗിൻ ചെയ്യുന്നതിന് നിങ്ങൾ ഒരു പോയിന്റ് നേടി.";
        this.architectContent = "ഈ ബാഡ്ജ് ലഭിക്കുന്നതിന് നിരന്തരത കൈവരുത്തി ആഴ്ചയുടെ എല്ലാ ചോദ്യങ്ങള്‍ക്കും മറുപടി നല്‍കുക. നിങ്ങളുടെ സ്ഥിരത നിലനിർത്തുക आणि നിങ്ങളുടെ സമര്‍പ്പണം പ്രദര്‍ശിപ്പിക്കുക!";
        this.chieftainContent = "ചീഫ്‌ടെയിൻ ബാഡ്ജ് ഉപയോഗിച്ച് ഏറ്റവും ഉയർന്ന ആദരം നേടുക! സജീവ പങ്കാളിത്തത്തിനും ആഴ്ചവരെയുള്ള എല്ലാ ചോദ്യങ്ങളിലും കമന്റ് ചെയ്യുന്നതിനും 30 പോയിന്റുകൾ നേടുക. നിങ്ങളുടെ ഏർപ്പെടുത്തൽ നിങ്ങളെ വ്യത്യസ്തമായി കാണിക്കുന്നു!";        
        this.engineerContent = "നിങ്ങളുടെ പ്രതിവാര സ്‌ട്രീക്ക് ഒരു മാസത്തിൽ 3 തവണയെങ്കിലും ക്ലെയിം ചെയ്തുകൊണ്ട് ഈ ബാഡ്ജ് ഉപയോഗിച്ച് 10 പോയിന്റുകൾ നേടുക";
        this.laureateContent = "സ്ഥലത്ത് ഒരു സമ്മാന ജേതാവ് വിശദമായ അഭിപ്രായമോ ഫീഡ്‌ബാക്കോ ഉപേക്ഷിക്കുന്നുണ്ടോ? ഈ ബാഡ്ജ് ഉപയോഗിച്ച് തൽക്ഷണം 15 പോയിന്റുകൾ നേടുക ..";
        this.wordsmithContent = "ഒരു ഹ്രസ്വ അഭിപ്രായം രേഖപ്പെടുത്തുന്നതിന് ഈ ബാഡ്ജ് നിങ്ങൾക്ക് 3 പോയിന്റുകൾ നേടുന്നു.";
        this.pointDescription1 = "ഓരോ ബാഡ്ജിനും ഇതുമായി ബന്ധപ്പെട്ട പോയിന്റുകൾ ഉണ്ട്. നിങ്ങൾക്ക് ഒരു ബാഡ്ജ് ലഭിക്കുമ്പോൾ, നിങ്ങളുടെ നിലവിലുള്ള പോയിന്റുകളിലേക്ക് ചേർക്കുന്നത് തുടരുന്ന അനുബന്ധ പോയിന്റുകളും നിങ്ങൾക്ക് ലഭിക്കും. പോയിന്റുകൾ മാസം തിരിച്ചാണ് കണക്കാക്കുന്നത്, ഓരോ മാസത്തിൻറെയും ആരംഭത്തിൽ സ്കോർ 0 ആയി പുന reset സജ്ജമാക്കും";
        this.pointDescription2 = "നിങ്ങളുടെ ടീമിലെ മറ്റുള്ളവരുടെ സ്കോറുകളുമായി താരതമ്യപ്പെടുത്തുമ്പോൾ നിങ്ങളുടെ പോയിന്റുകളുടെ അടിസ്ഥാനത്തിലാണ് ടീം റാങ്ക് തീരുമാനിക്കുന്നത്. ടീമുകളെ നിർവചിക്കുന്നതിന് നിങ്ങളുടെ ഓർഗനൈസേഷൻ തിരഞ്ഞെടുത്ത ഡെമോഗ്രാഫി അനുസരിച്ച് ടീം നിർവചിക്കപ്പെടുന്നു";
        this.pointDescription3 = "നിങ്ങളുടെ ഓർഗനൈസേഷനിലെ മറ്റെല്ലാവരുടേയും പോയിന്റുകളുമായി താരതമ്യപ്പെടുത്തുമ്പോൾ നിങ്ങളുടെ പോയിന്റുകളുടെ അടിസ്ഥാനത്തിലാണ് ഓർ‌ഗനൈസേഷൻ റാങ്ക് തീരുമാനിക്കുന്നത് ..";
        this.ok = "ശരി";
        this.congratulations = "അഭിനന്ദനങ്ങൾ";
        this.trailblazerCongratulations = "നിങ്ങൾക്ക് ട്രെയ്‌ൽബ്ലേസർ ബാഡ്ജ് ലഭിച്ചു";
        this.laureateCongratulations = "യായ്! നിങ്ങൾക്ക് ലോറേറ്റ് ബാഡ്ജ് ലഭിച്ചു";
        this.wordsmithCongratulations = "നിങ്ങൾക്ക് വേഡ്സ്മിത്ത് ബാഡ്ജ് ലഭിച്ചു";
        this.architectCongratulations = "നിങ്ങൾക്ക് ആർക്കിടെക്ട് ബാഡ്ജ് ലഭിച്ചു";
        this.chieftainCongratulations = "നിങ്ങൾക്ക് ചീഫ്ടെയിൻ ബാഡ്ജ് ലഭിച്ചു";
        this.submitResponseNote = "* നിങ്ങളുടെ പ്രതികരണം റെക്കോർഡുചെയ്യുന്നതിന്, അവസാന പേജിലെ സമർപ്പിക്കുക ക്ലിക്കുചെയ്യുക"

        this.Anxious = "ഉത്കണ്ഠാജനകമായ";
        this.anxiousMoodLabel = "നിങ്ങൾക്ക് തോന്നുന്നതിൽ ഒരു ദോഷവുമില്ല, സ്വയം ദയയോടെ പെരുമാറുക! നാളെ നിങ്ങളിൽ നിന്ന് കേൾക്കാൻ കാത്തിരിക്കുന്നു";
        this.anxiousSnackBar = "ഞങ്ങൾ മനസ്സിലാക്കുന്നു, ഞങ്ങൾ നിങ്ങൾക്കായി ഇവിടെയുണ്ട്.";

        this.Uneasy = "അസന്തുഷ്ടൻ";
        this.uneasyMoodLabel = "ഈ ദിവസങ്ങളിൽ ഉണ്ടാകുന്നത് തികച്ചും സാധാരണമാണ്, പക്ഷേ അവ എല്ലായ്പ്പോഴും ഉള്ളതുപോലെ സംഭവിക്കും! നാളെ നിങ്ങളിൽ നിന്ന് കേൾക്കാൻ കാത്തിരിക്കുന്നു";
        this.uneasySnackBar = "ഇങ്ങനെ തോന്നിയാൽ കുഴപ്പമില്ല. അത് മെച്ചപ്പെടുമെന്ന് ഞങ്ങൾ പ്രതീക്ഷിക്കുന്നു!";

        this.Okay = "ശരി";
        this.okayMoodLabel = "ചില ദിവസങ്ങൾ വെറുതെയാണ്. എന്നാൽ മറ്റുള്ളവർ അസാധാരണമായിരിക്കാം, നിങ്ങൾക്ക് രണ്ടാമത്തേത് കൂടുതൽ ഉണ്ടെന്ന് ഞങ്ങൾ പ്രതീക്ഷിക്കുന്നു! നാളെ നിങ്ങളിൽ നിന്ന് കേൾക്കാൻ കാത്തിരിക്കുന്നു";
        this.okaySnackBar = "അത് നല്ലതാണ്, അത് മെച്ചപ്പെടുമെന്ന് ഞങ്ങൾ പ്രതീക്ഷിക്കുന്നു!";

        this.Happy = "സന്തോഷം";
        this.happyMoodLabel = "നാളെ ഒരു പുതിയ പ്രഭാതമാണ്; ഒരു പുതിയ ദിവസം, നിങ്ങൾക്ക് കൂടുതൽ സുഖം തോന്നുന്നുവെന്ന് ഞങ്ങൾ പ്രതീക്ഷിക്കുന്നു! നാളെ നിങ്ങളിൽ നിന്ന് കേൾക്കാൻ കാത്തിരിക്കുന്നു";
        this.happySnackBar="അത് കാണുന്നതിൽ ഞങ്ങൾക്ക് സന്തോഷമുണ്ട്!";

        this.Excited = "ആവേശം";
        this.excitedMoodLabel ="നിങ്ങൾക്ക് അതിശയം തോന്നുന്നു, അത് ഉൾക്കൊള്ളുകയും അതേ ആത്മാവുമായി തിരികെ വരിക! നാളെ നിങ്ങളിൽ നിന്ന് കേൾക്കാൻ കാത്തിരിക്കുന്നു";
        this.excitedSnackBar = "അത് വളരെ മികച്ചതാണ്, നമുക്ക് ഇത് നിലനിർത്താം!";

        this.heresYourMood = "നിങ്ങളുടെ ഇന്നത്തെ മാനസികാവസ്ഥ ഇതാ";
        this.whatsYourMood = "ഇന്നത്തെ നിങ്ങളുടെ മാനസികാവസ്ഥ എന്താണ്?";
        this.submitResponseNote = "* നിങ്ങളുടെ പ്രതികരണം റെക്കോർഡുചെയ്യുന്നതിന്, അവസാന പേജിലെ സമർപ്പിക്കുക ക്ലിക്കുചെയ്യുക";
        this.questionsLeft = "ചോദ്യങ്ങൾ അവശേഷിക്കുന്നു";
        this.questionLeft = "ചോദ്യങ്ങൾ അവശേഷിക്കുന്നു";

        this.request = "എടുത്ത നടപടി റേറ്റുചെയ്യുക"
        this.thankYouComment = "ഞങ്ങളുടെ പ്രവർത്തനങ്ങളെക്കുറിച്ച് നിങ്ങളുടെ സത്യസന്ധമായ അഭിപ്രായം നൽകിയതിന് വളരെ നന്ദി. കൂടുതൽ നൽകാൻ (+) ഐക്കൺ ക്ലിക്കുചെയ്യുക..."
        this.actionOnFeedbackCommentPlaceholder = "ഈ നടപടിയെക്കുറിച്ച് നിങ്ങൾ എന്താണ് കരുതുന്നത്?";

        this.sixthOptionText = "റേറ്റുചെയ്യാൻ മതിയായ വിവരങ്ങൾ ഇല്ല";

        this.ICICISurveyCompletedAlready = "തീർപ്പാക്കാത്ത പ്രസ്താവനകളൊന്നും ഉത്തരം നൽകേണ്ടതില്ല";
        break;

      //Punjabi - pa
      case "pa":
        this.welcomeText = "ਸੁਆਗਤ ਹੈ";
        this.startSurveyText = "ਸਰਵੇਖਣ ਸ਼ੁਰੂ ਕਰੋ";
        this.surveyCompletedAlready =
          "ਤੁਸੀਂ ਆਪਣਾ ਸਰਵੇਖਣ ਪਹਿਲਾਂ ਹੀ ਪੂਰਾ ਕਰ ਲਿਆ ਸੀ";
        this.somethingWentWrong =
          "ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ";
        this.nextButton = "ਅਗਲਾ";
        this.previousButton = "ਪਿਛਲਾ";
        this.submitButton = "ਜਮ੍ਹਾਂ ਕਰੋ";
        this.submittingButton = "ਭੇਜ ਰਿਹਾ ਹੈ";
        this.logOut = "ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ";
        this.selectLanguage = "ਭਾਸ਼ਾ ਚੁਣੋ";
        this.tryAgainButton = "ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ";
        this.writeHerePlaceHolder = "ਕਿਰਪਾ ਕਰਕੇ ਟਿੱਪਣੀ ਦਾਖਲ ਕਰੋ";
        this.pleaseWaitWhileFetch =
          "ਅਸੀਂ ਤੁਹਾਡੇ ਸਰਵੇਖਣ ਨੂੰ ਪ੍ਰਾਪਤ ਕਰਦੇ ਹੋਏ ਇੰਤਜ਼ਾਰ ਕਰੋ";
        this.thankYouText = "ਇਸ ਸਰਵੇਖਣ ਵਿੱਚ ਤੁਹਾਡੀ ਭਾਗੀਦਾਰੀ ਲਈ ਧੰਨਵਾਦ";
        this.termsConditions = "ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ";
        this.privacyPolicy = "ਪਰਾਈਵੇਟ ਨੀਤੀ";
        this.brandGuidelines = "ਬ੍ਰਾਂਡ ਦਿਸ਼ਾ ਨਿਰਦੇਸ਼";
        this.contactNumber = "ਸੰਪਰਕ ਨੰਬਰ";
        this.emailText = "ਈ - ਮੇਲ";
        this.aboutUsText = "ਸਾਡੇ ਬਾਰੇ";
        this.changePassword = "ਪਾਸਵਰਡ ਬਦਲੋ";
        this.Badge = "ਬੈਜ";
        this.EngagementTrends = "ਰੁਝੇਵੇਂ ਦੇ ਰੁਝਾਨ";
        this.Perceptions = "ਧਾਰਨਾ";
        this.InvalidInputError = "ਐਚਟੀਐਮਐਲ ਅਤੇ ਸਕ੍ਰਿਪਟ ਟੈਗ ਦੀ ਆਗਿਆ ਨਹੀਂ (<>)";
        this.openEndedPollPlaceholder = "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਫੀਡਬੈਕ ਦਿਓ";
        this.floatingCommentLable = "ਕਿਰਪਾ ਕਰਕੇ ਅੱਗੇ ਵਿਸਥਾਰ ਨਾਲ ਦੱਸੋ ...";
        this.actionPointDialogHeader="ਤੁਹਾਡੀ ਇਮਾਨਦਾਰ ਫੀਡਬੈਕ ਲਈ ਧੰਨਵਾਦ. ਪ੍ਰਬੰਧਨ ਨੇ ਹੇਠ ਲਿਖੀ ਕਾਰਵਾਈ ਕੀਤੀ ਹੈ";
        this.badgeTitle = "ਤੁਹਾਡੇ ਬੈਜ";
        this.orgRank = "ਸੰਗਠਨ ਦਰਜਾਬੰਦੀ";
        this.teamRank = "ਟੀਮ ਰੈਂਕ";
        this.totalPoints = "ਕੁੱਲ ਬਿੰਦੂ";

        this.badgeTitle = "ਤੁਹਾਡੇ ਬੈਜ";
        this.orgRank = "ਸੰਗਠਨ ਦਰਜਾਬੰਦੀ";
        this.teamRank = "ਟੀਮ ਰੈਂਕ";
        this.totalPoints = "ਕੁੱਲ ਬਿੰਦੂ";
        this.Youwon = "ਤੁਸੀ ਜਿੱਤੇ";
        this.badges = "ਬੈਜ";
        this.badgeSingular = "ਬੈਜ";
        this.PointsSystem = "ਪੁਆਇੰਟ ਸਿਸਟਮ";
        this.Points = "ਬਿੰਦੂ";
        this.Calculation = "ਗਣਨਾ";
        this.BadgeName = "ਬੈਜ ਦਾ ਨਾਮ";
        this.Description = "ਵੇਰਵਾ";
        this.Howarepointscalculated = "ਬਿੰਦੂਆਂ ਦੀ ਕਿਵੇਂ ਗਣਨਾ ਕੀਤੀ ਜਾਂਦੀ ਹੈ?";
        this.Howteamrank = "ਅਸੀਂ ਤੁਹਾਡੀ ਟੀਮ ਦਾ ਦਰਜਾ ਕਿਵੇਂ ਨਿਰਧਾਰਤ ਕਰਦੇ ਹਾਂ?";
        this.HowOrgrank = "ਅਸੀਂ ਤੁਹਾਡੇ ਸੰਗਠਨ ਦਾ ਦਰਜਾ ਕਿਵੇਂ ਨਿਰਧਾਰਤ ਕਰਦੇ ਹਾਂ?";
        this.Trailblazer = "ਟ੍ਰੇਲਬਲੇਜ਼ਰ";
        this.Architect = "ਆਰਕੀਟੈਕਟ";
        this.Chieftain = "ਸਰਦਾਰ";
        this.Engineer = "ਇੰਜੀਨੀਅਰ";
        this.Laureate = "ਜੇਤੂ";
        this.Wordsmith = "ਸ਼ਬਦ";
        this.trailblazerContent = "ਕਲਚਰ ਪਾਇਨੀਅਰ ਬਣਨ ਲਈ ਪਹਿਲਾ ਕਦਮ ਚੁੱਕਣ ਲਈ ਇਹ ਬੈਜ ਤੁਹਾਡਾ ਹੈ. ਤੁਸੀਂ ਐਪ ਵਿੱਚ ਲੌਗਇਨ ਕਰਨ ਲਈ 1 ਪੁਆਇੰਟ ਜਿੱਤੇ.";
        this.architectContent = "ਇਸ ਬੇਜ ਨੂੰ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਇੱਕ ਲਗਾਤਾਰਤਾ ਨੂੰ ਬਨਾਏ ਰੱਖੋ ਅਤੇ ਹਫਤੇ ਦੇ ਹਰ ਸਵਾਲ ਦੇ ਜਵਾਬ ਦੇ ਕੇ ਬਿਨਾਂ ਕਿਸੇ ਨੂੰ ਛੱਡੇ। ਆਪਣੀ ਲਗਾਤਾਰਤਾ ਬਨਾਏ ਰੱਖੋ ਅਤੇ ਆਪਣੀ ਪ੍ਰਤੀਬੱਧਤਾ ਨੂੰ ਦਰਸਾਓ!";
        this.chieftainContent = "ਚੀਫਟੇਨ ਬੇਜ ਨਾਲ ਸਭ ਤੋਂ ਉੱਚਾ ਸਨਮਾਨ ਪ੍ਰਾਪਤ ਕਰੋ! ਤੁਹਾਡੇ ਸਰਗਰਮ ਭਾਗੀਦਾਰੀ ਅਤੇ ਹਫਤੇ ਭਰ ਦੇ ਸਾਰੇ ਸਵਾਲਾਂ ਤੇ ਟਿੱਪਣੀ ਕਰਨ ਲਈ 30 ਅੰਕ ਪ੍ਰਾਪਤ ਕਰੋ। ਤੁਹਾਡੀ ਸ਼ਾਮਲਤਾ ਤੁਹਾਨੂੰ ਅਲੱਗ ਕਰਦੀ ਹੈ!";        
        this.engineerContent = "ਇਕ ਮਹੀਨੇ ਵਿਚ ਘੱਟੋ ਘੱਟ 3 ਵਾਰ ਆਪਣੀ ਹਫਤਾਵਾਰੀ ਲੜੀ ਦਾ ਦਾਅਵਾ ਕਰਕੇ ਇਸ ਬੈਜ ਨਾਲ 10 ਪੁਆਇੰਟ ਜਿੱਤੇ";
        this.laureateContent = "ਸਪਾਟ 'ਤੇ ਇਕ ਵਿਜੇਤਾ ਇਕ ਵਿਸਥਾਰ ਟਿੱਪਣੀ ਜਾਂ ਫੀਡਬੈਕ ਛੱਡ ਰਿਹਾ ਹੈ? ਇਸ ਬੈਜ ਨਾਲ 15 ਅੰਕ ਤੁਰੰਤ ਜਿੱਤੋ ..";
        this.wordsmithContent = "ਇਹ ਬੈਜ ਤੁਹਾਨੂੰ ਸੰਖੇਪ ਟਿੱਪਣੀ ਕਰਨ ਲਈ 3 ਅੰਕ ਜਿੱਤਦਾ ਹੈ.";
        this.pointDescription1 = "ਹਰ ਬੈਜ ਦੇ ਇਸਦੇ ਨਾਲ ਜੁੜੇ ਬਿੰਦੂ ਹੁੰਦੇ ਹਨ. ਜਿਵੇਂ ਕਿ ਤੁਹਾਨੂੰ ਇੱਕ ਬੈਜ ਪ੍ਰਾਪਤ ਹੁੰਦਾ ਹੈ, ਤੁਹਾਨੂੰ ਸੰਬੰਧਿਤ ਬਿੰਦੂ ਵੀ ਮਿਲਦੇ ਹਨ ਜੋ ਤੁਹਾਡੇ ਮੌਜੂਦਾ ਬਿੰਦੂਆਂ ਵਿੱਚ ਜੋੜਦੇ ਰਹਿੰਦੇ ਹਨ. ਪੁਆਇੰਟਸ ਮਹੀਨੇ ਦੇ ਹਿਸਾਬ ਨਾਲ ਗਿਣਿਆ ਜਾਂਦਾ ਹੈ ਅਤੇ ਹਰ ਮਹੀਨੇ ਦੀ ਸ਼ੁਰੂਆਤ 'ਤੇ ਸਕੋਰ 0' ਤੇ ਰੀਸੈਟ ਹੋ ਜਾਂਦਾ ਹੈ";
        this.pointDescription2 = "ਤੁਹਾਡੀ ਟੀਮ ਦੇ ਦੂਸਰੇ ਸਕੋਰਾਂ ਦੇ ਮੁਕਾਬਲੇ ਟੀਮ ਰੈਂਕ ਤੁਹਾਡੇ ਬਿੰਦੂਆਂ ਦੇ ਅਧਾਰ ਤੇ ਨਿਰਧਾਰਤ ਕੀਤੀ ਜਾਂਦੀ ਹੈ. ਟੀਮ ਨੂੰ ਡੈਮੋਗ੍ਰਾਫੀ ਦੇ ਅਨੁਸਾਰ ਪਰਿਭਾਸ਼ਤ ਕੀਤਾ ਜਾਂਦਾ ਹੈ ਜੋ ਤੁਹਾਡੀ ਸੰਸਥਾ ਦੁਆਰਾ ਟੀਮਾਂ ਨੂੰ ਪ੍ਰਭਾਸ਼ਿਤ ਕਰਨ ਲਈ ਚੁੱਕਿਆ ਜਾਂਦਾ ਹੈ";
        this.pointDescription3 = "ਸੰਗਠਨ ਦਾ ਦਰਜਾ ਤੁਹਾਡੇ ਸੰਸਥਾ ਦੇ ਹਰ ਕਿਸੇ ਦੇ ਅੰਕ ਦੇ ਮੁਕਾਬਲੇ ਤੁਹਾਡੇ ਪੁਆਇੰਟਸ ਦੇ ਅਧਾਰ ਤੇ ਨਿਰਧਾਰਤ ਕੀਤਾ ਜਾਂਦਾ ਹੈ ..";
        this.ok = "ਠੀਕ ਹੈ";
        this.congratulations = "ਵਧਾਈਆਂ";
        this.trailblazerCongratulations = "ਤੁਸੀਂ ਟ੍ਰੇਲਬਲੇਜ਼ਰ ਬੈਜ ਪ੍ਰਾਪਤ ਕੀਤਾ ਹੈ";
        this.laureateCongratulations = "ਹਾਂ! ਤੁਸੀਂ ਲੌਰੀਏਟ ਬੈਜ ਪ੍ਰਾਪਤ ਕੀਤਾ ਹੈ";
        this.wordsmithCongratulations = "ਤੁਹਾਨੂੰ ਵਰਡਸਮਿੱਥ ਬੈਜ ਪ੍ਰਾਪਤ ਹੋਇਆ ਹੈ";
        this.architectCongratulations = "ਤੁਸੀਂ ਆਰਕੀਟੈਕਟ ਬੈਜ ਪ੍ਰਾਪਤ ਕੀਤਾ ਹੈ";
        this.chieftainCongratulations = "ਤੁਹਾਨੂੰ ਸਰਦਾਰ ਬੈਜ ਪ੍ਰਾਪਤ ਹੋਇਆ ਹੈ";
        this.submitResponseNote = "* ਤੁਹਾਡੇ ਜਵਾਬ ਨੂੰ ਦਰਜ ਕਰਨ ਲਈ, ਕਿਰਪਾ ਕਰਕੇ ਅੰਤਮ ਪੇਜ ਤੇ ਦਰਜ ਕਰੋ ਤੇ ਕਲਿਕ ਕਰੋ"


        this.Anxious = "ਚਿੰਤਤ";
        this.anxiousMoodLabel = "ਇਸ ਵਿੱਚ ਕੋਈ ਨੁਕਸਾਨ ਨਹੀਂ ਹੈ ਕਿ ਤੁਸੀਂ ਕਿਵੇਂ ਮਹਿਸੂਸ ਕਰ ਰਹੇ ਹੋ, ਆਪਣੇ ਆਪ ਨਾਲ ਦਿਆਲਤਾ ਨਾਲ ਪੇਸ਼ ਆਓ! ਕੱਲ ਤੁਹਾਡੇ ਤੋਂ ਸੁਣਵਾਈ ਦੀ ਉਡੀਕ ਕਰ ਰਿਹਾ ਹਾਂ";
        this.anxiousSnackBar = "ਅਸੀਂ ਸਮਝਦੇ ਹਾਂ, ਅਸੀਂ ਤੁਹਾਡੇ ਲਈ ਇੱਥੇ ਹਾਂ.";

        this.Uneasy = "ਨਾਖੁਸ਼";
        this.uneasyMoodLabel = "ਇਨ੍ਹਾਂ ਦਿਨਾਂ ਦਾ ਹੋਣਾ ਬਿਲਕੁਲ ਆਮ ਗੱਲ ਹੈ, ਪਰ ਚੀਜ਼ਾਂ ਅਜਿਹੀਆਂ ਹੋਣਗੀਆਂ ਜਿਵੇਂ ਉਨ੍ਹਾਂ ਕੋਲ ਹਮੇਸ਼ਾਂ ਹੁੰਦੀਆਂ ਹਨ! ਕੱਲ ਤੁਹਾਡੇ ਤੋਂ ਸੁਣਵਾਈ ਦੀ ਉਡੀਕ ਕਰ ਰਿਹਾ ਹਾਂ";
        this.uneasySnackBar = "ਇਸ ਤਰ੍ਹਾਂ ਮਹਿਸੂਸ ਕਰਨਾ ਠੀਕ ਹੈ. ਸਾਨੂੰ ਉਮੀਦ ਹੈ ਕਿ ਇਹ ਸੁਧਰੇਗਾ!";

        this.Okay = "ਠੀਕ ਹੈ";
        this.okayMoodLabel = "ਕੁਝ ਦਿਨ ਸਿਰਫ ਮਹਿ ਹਨ. ਪਰ ਦੂਸਰੇ ਬੇਮਿਸਾਲ ਹੋ ਸਕਦੇ ਹਨ, ਅਸੀਂ ਉਮੀਦ ਕਰਦੇ ਹਾਂ ਕਿ ਤੁਹਾਡੇ ਕੋਲ ਬਾਅਦ ਦੇ ਹੋਰ ਵੀ ਹੋਣਗੇ! ਕੱਲ ਤੁਹਾਡੇ ਤੋਂ ਸੁਣਵਾਈ ਦੀ ਉਡੀਕ ਕਰ ਰਿਹਾ ਹਾਂ";
        this.okaySnackBar = "ਇਹ ਠੀਕ ਹੈ, ਸਾਨੂੰ ਉਮੀਦ ਹੈ ਕਿ ਇਹ ਬਿਹਤਰ ਹੋ ਜਾਵੇਗਾ!";

        this.Happy = "ਖੁਸ਼";
        this.happyMoodLabel = "ਕੱਲ੍ਹ ਇੱਕ ਨਵੀਂ ਸਵੇਰ ਹੈ; ਇੱਕ ਨਵਾਂ ਦਿਨ ਅਤੇ ਅਸੀਂ ਉਮੀਦ ਕਰਦੇ ਹਾਂ ਕਿ ਤੁਸੀਂ ਹੋਰ ਵੀ ਬਿਹਤਰ ਮਹਿਸੂਸ ਕਰੋਗੇ! ਕੱਲ ਤੁਹਾਡੇ ਤੋਂ ਸੁਣਵਾਈ ਦੀ ਉਡੀਕ ਕਰ ਰਿਹਾ ਹਾਂ";
        this.happySnackBar="ਅਸੀਂ ਇਹ ਵੇਖ ਕੇ ਖੁਸ਼ ਹਾਂ!";

        this.Excited = "ਉਤਸ਼ਾਹਿਤ";
        this.excitedMoodLabel ="ਤੁਸੀਂ ਹੈਰਾਨੀਜਨਕ ਮਹਿਸੂਸ ਕਰਦੇ ਹੋ, ਇਸ ਨੂੰ ਗਲੇ ਲਗਾਓ ਅਤੇ ਉਸੇ ਭਾਵਨਾ ਨਾਲ ਵਾਪਸ ਆਓ! ਕੱਲ ਤੁਹਾਡੇ ਤੋਂ ਸੁਣਵਾਈ ਦੀ ਉਡੀਕ ਕਰ ਰਿਹਾ ਹਾਂ";
        this.excitedSnackBar = "ਇਹ ਬਹੁਤ ਵਧੀਆ ਹੈ, ਆਓ ਇਸ ਨੂੰ ਜਾਰੀ ਰੱਖੀਏ!";

        this.heresYourMood = "ਇਹ ਹੈ ਤੁਹਾਡਾ ਅੱਜ ਦਾ ਮੂਡ";
        this.whatsYourMood = "ਇਹ ਹੈ ਤੁਹਾਡਾ ਅੱਜ ਦਾ ਮੂਡ";
        this.submitResponseNote = "* ਤੁਹਾਡੇ ਜਵਾਬ ਨੂੰ ਦਰਜ ਕਰਨ ਲਈ, ਕਿਰਪਾ ਕਰਕੇ ਅੰਤਮ ਪੇਜ ਤੇ ਦਰਜ ਕਰੋ ਤੇ ਕਲਿਕ ਕਰੋ";
        this.questionsLeft = "ਪ੍ਰਸ਼ਨ ਬਾਕੀ ਹਨ";
        this.questionLeft = "ਪ੍ਰਸ਼ਨ ਬਾਕੀ ਹਨ";

        this.request = "ਕਿਰਪਾ ਕਰਕੇ ਕੀਤੀ ਕਾਰਵਾਈ ਨੂੰ ਦਰਜਾ ਦਿਓ"
        this.thankYouComment = "ਸਾਡੇ ਕੰਮਾਂ ਬਾਰੇ ਆਪਣੀ ਇਮਾਨਦਾਰ ਰਾਏ ਦੇਣ ਲਈ ਤੁਹਾਡਾ ਬਹੁਤ ਬਹੁਤ ਧੰਨਵਾਦ। ਹੋਰ ਦੇਣ ਲਈ (+) ਆਈਕਾਨ 'ਤੇ ਕਲਿੱਕ ਕਰੋ।"
        this.actionOnFeedbackCommentPlaceholder = "ਤੁਸੀਂ ਇਸ ਕਾਰਵਾਈ ਬਾਰੇ ਕੀ ਸੋਚਦੇ ਹੋ?";

        this.sixthOptionText = "ਰੇਟ ਕਰਨ ਲਈ ਲੋੜੀਂਦੀ ਜਾਣਕਾਰੀ ਨਹੀਂ ਹੈ";

        this.ICICISurveyCompletedAlready = "ਜਵਾਬ ਦੇਣ ਲਈ ਕੋਈ ਬਕਾਇਆ ਬਿਆਨ ਨਹੀਂ";
        break;

      //Urdu - ur
      case "ur":
        this.welcomeText = "خوش آمدید";
        this.startSurveyText = "اب سروے شروع کرو";
        this.surveyCompletedAlready = "آپ نے پہلے ہی آپ کا سروے مکمل کیا تھا";
        this.somethingWentWrong = "کچھ غلط ہوا، دوبارہ کوشش کریں";
        this.nextButton = "اگلے";
        this.previousButton = "پچھلا";
        this.submitButton = "جمع";
        this.submittingButton = "جمع کر رہا ہے";
        this.logOut = "لاگ آوٹ";
        this.selectLanguage = "زبان منتخب کریں";
        this.tryAgainButton = "دوبارہ کوشش کریں";
        this.writeHerePlaceHolder = "براہ کرم تبصرہ درج کریں";
        this.pleaseWaitWhileFetch =
          "براہ مہربانی انتظار کریں جب ہم آپ کے سروے حاصل کریں";
        this.thankYouText = "اس سروے میں آپ کی شرکت کے لئے شکریہ";
        this.termsConditions = "شرائط و ضوابط";
        this.privacyPolicy = "پرائیویسی پالیسی";
        this.brandGuidelines = "برانڈ ہدایات";
        this.contactNumber = "رابطہ نمبر";
        this.emailText = "ای میل";
        this.aboutUsText = "ہمارے بارے میں";
        this.changePassword = "پاس ورڈ تبدیل کریں";
        this.Badge = "بیج";
        this.floatingCommentLable = "براہ کرم مزید تفصیل دیں ...";
        this.actionPointDialogHeader = "آپ کی راے کا شکریہ. انتظامیہ نے اس کے خلاف تمام آراء کی بنیاد پر مندرجہ ذیل کارروائی پوائنٹس اٹھائے ہیں۔";
        this.badgeTitle ="آپ کے بیج";
        this.orgRank = "تنظیم کا درجہ ";
        this.teamRank = "ٹیم رینک";
        this.totalPoints = "کل پوائنٹس";

        this.badgeTitle = "آپ کے بیج";
        this.orgRank = "تنظیم کا درجہ";
        this.teamRank = "ٹیم رینک";
        this.totalPoints = "کل پوائنٹس";
        this.Youwon = "آپ جیت گئے";
        this.badges = "بیج";
        this.badgeSingular = "بیج";
        this.PointsSystem = "پوائنٹ سسٹم";
        this.Points = "پوائنٹس";
        this.Calculation = "حساب کتاب";
        this.BadgeName = "بیج کا نام";
        this.Description = "تفصیل";
        this.Howarepointscalculated = "پوائنٹس کا حساب کس طرح لیا جاتا ہے؟";
        this.Howteamrank = "ہم آپ کی ٹیم کے عہدے کا فیصلہ کیسے کریں گے؟";
        this.HowOrgrank = "ہم آپ کے تنظیم کے عہدے کا فیصلہ کیسے کریں گے؟";
        this.Trailblazer = "ٹریلبلزر";
        this.Architect = "معمار";
        this.Chieftain = "سردار";
        this.Engineer = "انجینئر";
        this.Laureate = "انعام یافتہ";
        this.Wordsmith = "ورڈسمتھ";
        this.trailblazerContent = "ثقافت پاینیر بننے کے لئے پہلا قدم اٹھانے کے لئے یہ بیج آپ کا ہے۔ آپ ایپ میں لاگ ان کرنے کے لئے 1 پوائنٹ جیت گئے ہیں۔";
        this.architectContent = "اس بیچ کو جیتنے کے ل your ، اپنا سلسلہ جاری رکھیں اور سروے کا جواب 5 دن سیدھے رکھیں۔ ایک معمار کا بیج 5 پوائنٹس کے ساتھ آتا ہے!";
        this.chieftainContent = "اعلی اعزاز ، یہ بیج آپ کو فعال شریک ہونے اور مسلسل 5 دن تک اپنے تبصروں کو چھوڑنے کیلئے 30 پوائنٹس جیتتا ہے۔";
        this.engineerContent = "ایک ماہ میں کم سے کم 3 مرتبہ ہفتہ وار لکیر کا دعویٰ کرتے ہوئے اس بیج کے ساتھ 10 پوائنٹس جیتیں";
        this.laureateContent = "اسپاٹ ایوارڈ پر تفصیلی تبصرہ یا آراء چھوڑ رہے ہو؟ اس بیج سے فوری طور پر 15 پوائنٹس جیتیں ..";
        this.wordsmithContent = "ایک مختصر تبصرہ کرنے کے لئے یہ بیج آپ کو 3 پوائنٹس جیتتا ہے۔";
        this.pointDescription1 = "ہر بیج کے ساتھ اس سے وابستہ پوائنٹس ہوتے ہیں۔ جیسے ہی آپ کو بیج موصول ہوتا ہے ، آپ کو وابستہ پوائنٹس بھی مل جاتے ہیں جو آپ کے موجودہ نکات میں شامل ہوتے رہتے ہیں۔ پوائنٹس کا حساب ماہ کے حساب سے کیا جاتا ہے اور ہر مہینے کے آغاز پر اسکور 0 پر دوبارہ ترتیب پاتا ہے";
        this.pointDescription2 = "آپ کی ٹیم کے دوسروں کے اسکور کے مقابلہ میں ٹیم رینک کا آپ کے پوائنٹس کی بنیاد پر فیصلہ کیا جاتا ہے۔ ٹیم کی تعریف ڈیموگرافی کے مطابق کی گئی ہے جو آپ کی تنظیم نے ٹیموں کی تعریف کے ل picked منتخب کی ہے";
        this.pointDescription3 = "تنظیم کے درجے کا فیصلہ آپ کے پوائنٹس کی بنیاد پر کیا جاتا ہے جیسا کہ آپ کی تنظیم میں موجود ہر کسی کے پوائنٹس کے مقابلے میں ..";
        this.ok = "ٹھیک ہے";
        this.congratulations = "مبارک ہو";
        this.trailblazerCongratulations = "آپ نے ٹریل بلزر بیج حاصل کیا ہے";
 this.laureateCongratulations = "ہاں! آپ کو جیت کا بیج ملا ہے";
        this.wordsmithCongratulations = "آپ کو ورڈسمتھ بیج ملا ہے";
        this.architectCongratulations = "آپ کو آرکٹیکٹ بیج مل گیا ہے";
        this.chieftainCongratulations = "آپ کو سردار بیج مل گیا ہے";
        this.submitResponseNote = "* آپ کے جواب کو ریکارڈ کرنے کے ل please ، براہ کرم آخری صفحے پر جمع کریں پر کلک کریں"


        this.Anxious = "فکر مند";
        this.anxiousMoodLabel = "آپ کیسا محسوس کر رہے ہیں اس میں کوئی حرج نہیں ہے ، اپنے ساتھ احسان سے پیش آئیں! کل آپ سے سننے کے منتظر ہیں۔";
        this.anxiousSnackBar = "ہم سمجھتے ہیں ، ہم یہاں آپ کے لیے ہیں۔";

        this.Uneasy = "ناخوش";
        this.uneasyMoodLabel = "ان دنوں کا ہونا مکمل طور پر معمول ہے ، لیکن چیزیں اپنی جگہ پر گر جائیں گی جیسا کہ ہمیشہ ہوتا ہے! کل آپ سے سننے کے منتظر ہیں۔";
        this.uneasySnackBar = "اس طرح محسوس کرنا ٹھیک ہے۔ ہمیں امید ہے کہ اس میں بہتری آئے گی!";

        this.Okay = "ٹھیک ہے";
        this.okayMoodLabel = "کچھ دن صرف مہینے ہیں۔ لیکن دوسرے غیر معمولی ہوسکتے ہیں ، ہم امید کرتے ہیں کہ آپ کے پاس بعد میں زیادہ ہوگا! کل آپ سے سننے کے منتظر ہیں۔";
        this.okaySnackBar = "یہ ٹھیک ہے ، ہمیں امید ہے کہ یہ بہتر ہو جائے گا!";

        this.Happy = "خوش۔";
        this.happyMoodLabel = "کل ایک نئی صبح ہے ایک نیا دن اور ہم امید کرتے ہیں کہ آپ اور بھی بہتر محسوس کریں گے! کل آپ سے سننے کے منتظر ہیں۔";
        this.happySnackBar="ہم یہ دیکھ کر خوش ہیں!";

        this.Excited = "بہت پرجوش";
        this.excitedMoodLabel ="آپ حیرت انگیز محسوس کرتے ہیں ، اسے گلے لگائیں اور اسی جذبے کے ساتھ واپس آئیں! کل آپ سے سننے کے منتظر ہیں۔";
        this.excitedSnackBar = "یہ بہت اچھا ہے ، آئیے اسے جاری رکھیں!";

        this.heresYourMood = "یہ ہے آپ کا آج کا مزاج۔";
        this.whatsYourMood = "آج آپ کا مزاج کیسا ہے؟";

        this.submitResponseNote = "* آپ کے جواب کو ریکارڈ کرنے کے ل please ، براہ کرم آخری صفحے پر جمع کریں پر کلک کریں";
        this.questionsLeft = "سوال باقی ہیں۔";
        this.questionLeft = "سوال باقی ہیں۔";

        this.request = "براہ کرم کی گئی کارروائی کی شرح کریں"
        this.thankYouComment = "ہمارے اقدامات پر اپنی ایماندارانہ رائے دینے کے لئے آپ کا بہت بہت شکریہ۔ مزید دینے کے لیے (+) شبیہ پر کلک کریں..."
        this.actionOnFeedbackCommentPlaceholder = "آپ اس عمل کے بارے میں کیا سوچتے ہیں؟";

        this.sixthOptionText = "درجہ بندی کے لیے کافی معلومات نہیں ہیں۔";

        this.ICICISurveyCompletedAlready = "جواب دینے کے لیے کوئی زیر التواء بیانات";
        break;

      //Spanish - es
      case "es":
        this.welcomeText = "Bienvenido";
        this.startSurveyText = "iniciar la encuesta ahora";
        this.surveyCompletedAlready = "Ya habías completado tu encuesta.";
        this.somethingWentWrong =
          "Algo salió mal. Por favor, vuelva a intentarlo";
        this.nextButton = "Siguiente";
        this.previousButton = "Anterior";
        this.submitButton = "Enviar";
        this.submittingButton = "Sumisión";
        this.logOut = "Cerrar sesión";
        this.selectLanguage = "Seleccione el idioma";
        this.tryAgainButton = "INTÉNTALO DE NUEVO";
        this.writeHerePlaceHolder = "Por favor ingrese un comentario";
        this.pleaseWaitWhileFetch =
          "Por favor espere mientras buscamos su encuesta.";
        this.thankYouText = "Gracias por su participación en esta encuesta";
        this.termsConditions = "Términos y condiciones";
        this.privacyPolicy = "Política de privacidad";
        this.brandGuidelines = "Directrices de la marca";
        this.contactNumber = "Número de contacto";
        this.emailText = "Email";
        this.aboutUsText = "Sobre nosotros";
        this.changePassword = "Cambia la contraseña";
        this.Badge = "Insignia";
        this.floatingCommentLable = "por favor, elabore más ...";
        this.actionPointDialogHeader = "Gracias por tus comentarios. La gerencia ha tomado los siguientes puntos de acción basados ​​en todos los comentarios en su contra.";
        this.badgeTitle = "Tus insignias";
        this.orgRank = "Rango de organización";
        this.teamRank = "Rango de equipo";
        this.totalPoints = "Puntos totales";

        this.badgeTitle = "Tus insignias";
        this.orgRank = "Rango de organización";
        this.teamRank = "Rango de equipo";
        this.totalPoints = "Puntos totales";
        this.Youwon = "Ganaste";
        this.badges = "Insignia";
        this.badgeSingular = "Insignia";
        this.PointsSystem = "Sistema de puntos";
        this.Points = "Puntos";
        this.Calculation = "Cálculo";
        this.BadgeName = "Placa de identificación";
        this.Description = "Descripción";
        this.Howarepointscalculated = "¿Cómo se calculan los puntos?";
        this.Howteamrank = "¿Cómo decidimos el rango de su equipo?";
        this.HowOrgrank = "¿Cómo decidimos el rango de su organización?";
        this.Trailblazer = "Pionera";
        this.Architect = "Arquitecta";
        this.Chieftain = "Jefa";
        this.Engineer = "Ingeniera";
        this.Laureate = "Laureada";
        this.Wordsmith = "Wordsmith";
        this.trailblazerContent = "Esta insignia es suya por dar el primer paso para convertirse en un pionero de la cultura. Ganas 1 punto por iniciar sesión en la aplicación.";
        this.architectContent = "Para ganar este lote, mantenga su racha y responda la encuesta durante 5 días seguidos. ¡Una insignia de arquitecto viene con 5 puntos!";
        this.chieftainContent = "El mayor honor, esta insignia le otorga 30 puntos por ser un participante activo y dejar caer sus comentarios durante 5 días seguidos.";
        this.engineerContent = "Gana 10 puntos con esta insignia al reclamar tu racha semanal al menos 3 veces en un mes";
        this.laureateContent = "On The Spot Un Laureado ¿Dejando un comentario o retroalimentación detallada? Gana 15 puntos al instante con esta insignia.";
        this.wordsmithContent = "Esta insignia le otorga 3 puntos por dejar un breve comentario.";
        this.pointDescription1 = "Cada insignia tiene puntos asociados. A medida que recibe una insignia, también obtiene los puntos asociados que se van sumando a sus puntos existentes. Los puntos se calculan mensualmente y al comienzo de cada mes la puntuación se restablece a 0";
        this.pointDescription2 = "El rango del equipo se decide en base a sus puntos en comparación con los puntajes de otros en su equipo. El equipo se define de acuerdo con la demografía que elige su organización para definir los equipos.";
        this.pointDescription3 = "El rango de la organización se decide en base a sus puntos en comparación con los puntos de todos los demás en su organización.";
        this.ok = "OK";
        this.congratulations = "Felicidades";
        this.trailblazerCongratulations = "Has recibido la insignia de Trailblazer";
 this.laureateCongratulations = "¡Hurra! Has recibido la insignia de Laureate";
        this.wordsmithCongratulations = "Has recibido la insignia de Wordsmith";
        this.architectCongratulations = "Has recibido la insignia de arquitecto";
        this.chieftainCongratulations = "Has recibido la insignia de cacique";
        this.submitResponseNote = "* Para que se registre su respuesta, haga clic en Enviar en la página final"

        this.Anxious = "Ansiosa";
        this.anxiousMoodLabel = "No hay nada de malo en cómo se siente, ¡trátese con amabilidad! esperando tener noticias tuyas mañana";
        this.anxiousSnackBar = "Entendemos, estamos aquí para ti.";

        this.Uneasy = "Infeliz";
        this.uneasyMoodLabel = "Es completamente normal tener estos días, ¡pero las cosas se pondrán en su lugar como siempre lo han hecho! esperando tener noticias tuyas mañana";
        this.uneasySnackBar = "Está bien sentirse así. ¡Esperamos que mejore!";

        this.Okay = "Okey";
        this.okayMoodLabel = "Algunos días son simplemente normales. Pero otros pueden ser excepcionales, ¡esperamos que tengas más de estos últimos! esperando tener noticias tuyas mañana";
        this.okaySnackBar = "Está bien, ¡esperamos que mejore!";

        this.Happy = "Contenta";
        this.happyMoodLabel = "Mañana es un nuevo amanecer; ¡Un nuevo día y esperamos que te sientas aún mejor! esperando tener noticias tuyas mañana";
        this.happySnackBar="¡Estamos felices de ver eso!";

        this.Excited = "Emocionada";
        this.excitedMoodLabel ="¡Te sientes increíble, abrázalo y regresa con el mismo espíritu! esperando tener noticias tuyas mañana";
        this.excitedSnackBar = "Eso es genial, ¡sigamos así!";

        this.heresYourMood = "Aquí está tu estado de ánimo de hoy";
        this.whatsYourMood = "¿Cúal es tu estado de ánimo hoy?";
        this.submitResponseNote = "* Para que se registre su respuesta, haga clic en Enviar en la página final";
        this.questionsLeft = "Quedan preguntas";
        this.questionLeft = "Quedan preguntas";

        this.request = "Por favor, califique la acción tomada"
        this.thankYouComment = "Muchas gracias por dar su honesta opinión sobre nuestras acciones. Haga clic en el icono (+) para dar más..."
        this.actionOnFeedbackCommentPlaceholder = "¿Qué opinas de esta acción?";

        this.sixthOptionText = "No hay suficiente información para calificar";

        this.ICICISurveyCompletedAlready = "No hay sentencias pendientes de respuesta";
        break;

      //Portuguese - pt
      case "pt":
        this.welcomeText = "Bem vinda";
        this.startSurveyText = "iniciar pesquisa agora";
        this.surveyCompletedAlready = "Você já concluiu sua pesquisa";
        this.somethingWentWrong =
          "Alguma coisa deu errado. Por favor tente outra vez";
        this.nextButton = "Próximo";
        this.previousButton = "Anterior";
        this.submitButton = "Enviar";
        this.submittingButton = "Submetendo";
        this.logOut = "Sair";
        this.selectLanguage = "Selecione o idioma";
        this.tryAgainButton = "TENTE NOVAMENTE";
        this.writeHerePlaceHolder = "Por favor insira comentário";
        this.pleaseWaitWhileFetch =
          "Por favor aguarde enquanto buscamos sua pesquisa";
        this.thankYouText = "Obrigado por sua participação nesta pesquisa";
        this.termsConditions = "termos e Condições";
        this.privacyPolicy = "Política de Privacidade";
        this.brandGuidelines = "Diretrizes de marca";
        this.contactNumber = "Número de contato";
        this.emailText = "O email";
        this.aboutUsText = "Sobre nós";
        this.changePassword = "Mudar senha";
        this.Badge = "distintivo";
        this.floatingCommentLable = "por favor, elabore mais ...";
        this.actionPointDialogHeader = "Obrigado pelo seu feedback! A gestão tomou os seguintes pontos de ação com base em todo o feedback em relação a eles.";
        this.badgeTitle = "Seus emblemas";
        this.orgRank = "Classificação da Organização";
        this.teamRank = "Classificação da equipe";
        this.totalPoints = "Total de pontos";

        this.badgeTitle = "Seus emblemas";
        this.orgRank = "Classificação da Organização";
        this.teamRank = "Classificação da equipe";
        this.totalPoints = "Total de pontos";
        this.Youwon = "Você ganhou";
        this.badges = "distintivo";
        this.badgeSingular = "distintivo";
        this.PointsSystem = "Sistema de Pontos";
        this.Points = "Pontos";
        this.Calculation = "Cálculo";
        this.BadgeName = "Nome no crachá";
        this.Description = "Descrição";
        this.Howarepointscalculated = "Como os pontos são calculados?";
        this.Howteamrank = "Como decidimos a classificação da sua equipe?";
        this.HowOrgrank = "Como decidimos a classificação da sua organização?";
        this.Trailblazer = "Pioneira";
        this.Architect = "Arquiteta";
        this.Chieftain = "Chefe";
        this.Engineer = "Engenheira";
        this.Laureate = "Laureada";
        this.Wordsmith = "Escritor";
        this.trailblazerContent = "Este emblema é seu por dar o primeiro passo para se tornar um Pioneiro da Cultura. Você ganha 1 ponto por fazer login no aplicativo.";
        this.architectContent = "Para ganhar este lote, continue sua seqüência e responda à pesquisa por 5 dias seguidos. Um emblema de arquiteto vem com 5 pontos!";
        this.chieftainContent = "A maior honra, este emblema dá a você 30 pontos por ser um participante ativo e abandonar seus comentários por 5 dias consecutivos.";
        this.engineerContent = "Ganhe 10 pontos com este emblema, reivindicando sua sequência semanal pelo menos 3 vezes em um mês";
        this.laureateContent = "On The Spot Um laureado Deixando um comentário ou feedback detalhado? Ganhe 15 pontos instantaneamente com este emblema ..";
        this.wordsmithContent = "Este emblema ganha 3 pontos por deixar um breve comentário.";
        this.pointDescription1 = "Cada emblema tem pontos associados a ele. Ao receber um emblema, você também recebe os pontos associados que continuam sendo adicionados aos seus pontos existentes. Os pontos são calculados mês a mês e no início de cada mês a pontuação é redefinida para 0";
        this.pointDescription2 = "A Classificação da Equipe é decidida com base em seus pontos em comparação com as pontuações de outros em sua equipe. Equipe é definida de acordo com a Demografia que é escolhida por sua organização para definir as equipes";
        this.pointDescription3 = "A classificação da organização é decidida com base em seus pontos em comparação com os pontos de todos os outros em sua organização.";
        this.ok = "OK";
        this.congratulations = "Parabéns";
        this.trailblazerCongratulations = "Você recebeu o Emblema Trailblazer";
 this.laureateCongratulations = "Yay! Você recebeu o selo Laureate";
        this.wordsmithCongratulations = "Você recebeu o emblema de criador";
        this.architectCongratulations = "Você recebeu o emblema de arquiteto";
        this.chieftainCongratulations = "Você recebeu o emblema de Chieftain";
        this.submitResponseNote = "* Para que sua resposta seja registrada, clique em Enviar na página final"

        this.Anxious = "Ansiosa";
        this.anxiousMoodLabel = "Não há mal nenhum em como você está se sentindo, trate-se com gentileza! ansioso por ouvir de você amanhã";
        this.anxiousSnackBar = "Entendemos, estamos aqui para ajudá-lo.";

        this.Uneasy = "Infeliz";
        this.uneasyMoodLabel = "É completamente normal que isso aconteça hoje em dia, mas as coisas vão se encaixar como sempre! ansioso por ouvir de você amanhã";
        this.uneasySnackBar = "É normal se sentir assim. Esperamos que melhore!";

        this.Okay = "OK";
        this.okayMoodLabel = "Alguns dias são apenas meh. Mas outros podem ser excepcionais, esperamos que você tenha mais deste último! ansioso por ouvir de você amanhã";
        this.okaySnackBar = "Tudo bem, esperamos que melhore!";

        this.Happy = "Feliz";
        this.happyMoodLabel = "Amanhã é um novo amanhecer; um novo dia e esperamos que você se sinta ainda melhor! ansioso por ouvir de você amanhã";
        this.happySnackBar="Estamos felizes em ver isso!";

        this.Excited = "Animada";
        this.excitedMoodLabel ="Você se sente incrível, abrace e volte com o mesmo espírito! ansioso por ouvir de você amanhã";
        this.excitedSnackBar = "Isso é ótimo, vamos continuar assim!";

        this.heresYourMood = "Aqui está o seu humor de hoje";
        this.whatsYourMood = "Qual é o seu humor hoje?";

        this.submitResponseNote = "* Para que sua resposta seja registrada, clique em Enviar na página final";
        this.questionsLeft = "questões são deixadas";
        this.questionLeft = "questões são deixadas";

        this.request = "Por favor, avalie as medidas tomadas"
        this.thankYouComment = "Muito obrigado por dar a sua opinião honesta sobre as nossas ações. Clique no ícone (+) para dar mais..."
        this.actionOnFeedbackCommentPlaceholder = "O que acha desta ação?";

        this.sixthOptionText = "Não há informações suficientes para avaliar";

        this.ICICISurveyCompletedAlready = "Nenhuma declaração pendente a ser respondida";
        break;

      //Malay - ms
      case "ms":
        this.welcomeText = "Selamat datang";
        this.startSurveyText = "mulakan tinjauan sekarang";
        this.surveyCompletedAlready = "Anda telah menyelesaikan tinjauan anda";
        this.somethingWentWrong = "Ada yang salah, Sila cuba lagi";
        this.nextButton = "Seterusnya";
        this.previousButton = "Sebelum ini";
        this.submitButton = "Hantar";
        this.submittingButton = "Mengemukakan";
        this.logOut = "Log keluar";
        this.selectLanguage = "Pilih Bahasa";
        this.tryAgainButton = "CUBA LAGI";
        this.writeHerePlaceHolder = "Sila masukkan komen";
        this.pleaseWaitWhileFetch =
          "Sila tunggu sementara kami mengambil tinjauan anda";
        this.thankYouText =
          "Terima kasih kerana penyertaan anda dalam kaji selidik ini";
        this.termsConditions = "Terma & Syarat";
        this.privacyPolicy = "Dasar Privasi";
        this.brandGuidelines = "Garis Panduan Jenama";
        this.contactNumber = "Nombor telefon";
        this.emailText = "E-mel";
        this.aboutUsText = "Tentang kita";
        this.changePassword = "Tukar kata laluan";
        this.Badge = "lencana";
        this.floatingCommentLable = "sila huraikan lebih lanjut ...";
        this.actionPointDialogHeader="Terima kasih atas maklum balas anda. Pihak pengurusan telah mengambil langkah-langkah tindakan berdasarkan semua maklum balas terhadapnya.";
        this.badgeTitle = "Lencana Anda";
        this.orgRank = "Peringkat Organisasi";
        this.teamRank = "Kedudukan Pasukan";
        this.totalPoints = "Jumlah Mata";

        this.badgeTitle = "Lencana Anda";
        this.orgRank = "Peringkat Organisasi";
        this.teamRank = "Kedudukan Pasukan";
        this.totalPoints = "Jumlah Mata";
        this.Youwon = "Kamu telah menang";
        this.badges = "lencana";
        this.badgeSingular = "lencana";
        this.PointsSystem = "Sistem Titik";
        this.Points = "Mata";
        this.Calculation = "Pengiraan";
        this.BadgeName = "Nama Lencana";
        this.Description = "Penerangan";
        this.Howarepointscalculated = "Bagaimana mata dikira?";
        this.Howteamrank = "Bagaimana kami menentukan kedudukan pasukan anda?";
        this.HowOrgrank = "Bagaimana kita menentukan kedudukan organisasi anda?";
        this.Trailblazer = "Trailblazer";
        this.Architect = "Arkitek";
        this.Chieftain = "Ketua";
        this.Engineer = "Jurutera";
        this.Laureate = "Pemenang";
        this.Wordsmith = "Tukang Kata";
        this.trailblazerContent = "Lencana ini adalah milik anda untuk mengambil langkah pertama untuk menjadi Perintis Budaya. Anda mendapat 1 mata kerana log masuk ke aplikasi.";
        this.architectContent = "Untuk memenangkan kumpulan ini, teruskan kejayaan anda dan jawab tinjauan selama 5 hari berturut-turut. Lencana arkitek dilengkapi dengan 5 mata!";
        this.chieftainContent = "Penghormatan tertinggi, lencana ini memenangkan anda 30 Mata kerana menjadi peserta aktif dan memberikan komen anda selama 5 hari berturut-turut.";
        this.engineerContent = "Menangi 10 mata dengan lencana ini dengan menuntut kemenangan mingguan anda sekurang-kurangnya 3 kali dalam sebulan";
        this.laureateContent = "Di Tempat Terbaik Laureate Meninggalkan komen atau maklum balas terperinci? Menangi 15 mata serta-merta dengan lencana ini ..";
        this.wordsmithContent = "Lencana ini memenangkan anda 3 mata kerana memberikan komen ringkas.";
        this.pointDescription1 = "Setiap Lencana mempunyai mata yang berkaitan dengannya. Semasa anda menerima lencana, anda juga mendapat mata yang berkaitan yang terus ditambahkan ke mata yang ada. Poin dikira setiap bulan dan pada awal setiap bulan skor dikembalikan kepada 0";
        this.pointDescription2 = "Peringkat Pasukan ditentukan berdasarkan mata anda berbanding dengan skor orang lain dalam pasukan anda. Pasukan ditentukan mengikut Demografi yang dipilih oleh organisasi anda untuk menentukan pasukan";
        this.pointDescription3 = "Peringkat organisasi diputuskan berdasarkan mata anda berbanding dengan mata orang lain dalam organisasi anda ..";
        this.ok = "okey";
        this.congratulations = "selamat berjaya";
        this.trailblazerCongratulations = "Anda telah menerima Lencana Trailblazer";
 this.laureateCongratulations = "Yay! Anda telah menerima Lencana Laureate";
        this.wordsmithCongratulations = "Anda telah menerima Lencana Wordsmith";
        this.architectCongratulations = "Anda telah menerima Lencana Arkitek";
        this.chieftainCongratulations = "Anda telah menerima Lencana Chieftain";
        this.submitResponseNote = "* Agar jawapan anda dapat dirakam, sila klik Hantar di halaman terakhir"

        this.Anxious = "Ansiosa";
        this.anxiousMoodLabel = "Não há mal nenhum em como você está se sentindo, trate-se com gentileza! ansioso por ouvir de você amanhã";
        this.anxiousSnackBar = "Entendemos, estamos aqui para ajudá-lo.";

        this.Uneasy = "Tidak berpuas hati";
        this.uneasyMoodLabel = "É completamente normal que isso aconteça hoje em dia, mas as coisas vão se encaixar como sempre! ansioso por ouvir de você amanhã";
        this.uneasySnackBar = "É normal se sentir assim. Esperamos que melhore!";

        this.Okay = "OK";
        this.okayMoodLabel = "Alguns dias são apenas meh. Mas outros podem ser excepcionais, esperamos que você tenha mais deste último! ansioso por ouvir de você amanhã";
        this.okaySnackBar = "Tudo bem, esperamos que melhore!";

        this.Happy = "Feliz";
        this.happyMoodLabel = "Amanhã é um novo amanhecer; um novo dia e esperamos que você se sinta ainda melhor! ansioso por ouvir de você amanhã";
        this.happySnackBar="Estamos felizes em ver isso!";

        this.Excited = "Animada";
        this.excitedMoodLabel ="Você se sente incrível, abrace e volte com o mesmo espírito! ansioso por ouvir de você amanhã";
        this.excitedSnackBar = "Isso é ótimo, vamos continuar assim!";

        this.heresYourMood = "Aqui está o seu humor de hoje";
        this.whatsYourMood = "Qual é o seu humor hoje?";
        this.submitResponseNote = "* Agar jawapan anda dapat dirakam, sila klik Hantar di halaman terakhir";
        this.questionsLeft = "Tinggal soalan";
        this.questionLeft = "Tinggal soalan";

        this.request = "Sila nilai tindakan yang diambil"
        this.thankYouComment = "Terima kasih banyak untuk memberikan pendapat jujur Anda tentang tindakan kami. Klik ikon (+) untuk memberi lebih banyak..."
        this.actionOnFeedbackCommentPlaceholder = "Apa pendapat anda tentang tindakan ini?";

        this.sixthOptionText = "Tidak cukup maklumat untuk dinilai";

        this.ICICISurveyCompletedAlready = "Tiada kenyataan tertangguh untuk dijawab";
        break;

      //Russian - ru
      case "ru":
        this.welcomeText = "желанный";
        this.startSurveyText = "начать опрос сейчас";
        this.surveyCompletedAlready = "Вы уже завершили свой опрос";
        this.somethingWentWrong =
          "Что-то пошло не так. Пожалуйста, попытайтесь еще раз";
        this.nextButton = "следующий";
        this.previousButton = "предыдущий";
        this.submitButton = "Отправить";
        this.submittingButton = "предоставление";
        this.logOut = "Выйти";
        this.selectLanguage = "Выберите язык";
        this.tryAgainButton = "ПОПРОБУЙТЕ СНОВА";
        this.writeHerePlaceHolder = "Пожалуйста, введите комментарий";
        this.pleaseWaitWhileFetch =
          "Пожалуйста, подождите, пока мы получим ваш опрос";
        this.thankYouText = "Спасибо за ваше участие в этом опросе";
        this.termsConditions = "Условия использования";
        this.privacyPolicy = "политика конфиденциальности";
        this.brandGuidelines = "Руководство по бренду";
        this.contactNumber = "Контактный номер";
        this.emailText = "Эл. адрес";
        this.aboutUsText = "Насчет нас";
        this.changePassword = "Изменить пароль";
        this.Badge = "значок";
        this.floatingCommentLable = "пожалуйста, уточните подробнее ...";
        this.actionPointDialogHeader="Спасибо за ваш отзыв. Руководство приняло следующие меры на основе всех отзывов.";
        this.badgeTitle = "Ваши значки";
        this.orgRank = "Ранг организации";
        this.teamRank = "Рейтинг команды";
        this.totalPoints = "Общее количество очков";


        this.badgeTitle = "Ваши значки";
        this.orgRank = "Ранг организации";
        this.teamRank = "Рейтинг команды";
        this.totalPoints = "Общее количество очков";
        this.Youwon = "Ты победил";
        this.badges = "значок";
        this.badgeSingular = "значок";
        this.PointsSystem = "Point System";
        this.Points = "Points";
        this.Calculation = "Расчет";
        this.BadgeName = "Имя на бейдже";
        this.Description = "Описание";
        this.Howarepointscalculated = "Как начисляются баллы?";
        this.Howteamrank = "Как мы определяем рейтинг вашей команды?";
        this.HowOrgrank = "Как мы определяем рейтинг вашей организации?";
        this.Trailblazer = "Первопроходец";
        this.Architect = "Архитектор";
        this.Chieftain = "Вождь";
        this.Engineer = "Инженер";
        this.Laureate = "Лауреат";
        this.Wordsmith = "Wordsmith";
        this.trailblazerContent = "Этот значок - ваш первый шаг к тому, чтобы стать пионером культуры. Вы получаете 1 балл за вход в приложение.";
        this.architectContent = "Чтобы выиграть эту партию, продолжайте свою серию и отвечайте на опрос в течение 5 дней подряд. Значок архитектора дает 5 баллов!";
        this.chieftainContent = "Наивысшая награда: этот значок приносит вам 30 баллов за то, что вы являетесь активным участником и оставляете свои комментарии в течение 5 дней подряд.";
        this.engineerContent = "Выиграйте 10 очков с этим значком, заявив о своей еженедельной серии не менее 3 раз в месяц.";
        this.laureateContent = "On The Spot Лауреат Оставить подробный комментарий или отзыв? Мгновенно выиграйте 15 очков с этим значком ..";
        this.wordsmithContent = "Этот значок приносит вам 3 очка за краткий комментарий.";
        this.pointDescription1 = "С каждым значком связаны баллы. По мере того, как вы получаете значок, вы также получаете связанные баллы, которые продолжают добавляться к вашим существующим баллам. Очки начисляются по месяцам, и в начале каждого месяца счет сбрасывается до 0.";
        this.pointDescription2 = "Рейтинг команды определяется на основе ваших очков по сравнению с оценками других членов вашей команды. Команда определяется в соответствии с демографией, выбранной вашей организацией для определения команд.";
        this.pointDescription3 = "Рейтинг организации определяется на основе ваших баллов по сравнению с баллами всех остальных сотрудников вашей организации.";
        this.ok = "ОК";
        this.congratulations = "поздравляю";
        this.trailblazerCongratulations = "Вы получили значок первопроходца";
 this.laureateCongratulations = "Ура! Вы получили значок лауреата";
        this.wordsmithCongratulations = "Вы получили значок Вордсмит";
        this.architectCongratulations = "Вы получили значок архитектора";
        this.chieftainCongratulations = "Вы получили значок вождя";
        this.submitResponseNote = "* Чтобы ваш ответ был записан, нажмите Отправить на последней странице."

        this.Anxious = "Тревожный";
        this.anxiousMoodLabel = "Нет ничего плохого в том, как вы себя чувствуете, относитесь к себе с добротой! с нетерпением жду ответа от вас завтра";
        this.anxiousSnackBar = "Мы понимаем, мы здесь для вас";

        this.Uneasy = "Несчастный";
        this.uneasyMoodLabel = "В наши дни это совершенно нормально, но все встанет на свои места, как и всегда! с нетерпением жду ответа от вас завтра";
        this.uneasySnackBar = "Это нормально. Мы надеемся, что ситуация улучшится!";

        this.Okay = "Хорошо";
        this.okayMoodLabel = "Некоторые дни просто меня. Но другие могут быть исключительными, мы надеемся, что у вас их будет больше! с нетерпением жду ответа от вас завтра";
        this.okaySnackBar = "Ничего страшного, мы надеемся, что станет лучше!";

        this.Happy = "Счастливый";
        this.happyMoodLabel = "Завтра новый рассвет; новый день, и мы надеемся, что вы почувствуете себя еще лучше! с нетерпением жду ответа от вас завтра";
        this.happySnackBar="Мы очень рады этому!";

        this.Excited = "В восторге";
        this.excitedMoodLabel ="Вы чувствуете себя потрясающе, примите это и вернетесь с тем же духом! с нетерпением жду ответа от вас завтра";
        this.excitedSnackBar = "Это здорово, так держать!";

        this.heresYourMood = "Вот твое сегодняшнее настроение";
        this.whatsYourMood = "Какое у тебя сегодня настроение?";
        this.submitResponseNote = "* Чтобы ваш ответ был записан, нажмите Отправить на последней странице.";
        this.questionsLeft = "Осталось вопроса";
        this.questionLeft = "Осталось вопроса";

        this.request = "Пожалуйста, оцените предпринятые действия"
        this.thankYouComment = "Большое спасибо за то, что вы дали свое честное мнение о наших действиях. Нажмите на значок (+), чтобы дать больше..."
        this.actionOnFeedbackCommentPlaceholder = "Что вы думаете об этом действии?";

        this.sixthOptionText = "Недостаточно информации для оценки";

        this.ICICISurveyCompletedAlready = "Нет заявлений, на которые нужно ответить";
        break;

      //Italian - it
      case "it":
        this.welcomeText = "benvenuto";
        this.startSurveyText = "inizia il sondaggio ora";
        this.surveyCompletedAlready = "Hai già completato il sondaggio";
        this.somethingWentWrong = "Qualcosa è andato storto, prova ancora";
        this.nextButton = "Il prossimo";
        this.previousButton = "Precedente";
        this.submitButton = "Sottoscrivi";
        this.submittingButton = "Invio";
        this.logOut = "Disconnettersi";
        this.selectLanguage = "Seleziona la lingua";
        this.tryAgainButton = "RIPROVA";
        this.writeHerePlaceHolder = "Si prega di inserire un commento";
        this.pleaseWaitWhileFetch =
          "Per favore aspetta mentre recuperiamo il tuo sondaggio";
        this.thankYouText =
          "Grazie per la tua partecipazione a questo sondaggio";
        this.termsConditions = "Termini & Condizioni";
        this.privacyPolicy = "politica sulla riservatezza";
        this.brandGuidelines = "Linee guida del marchio";
        this.contactNumber = "Numero di contatto";
        this.emailText = "E-mail";
        this.aboutUsText = "Riguardo a noi";
        this.changePassword = "Cambia la password";
        this.Badge = "distintivo";
        this.floatingCommentLable = "si prega di elaborare ulteriormente ...";
        this.actionPointDialogHeader = "Grazie per il tuo feedback. La direzione ha adottato i seguenti punti di azione in base a tutti i feedback contro di essa.";
        this.badgeTitle = "I tuoi badge";
        this.orgRank = "Rango dell'organizzazione";
        this.teamRank = "Classifica squadra";
        this.totalPoints = "Punti totali";

        this.badgeTitle = "I tuoi badge";
        this.orgRank = "Rango dell'organizzazione";
        this.teamRank = "Classifica squadra";
        this.totalPoints = "Punti totali";
        this.Youwon = "Hai vinto";
        this.badges = "distintivo";
        this.badgeSingular = "distintivo";
        this.PointsSystem = "Sistema di punti";
        this.Points = "Punti";
        this.Calculation = "Calcolo";
        this.BadgeName = "Nome badge";
        this.Description = "Descrizione";
        this.Howarepointscalculated = "Come vengono calcolati i punti?";
        this.Howteamrank = "Come decidiamo il grado della tua squadra?";
        this.HowOrgrank = "Come decidiamo il grado della tua organizzazione?";
        this.Trailblazer = "Pioniera";
        this.Architect = "Architetta";
        this.Chieftain = "Capa";
        this.Engineer = "Ingegnera";
        this.Laureate = "Laureata";
        this.Wordsmith = "Wordsmith";
        this.trailblazerContent = "Questo badge è tuo per aver fatto il primo passo per diventare un pioniere della cultura. Ottieni 1 punto per l'accesso all'app.";
        this.architectContent = "Per vincere questo lotto, mantieni la tua serie di vittorie e rispondi al sondaggio per 5 giorni consecutivi. Un distintivo di architetto viene fornito con 5 punti!";
        this.chieftainContent = "L'onore più alto, questo badge ti fa guadagnare 30 punti per essere un partecipante attivo e lasciare i tuoi commenti per 5 giorni consecutivi.";
        this.engineerContent = "Vinci 10 punti con questo badge rivendicando la tua serie settimanale almeno 3 volte in un mese";
        this.laureateContent = "On The Spot Un laureato Lasciando un commento o feedback dettagliato? Vinci 15 punti istantaneamente con questo badge ..";
        this.wordsmithContent = "Questo badge ti fa guadagnare 3 punti per aver lasciato un breve commento.";
        this.pointDescription1 = "Ogni badge ha punti associati ad esso. Quando ricevi un badge, ricevi anche i punti associati che continuano ad essere aggiunti ai tuoi punti esistenti. I punti vengono calcolati su base mensile e all'inizio di ogni mese il punteggio viene reimpostato a 0";
        this.pointDescription2 = "Il grado di squadra viene deciso in base ai tuoi punti rispetto ai punteggi degli altri nella tua squadra. Il team è definito in base alla demografia scelta dalla tua organizzazione per definire i team";
        this.pointDescription3 = "Il grado dell'organizzazione viene deciso in base ai tuoi punti rispetto ai punti di tutti gli altri nella tua organizzazione.";
        this.ok = "ok";
        this.congratulations = "Congratulazioni";
        this.trailblazerCongratulations = "Hai ricevuto il badge Trailblazer";
 this.laureateCongratulations = "Sìì! Hai ricevuto il badge laureato";
        this.wordsmithCongratulations = "Hai ricevuto il distintivo Wordsmith";
        this.architectCongratulations = "Hai ricevuto il distintivo dell'architetto";
        this.chieftainCongratulations = "Hai ricevuto il distintivo del capitano";
        this.submitResponseNote = "* Per registrare la tua risposta, fai clic su Invia nella pagina finale"

        this.Anxious = "Ansiosa";
        this.anxiousMoodLabel = "Non c'è nulla di male nel modo in cui ti senti, trattati con gentilezza! non vedo l'ora di sentirti domani";
        this.anxiousSnackBar = "Capiamo, siamo qui per te";

        this.Uneasy = "Infelice";
        this.uneasyMoodLabel = "È del tutto normale avere questi giorni, ma le cose andranno a posto come hanno sempre fatto! non vedo l'ora di sentirti domani";
        this.uneasySnackBar = "Va bene sentirsi in questo modo. Speriamo che migliori!";

        this.Okay = "Va bene";
        this.okayMoodLabel = "Alcuni giorni sono solo meh. Ma altri possono essere eccezionali, speriamo che tu ne abbia di più! non vedo l'ora di sentirti domani";
        this.okaySnackBar = "Va bene, speriamo che vada meglio!";

        this.Happy = "Felice";
        this.happyMoodLabel = "Domani è una nuova alba; un nuovo giorno e speriamo che tu ti senta ancora meglio! non vedo l'ora di sentirti domani";
        this.happySnackBar="Siamo felici di vederlo!";

        this.Excited = "Emozionato";
        this.excitedMoodLabel ="Ti senti fantastico, abbraccialo e torna con lo stesso spirito! non vedo l'ora di sentirti domani";
        this.excitedSnackBar = "È fantastico, continuiamo così!";

        this.heresYourMood = "Ecco il tuo umore di oggi";
        this.whatsYourMood = "Qual è il tuo umore oggi?";
        this.submitResponseNote = "* Per registrare la tua risposta, fai clic su Invia nella pagina finale";
        this.questionsLeft = "Rimangono domande";
        this.questionLeft = "Rimangono domande";

        this.request = "Si prega di valutare l'azione intrapresa"
        this.thankYouComment = "Grazie mille per aver dato la tua onesta opinione sulle nostre azioni. Fai clic sull'icona (+) per dare di più..."
        this.actionOnFeedbackCommentPlaceholder = "Cosa ne pensi di questa azione?";

        this.sixthOptionText = "Informazioni insufficienti per valutare";

        this.ICICISurveyCompletedAlready = "Nessuna dichiarazione pendente a cui rispondere";
        break;

      //French - fr
      case "fr":
        this.welcomeText = "Bienvenue";
        this.startSurveyText = "commencez l'enquête maintenant";
        this.surveyCompletedAlready = "Vous avez déjà complété votre sondage";
        this.somethingWentWrong =
          "Une erreur s'est produite. Veuillez réessayer";
        this.nextButton = "Suivant";
        this.previousButton = "précédent";
        this.submitButton = "Soumettre";
        this.submittingButton = "Soumission";
        this.logOut = "Connectez - Out";
        this.selectLanguage = "Choisir la langue";
        this.tryAgainButton = "RÉESSAYER";
        this.writeHerePlaceHolder = "S'il vous plaît entrer un commentaire";
        this.pleaseWaitWhileFetch =
          "Veuillez patienter pendant que nous récupérons votre sondage";
        this.thankYouText = "Merci pour votre participation à cette enquête";
        this.termsConditions = "termes et conditions";
        this.privacyPolicy = "Politique de confidentialité";
        this.brandGuidelines = "Directives de marque";
        this.contactNumber = "Numéro de contact";
        this.emailText = "Email";
        this.aboutUsText = "À propos de nous";
        this.changePassword = "Changer le mot de passe";
        this.Badge = "Badges";
        this.floatingCommentLable = "veuillez préciser davantage ...";
        this.actionPointDialogHeader = "Merci pour vos commentaires. La direction a pris les mesures suivantes en fonction de tous les commentaires à son encontre.";
        this.badgeTitle = "Vos badges";
        this.orgRank = "Classement de l'organisation";
        this.teamRank = "Rang de l'équipe";
        this.totalPoints = "Points totaux";

        this.badgeTitle = "Vos badges";
        this.orgRank = "Classement de l'organisation";
        this.teamRank = "Rang de l'équipe";
        this.totalPoints = "Points totaux";
        this.Youwon = "Tu as gagné";
        this.badges = "badge";
        this.badgeSingular = "badge";
        this.PointsSystem = "Système de points";
        this.Points = "Points";
        this.Calculation = "Calcul";
        this.BadgeName = "Badge";
        this.Description = "La description";
        this.Howarepointscalculated = "Comment les points sont-ils calculés?";
        this.Howteamrank = "Comment décidons-nous du classement de votre équipe?";
        this.HowOrgrank = "Comment décidons-nous du classement de votre organisation?";
        this.Trailblazer = "Pionnière";
        this.Architect = "Architecte";
        this.Chieftain = "Chef de clan";
        this.Engineer = "Ingénieure";
        this.Laureate = "Lauréate";
        this.Wordsmith = "Wordsmith";
        this.trailblazerContent = "Ce badge est le vôtre pour avoir franchi la première étape pour devenir un pionnier de la culture. Vous gagnez 1 point en vous connectant à l'application.";
        this.architectContent = "Pour gagner ce lot, continuez votre séquence et répondez au sondage pendant 5 jours consécutifs. Un badge d'architecte comporte 5 points!";
        this.chieftainContent = "La plus haute distinction, ce badge vous rapporte 30 points pour avoir été un participant actif et laisser vos commentaires pendant 5 jours consécutifs.";
        this.engineerContent = "Gagnez 10 points avec ce badge en réclamant votre séquence hebdomadaire au moins 3 fois par mois";
        this.laureateContent = "On The Spot Un lauréat Vous laissez un commentaire détaillé ou un retour d'expérience? Gagnez 15 points instantanément avec ce badge.";
        this.wordsmithContent = "Ce badge vous rapporte 3 points pour avoir laissé un bref commentaire.";
        this.pointDescription1 = "Chaque badge est associé à des points. Lorsque vous recevez un badge, vous obtenez également les points associés qui ne cessent de s'ajouter à vos points existants. Les points sont calculés par mois et au début de chaque mois, le score est remis à 0";
        this.pointDescription2 = "Le rang d'équipe est déterminé en fonction de vos points par rapport aux scores des autres membres de votre équipe. L'équipe est définie en fonction de la démographie choisie par votre organisation pour définir les équipes";
        this.pointDescription3 = "Le classement de l'organisation est déterminé en fonction de vos points par rapport aux points de tous les autres membres de votre organisation.";
        this.ok = "d'accord";
        this.congratulations = "toutes nos félicitations";
        this.trailblazerCongratulations = "Vous avez reçu le badge Trailblazer";
 this.laureateCongratulations = "Yay! Vous avez reçu le badge des lauréats";
        this.wordsmithCongratulations = "Vous avez reçu le badge Wordsmith";
        this.architectCongratulations = "Vous avez reçu le badge d'architecte";
        this.chieftainCongratulations = "Vous avez reçu le badge de chef";
        this.submitResponseNote = "* Pour que votre réponse soit enregistrée, veuillez cliquer sur Soumettre sur la dernière page"

        this.Anxious = "Anxieuse";
        this.anxiousMoodLabel = "Il n'y a pas de mal dans ce que vous ressentez, traitez-vous avec gentillesse ! au plaisir de vous entendre demain";
        this.anxiousSnackBar = "Nous comprenons, nous sommes là pour vous";

        this.Uneasy = "Malheureux";
        this.uneasyMoodLabel = "C'est tout à fait normal d'avoir ces jours-ci, mais les choses se mettront en place comme elles l'ont toujours fait ! au plaisir de vous entendre demain";
        this.uneasySnackBar = "C'est bien de ressentir ça. Espérons que ça s'améliore !";

        this.Okay = "D'accord";
        this.okayMoodLabel = "Certains jours sont juste meh. Mais d'autres peuvent être exceptionnels, nous espérons que vous en aurez plus ! au plaisir de vous entendre demain";
        this.okaySnackBar = "C'est bon, on espère que ça ira mieux !";

        this.Happy = "Heureuse";
        this.happyMoodLabel = "Demain est une nouvelle aube; un nouveau jour et nous espérons que vous vous sentez encore mieux ! au plaisir de vous entendre demain";
        this.happySnackBar="Nous sommes heureux de voir ça !";

        this.Excited = "Excitée";
        this.excitedMoodLabel ="Vous vous sentez incroyable, embrassez-le et revenez avec le même esprit ! au plaisir de vous entendre demain";
        this.excitedSnackBar = "C'est super, continuons comme ça !";

        this.heresYourMood = "Voici votre humeur du jour";
        this.whatsYourMood = "Quelle est votre humeur aujourd'hui ?";
        this.submitResponseNote = "* Pour que votre réponse soit enregistrée, veuillez cliquer sur Soumettre sur la dernière page";
        this.questionsLeft = "il reste questions";
        this.questionLeft = "il reste questions";

        this.request = "Veuillez noter l’action entreprise"
        this.thankYouComment = "Merci beaucoup d’avoir donné votre opinion honnête sur nos actions. Cliquez sur l’icône (+) pour en savoir plus..."
        this.actionOnFeedbackCommentPlaceholder = "Que pensez-vous de cette action ?";

        this.sixthOptionText = "Pas assez d'informations pour évaluer";

        this.ICICISurveyCompletedAlready = "Aucune déclaration en attente à laquelle il faut répondre";
        break;

      //German - de
      case "de":
        this.welcomeText = "Herzlich willkommen";
        this.startSurveyText = "Umfrage jetzt starten";
        this.surveyCompletedAlready =
          "Sie haben Ihre Umfrage bereits ausgefüllt";
        this.somethingWentWrong =
          "Etwas ist schief gelaufen. Bitte versuche es erneut";
        this.nextButton = "Nächster";
        this.previousButton = "Bisherige";
        this.submitButton = "Einreichen";
        this.submittingButton = "Einreichen";
        this.logOut = "Ausloggen";
        this.selectLanguage = "Sprache auswählen";
        this.tryAgainButton = "VERSUCHEN SIE ES NOCHMAL";
        this.writeHerePlaceHolder = "Bitte Kommentar eingeben";
        this.pleaseWaitWhileFetch =
          "Bitte warten Sie, während wir Ihre Umfrage abrufen";
        this.thankYouText = "Vielen Dank für Ihre Teilnahme an dieser Umfrage";
        this.termsConditions = "Terms & amp; Bedingungen";
        this.privacyPolicy = "Datenschutz-Bestimmungen";
        this.brandGuidelines = "Markenrichtlinien";
        this.contactNumber = "Kontakt Nummer";
        this.emailText = "Email";
        this.aboutUsText = "Über uns";
        this.changePassword = "Ändere das Passwort";
        this.Badge = "Abzeichen";
        this.floatingCommentLable = "bitte weiter ausführen ...";
        this.actionPointDialogHeader = "Vielen Dank für Ihre Rückmeldung. Das Management hat folgende Aktionspunkte ergriffen, basierend auf allen Rückmeldungen.";
        this.badgeTitle = "Deine Abzeichen";
        this.orgRank = "Organisationsrang";
        this.teamRank = "Teamrang";
        this.totalPoints = "Gesamtpunktzahl";

        this.badgeTitle = "Deine Abzeichen";
        this.orgRank = "Organisationsrang";
        this.teamRank = "Team Rang";
        this.totalPoints = "Gesamtpunktzahl";
        this.Youwon = "Du hast gewonnen";
        this.badges = "Abzeichen";
        this.badgeSingular = "Abzeichen";
        this.PointsSystem = "Punkte System";
        this.Points = "Punkte";
        this.Calculation = "Berechnung";
        this.BadgeName = "Abzeichen Name";
        this.Description = "Beschreibung";
        this.Howarepointscalculated = "Wie werden Punkte berechnet?";
        this.Howteamrank = "Wie entscheiden wir Ihr Team Rang?";
        this.HowOrgrank = "Wie bestimmen wir Ihren Organisationsrang?";
        this.Trailblazer = "Wegbereiterin";
        this.Architect = "Architektin";
        this.Chieftain = "Häuptling";
        this.Engineer = "Ingenieurin";
        this.Laureate = "Preisträger";
        this.Wordsmith = "Wortschmied";
        this.trailblazerContent = "Dieses Abzeichen gehört Ihnen, um den ersten Schritt zum Kulturpionier zu machen. Sie gewinnen 1 Punkt für die Anmeldung bei der App.";
        this.architectContent = "Um diesen Batch zu gewinnen, halten Sie Ihre Serie am Laufen und beantworten Sie die Umfrage 5 Tage lang. Ein Architektenabzeichen kommt mit 5 Punkten!";
        this.chieftainContent = "Die höchste Ehre, dieses Abzeichen, bringt Ihnen 30 Punkte, wenn Sie ein aktiver Teilnehmer sind und Ihre Kommentare 5 Tage hintereinander fallen lassen.";
        this.engineerContent = "Gewinnen Sie mit diesem Abzeichen 10 Punkte, indem Sie Ihre wöchentliche Serie mindestens dreimal im Monat beanspruchen";
        this.laureateContent = "Vor Ort Ein Preisträger Hinterlässt einen detaillierten Kommentar oder ein Feedback? Gewinnen Sie sofort 15 Punkte mit diesem Abzeichen.";
        this.wordsmithContent = "Dieses Abzeichen bringt Ihnen 3 Punkte, wenn Sie einen kurzen Kommentar hinterlassen.";
        this.pointDescription1 = "Jedem Abzeichen sind Punkte zugeordnet. Wenn Sie ein Abzeichen erhalten, erhalten Sie auch die zugehörigen Punkte, die immer wieder zu Ihren vorhandenen Punkten hinzugefügt werden. Die Punkte werden monatlich berechnet und zu Beginn eines jeden Monats wird die Punktzahl auf 0 zurückgesetzt";
        this.pointDescription2 = "Der Teamrang wird anhand Ihrer Punkte im Vergleich zu den Punktzahlen anderer Mitglieder Ihres Teams festgelegt. Das Team wird gemäß der Demografie definiert, die von Ihrer Organisation ausgewählt wird, um die Teams zu definieren";
        this.pointDescription3 = "Der Organisationsrang wird anhand Ihrer Punkte im Vergleich zu den Punkten aller anderen in Ihrer Organisation festgelegt.";
        this.ok = "OK";
        this.congratulations = "Herzliche Glückwünsche";
        this.trailblazerCongratulations = "Sie haben das Wegbereiter-Abzeichen erhalten";
 this.laureateCongratulations = "Yay! Sie haben das Laureate Badge erhalten";
        this.wordsmithCongratulations = "Sie haben das Wortschmiedeabzeichen erhalten";
        this.architectCongratulations = "Sie haben das Architektenabzeichen erhalten";
        this.chieftainCongratulations = "Sie haben das Häuptlingsabzeichen erhalten";
        this.submitResponseNote = "* Damit Ihre Antwort aufgezeichnet wird, klicken Sie bitte auf der letzten Seite auf Senden"

        this.Anxious = "Ängstlich";
        this.anxiousMoodLabel = "Es schadet nicht, wie Sie sich fühlen, behandeln Sie sich mit Freundlichkeit! freue mich morgen von dir zu hören";
        this.anxiousSnackBar = "Wir verstehen, wir sind für Sie da";

        this.Uneasy = "Unzufrieden";
        this.uneasyMoodLabel = "Es ist völlig normal, diese Tage zu haben, aber die Dinge werden wie immer funktionieren! freue mich morgen von dir zu hören";
        this.uneasySnackBar = "Es ist in Ordnung, sich so zu fühlen. Wir hoffen, es verbessert sich!";

        this.Okay = "In Ordnung";
        this.okayMoodLabel = "Manche Tage sind einfach meh. Aber andere können außergewöhnlich sein, wir hoffen, Sie haben mehr davon! freue mich morgen von dir zu hören";
        this.okaySnackBar = "Das ist gut so, wir hoffen es wird besser!";

        this.Happy = "Glücklich";
        this.happyMoodLabel = "Morgen ist ein neuer Morgen; einen neuen Tag und wir hoffen, es geht dir noch besser! freue mich morgen von dir zu hören";
        this.happySnackBar="Das sehen wir gerne!";

        this.Excited = "Aufgeregt";
        this.excitedMoodLabel ="Sie fühlen sich großartig, umarmen es und kommen mit dem gleichen Geist zurück! freue mich morgen von dir zu hören";
        this.excitedSnackBar = "Das ist toll, machen wir weiter so!";

        this.heresYourMood = "Hier ist deine heutige Stimmung";
        this.whatsYourMood = "Wie ist deine Stimmung heute?";
        this.submitResponseNote = "* Damit Ihre Antwort aufgezeichnet wird, klicken Sie bitte auf der letzten Seite auf Senden";
        this.questionsLeft = "Fragen sind noch offen";
        this.questionLeft = "Fragen sind noch offen";

        this.request = "Was halten Sie von dieser Aktion?"
        this.thankYouComment = "Vielen Dank, dass Sie Ihre ehrliche Meinung zu unseren Aktionen abgegeben haben. Klicken Sie auf das Symbol (+), um mehr zu erhalten..."
        this.actionOnFeedbackCommentPlaceholder = "Was halten Sie von dieser Aktion?";
        this.sixthOptionText = "Nicht genügend Informationen zum Bewerten";

        this.ICICISurveyCompletedAlready = "Keine ausstehenden Aussagen zu beantworten";
        break;

      //Arabic - ar
      case "ar":
        this.welcomeText = "أهلا بك";
        this.startSurveyText = "بدء المسح الآن";
        this.surveyCompletedAlready = "كنت قد أكملت بالفعل الاستطلاع الخاص بك";
        this.somethingWentWrong = "حدث خطأ ما. أعد المحاولة من فضلك";
        this.nextButton = "التالى";
        this.previousButton = "سابق";
        this.submitButton = "خضع";
        this.submittingButton = "تقديم";
        this.logOut = "الخروج";
        this.selectLanguage = "اختار اللغة";
        this.tryAgainButton = "حاول مرة أخري";
        this.writeHerePlaceHolder = "الرجاء إدخال التعليق";
        this.pleaseWaitWhileFetch =
          "يرجى الانتظار بينما نقوم بإحضار الاستبيان الخاص بك";
        this.thankYouText = "شكرا لمشاركتكم في هذا الاستطلاع";
        this.termsConditions = "البنود و الظروف";
        this.privacyPolicy = "سياسة خاصة";
        this.brandGuidelines = "إرشادات العلامة التجارية";
        this.contactNumber = "رقم الاتصال";
        this.emailText = "البريد الإلكتروني";
        this.aboutUsText = "معلومات عنا";
        this.changePassword = "غير كلمة السر";
        this.Badge = "شارة";
        this.floatingCommentLable = "يرجى توضيح المزيد ...";
        this.actionPointDialogHeader = "شكرا لملاحظاتك. اتخذت الإدارة نقاط العمل التالية بناءً على جميع التعليقات ضدها.";
        this.badgeTitle = "شاراتك";
        this.orgRank = "رتبة المنظمة ";
        this.teamRank = "رتبة الفريق";
        this.totalPoints = "مجمل النقاط";

        this.badgeTitle = "شاراتك";
        this.orgRank = "رتبة المنظمة";
        this.teamRank = "رتبة الفريق";
        this.totalPoints = "مجمل النقاط";
        this.Youwon = "لقد فزت";
        this.badges = "شارة";
        this.badgeSingular = "شارة";
        this.PointsSystem = "نظام النقاط";
        this.Points = "نقاط";
        this.Calculation = "عملية حسابية";
        this.BadgeName = "اسم الشارة";
        this.Description = "وصف";
        this.Howarepointscalculated = "كيف يتم احتساب النقاط؟";
        this.Howteamrank = "كيف نحدد رتبة فريقك؟";
        this.HowOrgrank = "كيف نحدد رتبة مؤسستك؟";
        this.Trailblazer = "تريل بليزر";
        this.Architect = "مهندس معماري";
        this.Chieftain = "زعيم";
        this.Engineer = "مهندس";
        this.Laureate = "الحائز على جائزة";
        this.Wordsmith = "وردسميث";
        this.trailblazerContent = "هذه الشارة لك لاتخاذ الخطوة الأولى لتصبح رائدًا في الثقافة. تكسب 1 نقطة لتسجيل الدخول إلى التطبيق.";
        this.architectContent = "للفوز بهذه الدفعة ، استمر في خطك وأجب على الاستبيان لمدة 5 أيام متتالية. تأتي شارة المهندس مع 5 نقاط!";
        this.chieftainContent = "أعلى تكريم ، تمنحك هذه الشارة 30 نقطة لكونك مشاركًا نشطًا وإسقاط تعليقاتك لمدة 5 أيام متتالية.";
        this.engineerContent = "اربح 10 نقاط بهذه الشارة عن طريق المطالبة بخطك الأسبوعي 3 مرات على الأقل في الشهر";
        this.laureateContent = "On The Spot A Laureate هل تترك تعليقًا تفصيليًا أو تعليقًا؟ اربح 15 نقطة على الفور بهذه الشارة ..";
        this.wordsmithContent = "تمنحك هذه الشارة 3 نقاط لترك تعليق مختصر.";
        this.pointDescription1 = "كل شارة لها نقاط مرتبطة بها. عندما تتلقى شارة ، تحصل أيضًا على النقاط المرتبطة التي يتم إضافتها باستمرار إلى نقاطك الحالية. يتم احتساب النقاط على أساس شهري وفي بداية كل شهر يتم إعادة تعيين النقاط إلى 0";
        this.pointDescription2 = "يتم تحديد تصنيف الفريق على أساس نقاطك مقارنة بنتائج الآخرين في فريقك. يتم تحديد الفريق وفقًا للديموغرافيا التي تختارها مؤسستك لتحديد الفرق";
        this.pointDescription3 = "يتم تحديد ترتيب المنظمة على أساس نقاطك مقارنة بنقاط أي شخص آخر في مؤسستك.";
        this.ok = "نعم";
        this.congratulations = "تهانينا";
        this.trailblazerCongratulations = "لقد تلقيت شارة Trailblazer";
 this.laureateCongratulations = "ياي! لقد تلقيت شارة الحائز على جائزة";
        this.wordsmithCongratulations = "لقد تلقيت شارة Wordsmith";
        this.architectCongratulations = "لقد تلقيت شارة المهندس المعماري";
        this.chieftainCongratulations = "لقد تلقيت شارة الرئيس";
        this.Anxious = "قلق";
        this.anxiousMoodLabel = "لا ضير في شعورك ، عامل نفسك بلطف! نتطلع للسماع منك غدا";
        this.anxiousSnackBar = "نحن نفهم ، نحن هنا من أجلك";

        this.Uneasy = "تعيس";
        this.uneasyMoodLabel = "من الطبيعي تمامًا أن تكون لديك هذه الأيام ، لكن الأمور ستسقط كما كانت دائمًا! نتطلع للسماع منك غدا";
        this.uneasySnackBar = "لا بأس أن تشعر بهذه الطريقة. نأمل أن تتحسن!";

        this.Okay = "تمام";
        this.okayMoodLabel = "بعض الأيام هي مجرد مهلة. لكن يمكن للآخرين أن يكونوا استثنائيين ، نأمل أن يكون لديك المزيد من هذا الأخير! نتطلع للسماع منك غدا";
        this.okaySnackBar = "هذا جيد ، نأمل أن يتحسن!";

        this.Happy = "سعيد";
        this.happyMoodLabel = "غدا فجر جديد. يوم جديد ونأمل أن تشعر بتحسن! نتطلع للسماع منك غدا";
        this.happySnackBar="نحن سعداء برؤية ذلك!";

        this.Excited = "فرح";
        this.excitedMoodLabel ="تشعر بالدهشة ، احتضنها وعد بنفس الروح! نتطلع للسماع منك غدا";
        this.excitedSnackBar = "هذا رائع ، فلنستمر في ذلك!";

        this.heresYourMood = "ها هي مزاجك اليوم";
        this.whatsYourMood = "ما هو مزاجك اليوم؟";
        this.submitResponseNote = "* لتسجيل إجابتك ، يرجى النقر فوق إرسال في الصفحة الأخيرة";
        this.questionsLeft = "أسئلة متبقية";
        this.questionLeft = "أسئلة متبقية";

        this.request = "يرجى تقييم الإجراءات المتخذة"
        this.thankYouComment = "شكرا جزيلا على إبداء رأيك الصادق في أفعالنا. انقر فوق الرمز (+) لإعطاء المزيد..."
        this.actionOnFeedbackCommentPlaceholder = "ما رأيك في هذا العمل؟";
        this.sixthOptionText = "لا توجد معلومات كافية للتقييم";

        this.ICICISurveyCompletedAlready = "لا توجد بيانات معلقة ليتم الرد عليها";
        break;

      //Assamese - as  NEED TO CHANEGE ENG TEXT ADDED
      case "as":
        this.welcomeText = "Welcome";
        this.startSurveyText = "start survey now";
        this.surveyCompletedAlready = "Survey questions not available";
        this.somethingWentWrong = "Something went wrong, Please try again";
        this.nextButton = "Next";
        this.previousButton = "Previous";
        this.submitButton = "Submit";
        this.submittingButton = "Submitting";
        this.logOut = "Logout";
        this.selectLanguage = "Select Language";
        this.tryAgainButton = "TRY AGAIN";
        this.writeHerePlaceHolder = "Please enter comment";
        this.pleaseWaitWhileFetch = "Please wait while we fetch your survey";
        this.thankYouText = "Thank you for your participation in this survey";
        this.termsConditions = "Terms & Conditions";
        this.privacyPolicy = "Privacy Policy";
        this.brandGuidelines = "Brand Guidelines";
        this.contactNumber = "Contact Number";
        this.emailText = "Email";
        this.aboutUsText = "About Us";
        this.changePassword = "Change Password";
        this.Badge = "Badges";
        this.floatingCommentLable = "please elaborate further...";
        this.actionPointDialogHeader = "Thanks for your feedback. The management has taken following action points based an all feedback against it.";
        this.badgeTitle = "Your Badges";
        this.orgRank = "Organixation Rank"
        this.teamRank = "Team Rank";
        this.totalPoints = "Total points";

        this.badgeTitle = "Your Badges";
        this.orgRank = "Organization Rank";
        this.teamRank = "Team Rank";
        this.totalPoints = "Total Points";
        this.Youwon = "You won";
        this.badges = "badge";
        this.badgeSingular = "badges";
        this.PointsSystem = "Point System";
        this.Points = "Points";
        this.Calculation = "Calculation";
        this.BadgeName = "Badge Name";
        this.Description = "Description";
        this.Howarepointscalculated = "How are points calculated?";
        this.Howteamrank = "How do we decide your team rank?";
        this.HowOrgrank = "How do we decide your organization rank?";
        this.Trailblazer = "Trailblazer";
        this.Architect = "Architect";
        this.Chieftain = "Chieftain";
        this.Engineer = "Engineer";
        this.Laureate = "Laureate";
        this.Wordsmith = "Wordsmith";
        this.trailblazerContent = "This badge is yours for taking the first step to becoming a Culture Pioneer. You win 1 point for logging into the app.";
        this.architectContent = "To win this batch, keep your streak going and answer the survey for 5 days straight. An architect badge comes with 5 points!";
        this.chieftainContent = "The highest honor, this badge wins you 30 Points for being an active participant and dropping your comments for 5 days in a row.";
        this.engineerContent = "Win 10 points with this badge by claiming your weekly streak at least 3 times in a month";
        this.laureateContent = "On The Spot A Laureate Leaving a detailed comment or feedback? Win 15 points instantly with this badge..";
        this.wordsmithContent = "This badge wins you 3 points for leaving a brief comment.";
        this.pointDescription1 = "Each Badge has points associated with it. As you receive a badge, you also get the associated points which keep getting added to your existing points. Points are calculated month wise and at the start of every month the score gets reset to 0";
        this.pointDescription2 = "Team Rank is decided basis your points as compared to the scores of others in your team. Team is defined according to the Demography which is picked by your organization to define the teams";
        this.pointDescription3 = "Organization rank is decided basis your points as compared to points of everyone else in your organization..";
        this.ok = "OK";
        this.congratulations = "congratulations";
        this.trailblazerCongratulations = "You have received the Trailblazer Badge";
 this.laureateCongratulations = "Yay! You have received the Laureate Badge";
        this.wordsmithCongratulations = "You have received the Wordsmith Badge";
        this.architectCongratulations = "You have received the Architect Badge";
        this.chieftainCongratulations = "You have received the Chieftain Badge";
        this.submitResponseNote = "* For your response to get recorded, please click on Submit on the final page"

        this.Anxious = "Anxious";
        this.anxiousMoodLabel = "There is no harm in how you are feeling, treat yourself with kindness! Looking forward to hearing from you tomorrow";
        this.anxiousSnackBar = "We understand, we are here for you.";

        this.Uneasy = "Unhappy";
        this.uneasyMoodLabel = "It’s completely normal to have these days, but things will fall in place like they always have! Looking forward to hearing from you tomorrow";
        this.uneasySnackBar = "It’s okay to feel this way. We hope it improves!";

        this.Okay = "Okay";
        this.okayMoodLabel = "Some days are just meh. But others can be exceptional, we hope you have more of the latter! Looking forward to hearing from you tomorrow";
        this.okaySnackBar = "That’s fine, we hope it gets better!";

        this.Happy = "Happy";
        this.happyMoodLabel = "Tomorrow is a new dawn; a new day and we hope you feel even better! Looking forward to hearing from you tomorrow";
        this.happySnackBar="We are happy to see that!";

        this.Excited = "Excited";
        this.excitedMoodLabel ="You feel amazing, embrace it and come back with the same spirit! Looking forward to hearing from you tomorrow";
        this.excitedSnackBar = "That’s great, Lets keep this up!";

        this.heresYourMood = "Here is your today's mood";
        this.whatsYourMood = "What's your mood today?";
        this.submitResponseNote = "* For your response to get recorded, please click on Submit on the final page";
        this.questionsLeft = "Question are left";
        this.questionLeft = "Question is left";

        this.request = "Please rate the action taken"
        this.thankYouComment = "Thank you so much for giving your honest opinion on our actions. Click the (+) icon to give more..."
        this.actionOnFeedbackCommentPlaceholder = "What do you think of this action?";

        this.sixthOptionText = "Not enough info to rate";
        this.ICICISurveyCompletedAlready = "No pending statements to be answered";
        break;

      // Japanese - ja
      case "ja":
        this.welcomeText = "ようこそ";
        this.startSurveyText = "今すぐ調査を開始";
        this.surveyCompletedAlready = "アンケートの質問が利用できない";
        this.somethingWentWrong =
          "何かが間違っていた、もう一度やり直してください";
        this.nextButton = "次に";
        this.previousButton = "先の";
        this.submitButton = "送信";
        this.submittingButton = "提出";
        this.logOut = "ログアウト";
        this.selectLanguage = "言語の選択";
        this.tryAgainButton = "もう一度やり直してください";
        this.writeHerePlaceHolder = "コメントを入力してください";
        this.pleaseWaitWhileFetch = "アンケートを取得する間お待ちください";
        this.thankYouText =
          "このアンケートに参加していただきありがとうございます";
        this.termsConditions = "利用規約";
        this.privacyPolicy = "プライバシーポリシー";
        this.brandGuidelines = "ブランドガイドライン";
        this.contactNumber = "連絡先番号";
        this.emailText = "電子メール";
        this.aboutUsText = "我々について";
        this.changePassword = "パスワードの変更";
        this.Badge = " バッジ";
        this.EngagementTrends = "関与傾向";
        this.Perceptions = "認識";
        this.InvalidInputError =
          "HTML タグとスクリプト タグは許可されていません (<>)";
        this.openEndedPollPlaceholder = "ご意見を入力してください";
        this.floatingCommentLable = "さらに詳しく説明してください...";
        this.actionPointDialogHeader = "正直なフィードバックをありがとうございます。 経営陣は、受け取ったフィードバックに基づいて次の措置を講じました。」";
        this.badgeTitle =  "あなたのバッジ";
        this.orgRank = "組織ランク";
        this.teamRank = "チームランク";
        this.totalPoints = "総得点";


        this.badgeTitle = "あなたのバッジ";
        this.orgRank = "組織ランク";
        this.teamRank = "チームランク";
        this.totalPoints = "総得点";
        this.Youwon = "あなたが勝った";
        this.badges = "バッジ";
        this.badgeSingular = "バッジ";
        this.PointsSystem = "ポイント制";
        this.Points = "ポイント";
        this.Calculation = "計算";
        this.BadgeName = "バッジの名前";
        this.Description = "説明";
        this.Howarepointscalculated = "ポイントはどのように計算されますか？";
        this.Howteamrank = "チームのランクをどのように決定しますか？";
        this.HowOrgrank = "組織のランクをどのように決定しますか？";
        this.Trailblazer = "先駆者";
        this.Architect = "建築家";
        this.Chieftain = "チーフテン";
        this.Engineer = "エンジニア";
        this.Laureate = "受賞者";
        this.Wordsmith = "ワードスミス";
        this.trailblazerContent = "このバッジは、カルチャーパイオニアになるための第一歩を踏み出すためのあなたのものです。 アプリにログインすると1ポイント獲得できます。";
        this.architectContent = "このバッチに勝つために、あなたの連勝を続けて、5日間まっすぐに調査に答えてください。 アーキテクトバッジには5ポイントが付いています！";
        this.chieftainContent = "最高の栄誉であるこのバッジは、アクティブな参加者であり、コメントを5日間続けてドロップすると、30ポイントを獲得します。";
        this.engineerContent = "月に少なくとも3回、毎週の連勝を主張して、このバッジで10ポイントを獲得してください";
        this.laureateContent = "その場で受賞者詳細なコメントやフィードバックを残しますか？ このバッジで即座に15ポイントを獲得してください。";
        this.wordsmithContent = "このバッジは、簡単なコメントを残すことで3ポイントを獲得します。";
        this.pointDescription1 = "各バッジにはポイントが関連付けられています。 バッジを受け取ると、既存のポイントに追加され続ける関連ポイントも取得します。 ポイントは月ごとに計算され、毎月の初めにスコアが0にリセットされます";
        this.pointDescription2 = "チームランクは、チーム内の他のスコアと比較したポイントに基づいて決定されます。 チームは、チームを定義するために組織が選択した人口統計に従って定義されます";
        this.pointDescription3 = "組織のランクは、組織内の他の全員のポイントと比較して、ポイントに基づいて決定されます。」";
        this.ok = "オーケー";
        this.congratulations = "おめでとう";
        this.trailblazerCongratulations = "先駆者バッジを受け取りました";
 this.laureateCongratulations = "わーい！受賞者バッジを受け取りました";
        this.wordsmithCongratulations = "ワードスミスバッジを受け取りました";
        this.architectCongratulations = "アーキテクトバッジを受け取りました";
        this.chieftainCongratulations = "チーフテンバッジを受け取りました";
        this.submitResponseNote = "* 回答を記録するには、最終ページの[送信]をクリックしてください"

        this.Anxious = "気になる";
        this.anxiousMoodLabel = "気分に害はありません。優しくしてください！ 明日お返事をお待ちしております";
        this.anxiousSnackBar = "私たちはあなたのためにここにいることを理解しています。";

        this.Uneasy = "不幸";
        this.uneasyMoodLabel = "最近はまったく普通のことですが、いつものように物事はうまくいきます！ 明日お返事をお待ちしております";
        this.uneasySnackBar = "このように感じても大丈夫です。 改善されることを願っています！";

        this.Okay = "わかった";
        this.okayMoodLabel = "ある日はまあまあです。 しかし、他の人は例外的である可能性があります、私たちはあなたが後者をもっと持っていることを願っています！ 明日お返事をお待ちしております";
        this.okaySnackBar = "それで結構です、私たちはそれが良くなることを願っています！";

        this.Happy = "幸せ";
        this.happyMoodLabel = "明日は新しい夜明けです。 新しい日、私たちはあなたがさらに気分が良くなることを願っています！ 明日お返事をお待ちしております";
        this.happySnackBar="私たちはそれを見てうれしいです！";

        this.Excited = "興奮した";
        this.excitedMoodLabel ="あなたは素晴らしいと感じ、それを受け入れ、同じ精神で戻ってきます！ 明日お返事をお待ちしております";
        this.excitedSnackBar = "それは素晴らしいです、これを続けましょう！";

        this.heresYourMood = "これがあなたの今日の気分です";
        this.whatsYourMood = "今日の気分は？";
        this.submitResponseNote = "* 回答を記録するには、最終ページの[送信]をクリックしてください";
        this.questionsLeft = "つの質問が残っています";
        this.questionLeft = "つの質問が残っています";

        this.request = "取られた行動を評価してください"
        this.thankYouComment = "私たちの行動について正直な意見を述べてくれてありがとう。(+)アイコンをクリックして詳細を表示します。"
        this.actionOnFeedbackCommentPlaceholder = "この行動についてどう思いますか?";

        this.sixthOptionText = "評価するのに十分な情報がありません";

        this.ICICISurveyCompletedAlready = "回答する保留中のステートメントはありません";
        break;

      // Korean - ko
      case "ko":
        this.welcomeText = "환영";
        this.startSurveyText = "지금 설문조사 시작";
        this.surveyCompletedAlready = "설문조사 질문 사용할 수 없음";
        this.somethingWentWrong = "뭔가 잘못, 다시 시도하십시오";
        this.nextButton = "다음";
        this.previousButton = "이전";
        this.submitButton = "전송";
        this.submittingButton = "제출";
        this.logOut = "종료 응용 프로그램";
        this.selectLanguage = "언어 선택";
        this.tryAgainButton = "다시 한 번 시도해 보세요";
        this.writeHerePlaceHolder = "코멘트를 입력하십시오";
        this.pleaseWaitWhileFetch =
          "설문 조사를 가져오는 동안 기다려 주십시오.";
        this.thankYouText = "이 설문 조사에 참여해 주셔서 감사합니다.";
        this.termsConditions = "이용 약관";
        this.privacyPolicy = "개인정보 처리방침";
        this.brandGuidelines = "브랜드 가이드라인";
        this.contactNumber = "연락처 번호";
        this.emailText = "이메일";
        this.aboutUsText = "우리에 관해서";
        this.changePassword = "암호 변경";
        this.Badge = "배지";
        this.EngagementTrends = "참여 동향";
        this.Perceptions = "인식";
        this.InvalidInputError = "HTML 및 스크립트 태그는 허용되지 않음(<>)";
        this.openEndedPollPlaceholder = "피드백을 입력해 주세요";
        this.floatingCommentLable = "더 자세히 설명 해주세요 ...";
        this.actionPointDialogHeader = "정직한 피드백에 감사드립니다. 경영진은 피드백을받은 다음과 같은 조치를 취했습니다.";
        this.badgeTitle = "귀하의 배지";
        this.orgRank = "조직 순위";
        this.teamRank = "팀 순위";
        this.totalPoints = "총점";

        this.badgeTitle = "귀하의 배지";
        this.orgRank = "조직 순위";
        this.teamRank = "팀 순위";
        this.totalPoints = "총점";
        this.Youwon = "당신이 이겼습니다";
        this.badges = "배지";
        this.badgeSingular = "배지";
        this.PointsSystem = "포인트 시스템";
        this.Points = "포인트들";
        this.Calculation = "계산";
        this.BadgeName = "배지 이름";
        this.Description = "기술";
        this.Howarepointscalculated = "포인트는 어떻게 계산 되나요?";
        this.Howteamrank = "팀 순위는 어떻게 결정합니까?";
        this.HowOrgrank = "귀사의 순위는 어떻게 결정합니까?";
        this.Trailblazer = "선구자";
        this.Architect = "건축가";
        this.Chieftain = "지도자";
        this.Engineer = "공학자";
        this.Laureate = "수상자";
        this.Wordsmith = "워드 스미스";
        this.trailblazerContent = "이 배지는 문화 개척자가되기위한 첫 걸음을 내디뎠습니다. 앱에 로그인하면 1 점을 얻습니다.";
        this.architectContent = "이 배치를 획득하려면 연속 기록을 유지하고 5 일 동안 계속 설문 조사에 응답하세요. 건축가 뱃지는 5 점!";
        this.chieftainContent = "가장 영광스러운이 배지는 5 일 연속으로 적극적으로 참여하고 댓글을 달아 30 포인트를 획득합니다.";
        this.engineerContent = "한 달에 3 번 이상 주간 연속 기록을 달성하여이 배지로 10 점을 획득하세요.";
        this.laureateContent = "On The Spot 수상자 자세한 의견이나 피드백을 남기시겠습니까? 이 배지로 즉시 15 점을 획득하십시오 ..";
        this.wordsmithContent = "이 배지는 간단한 댓글을 남기면 3 점을 얻습니다.";
        this.pointDescription1 = "각 배지에는 관련된 포인트가 있습니다. 배지를 받으면 기존 포인트에 계속 추가되는 관련 포인트도 받게됩니다. 포인트는 월 단위로 계산되며 매월 초에 점수가 0으로 재설정됩니다.";
        this.pointDescription2 = "팀 순위는 팀의 다른 점수와 비교하여 점수를 기준으로 결정됩니다. 팀은 팀을 정의하기 위해 조직에서 선택한 인구 통계에 따라 정의됩니다.";
        this.pointDescription3 = "조직 순위는 조직의 다른 모든 사용자의 점수와 비교하여 점수를 기준으로 결정됩니다.";
        this.ok = "확인";
        this.congratulations = "축하합니다";
        this.trailblazerCongratulations = "개척자 배지를 받았습니다.";
 this.laureateCongratulations = "예이! 수상자 배지를 받았습니다.";
        this.wordsmithCongratulations = "Wordsmith 배지를 받았습니다.";
        this.architectCongratulations = "건축가 배지를 받았습니다.";
        this.chieftainCongratulations = "치프틴 배지를 받았습니다.";
        this.submitResponseNote = "* 응답을 기록하려면 마지막 페이지에서 제출을 클릭하십시오."

        this.Anxious = "불안해하는";
        this.anxiousMoodLabel = "당신의 기분에 아무런 해가 없습니다. 친절하게 자신을 대하십시오! 내일 당신의 소식을 기대합니다";
        this.anxiousSnackBar = "우리는 이해합니다, 우리는 당신을 위해 여기 있습니다";

        this.Uneasy = "불행한";
        this.uneasyMoodLabel = "요즘은 완전히 정상이지만 항상 그랬던 것처럼 모든 것이 제자리에 떨어질 것입니다! 내일 당신의 소식을 기대합니다";
        this.uneasySnackBar = "이렇게 느끼셔도 괜찮습니다. 개선되기를 바랍니다!";

        this.Okay = "괜찮아";
        this.okayMoodLabel = "어떤 날은 그냥 meh입니다. 그러나 다른 사람들은 예외적일 수 있습니다. 우리는 당신이 후자를 더 많이 가지고 있기를 바랍니다! 내일 당신의 소식을 기대합니다";
        this.okaySnackBar = "괜찮습니다. 더 나아지길 바랍니다!";

        this.Happy = "행복하다";
        this.happyMoodLabel = "내일은 새로운 새벽입니다. 새로운 하루를 보내고 기분이 더 나아지길 바랍니다! 내일 당신의 소식을 기대합니다";
        this.happySnackBar="우리는 그것을 보게되어 기쁩니다!";

        this.Excited = "흥분한";
        this.excitedMoodLabel ="당신은 놀라움을 느끼고 그것을 포용하고 같은 정신으로 돌아옵니다! 내일 당신의 소식을 기대합니다";
        this.excitedSnackBar = "훌륭합니다. 계속 진행하겠습니다!";

        this.heresYourMood = "오늘의 기분은 이렇다";
        this.whatsYourMood = "오늘 기분이 어때?";
        this.submitResponseNote = "* 응답을 기록하려면 마지막 페이지에서 제출을 클릭하십시오.";
        this.questionsLeft = "개의 질문이 남았습니다";
        this.questionLeft = "개의 질문이 남았습니다";

        this.request = "취한 행동을 평가해 주세요."
        this.thankYouComment = "우리의 행동에 대한 솔직한 의견을 주신 것에 대해 대단히 감사합니다. (+) 아이콘을 클릭하여 더 많은 것을 제공합니다..."
        this.actionOnFeedbackCommentPlaceholder = "이 행동에 대해 어떻게 생각하십니까?";

        this.sixthOptionText = "평가할 정보가 충분하지 않음";

        this.ICICISurveyCompletedAlready = "응답할 보류 중인 진술이 없습니다.";
        break;

      default:
        this.welcomeText = "Welcome";
        this.startSurveyText = "start survey now";
        this.surveyCompletedAlready = "Survey questions not available";
        this.somethingWentWrong = "Something went wrong, Please try again";
        this.nextButton = "Next";
        this.previousButton = "Previous";
        this.submitButton = "Submit";
        this.submittingButton = "Submitting";
        this.logOut = "Logout";
        this.selectLanguage = "Select Language";
        this.tryAgainButton = "TRY AGAIN";
        this.writeHerePlaceHolder = "Please enter comment";
        this.pleaseWaitWhileFetch = "Please wait while we fetch your survey";
        this.thankYouText = "Thank you for your participation in this survey";
        this.termsConditions = "Terms & Conditions";
        this.privacyPolicy = "Privacy Policy";
        this.brandGuidelines = "Brand Guidelines";
        this.contactNumber = "Contact Number";
        this.emailText = "Email";
        this.aboutUsText = "About Us";
        this.changePassword = "Change Password";
        this.Badge = "Badges"
        this.EngagementTrends = "Engagement Trends";
        this.Perceptions = "Perceptions";
        this.InvalidInputError = "Html & Script tags not allowed (<>)";
        this.openEndedPollPlaceholder = "Please enter your feedback";
        this.floatingCommentLable = "please elaborate further...";
        this.actionPointDialogHeader = "Thanks for your honest feedback. The management has taken following action(s) basis feedback received";
        this.badgeTitle = "Your Badges";
        this.orgRank = "Organization Rank";
        this.teamRank = "Team rank";
        this.totalPoints = "Total Points";

        this.badgeTitle = "Your Badges";
        this.orgRank = "Organization Rank";
        this.teamRank = "Team Rank";
        this.totalPoints = "Total Points";
        this.Youwon = "You won";
        this.badges = "badge";
        this.badgeSingular = "badges";
        this.PointsSystem = "Point System";
        this.Points = "Points";
        this.Calculation = "Calculation";
        this.BadgeName = "Badge Name";
        this.Description = "Description";
        this.Howarepointscalculated = "How are points calculated?";
        this.Howteamrank = "How do we decide your team rank?";
        this.HowOrgrank = "How do we decide your organization rank?";
        this.Trailblazer = "Trailblazer";
        this.Architect = "Architect";
        this.Chieftain = "Chieftain";
        this.Engineer = "Engineer";
        this.Laureate = "Laureate";
        this.Wordsmith = "Wordsmith";
        this.trailblazerContent = "This badge is yours for taking the first step to becoming a Culture Pioneer. You win 1 point for logging into the app.";
        this.architectContent = "To win this batch, keep your streak going and answer the survey for 5 days straight. An architect badge comes with 5 points!";
        this.chieftainContent = "The highest honor, this badge wins you 30 Points for being an active participant and dropping your comments for 5 days in a row.";
        this.engineerContent = "Win 10 points with this badge by claiming your weekly streak at least 3 times in a month";
        this.laureateContent = "On The Spot A Laureate Leaving a detailed comment or feedback? Win 15 points instantly with this badge..";
        this.wordsmithContent = "This badge wins you 3 points for leaving a brief comment.";
        this.pointDescription1 = "Each Badge has points associated with it. As you receive a badge, you also get the associated points which keep getting added to your existing points. Points are calculated month wise and at the start of every month the score gets reset to 0";
        this.pointDescription2 = "Team Rank is decided basis your points as compared to the scores of others in your team. Team is defined according to the Demography which is picked by your organization to define the teams";
        this.pointDescription3 = "Organization rank is decided basis your points as compared to points of everyone else in your organization..";
        this.ok = "OK";
        this.congratulations = "congratulations";
        this.trailblazerCongratulations = "You have received the Trailblazer Badge";
 this.laureateCongratulations = "Yay! You have received the Laureate Badge";
        this.wordsmithCongratulations = "You have received the Wordsmith Badge";
        this.architectCongratulations = "You have received the Architect Badge";
        this.chieftainCongratulations = "You have received the Chieftain Badge";
        this.submitResponseNote = "* For your response to get recorded, please click on Submit on the final page"


        this.Anxious = "Anxious";
        this.anxiousMoodLabel = "There is no harm in how you are feeling, treat yourself with kindness! Looking forward to hearing from you tomorrow";
        this.anxiousSnackBar = "We understand, we are here for you.";

        this.Uneasy = "Uneasy";
        this.uneasyMoodLabel = "It’s completely normal to have these days, but things will fall in place like they always have! Looking forward to hearing from you tomorrow";
        this.uneasySnackBar = "It’s okay to feel this way. We hope it improves!";

        this.Okay = "Okay";
        this.okayMoodLabel = "Some days are just meh. But others can be exceptional, we hope you have more of the latter! Looking forward to hearing from you tomorrow";
        this.okaySnackBar = "That’s fine, we hope it gets better!";

        this.Happy = "Happy";
        this.happyMoodLabel = "Tomorrow is a new dawn; a new day and we hope you feel even better! Looking forward to hearing from you tomorrow";
        this.happySnackBar="We are happy to see that!";

        this.Excited = "Excited";
        this.excitedMoodLabel ="You feel amazing, embrace it and come back with the same spirit! Looking forward to hearing from you tomorrow";
        this.excitedSnackBar = "That’s great, Lets keep this up!";

        this.heresYourMood = "Here is your todays mood";
        this.whatsYourMood = "What's your mood today?";
        this.submitResponseNote = "* For your response to get recorded, please click on Submit on the final page";
        this.questionsLeft = "Questions are left";
        this.questionLeft = "Question is left";

        this.request = "Please rate the action taken"
        this.thankYouComment = "Thank you so much for giving your honest opinion on our actions. Click the (+) icon to give more..."
        this.actionOnFeedbackCommentPlaceholder = "What do you think of this action?";

        this.sixthOptionText = "Not enough info to rate";

        this.ICICISurveyCompletedAlready = "No pending statements to be answered";
        break;
    }
  }

  public getLanguageViewvalue(language) {
    switch (language) {
      case "en":
        return "English";
      case "hi":
        return "Hindi";
      case "mr":
        return "Marathi";
      case "bn":
        return "Bengali";
      case "te":
        return "Telgu";
      case "ta":
        return "Tamil";
      case "gu":
        return "Gujarati";
      case "kn":
        return "Kannada";
      case "or":
        return "Oriya";
      case "ml":
        return "Malayalam";
      case "pa":
        return "Punjabi";
      case "ur":
        return "Urdu";
      case "es":
        return "Spanish";
      case "pt":
        return "Portuguese";
      case "ms":
        return "Malay";
      case "ru":
        return "Russian";
      case "it":
        return "Italian";
      case "fr":
        return "French";
      case "de":
        return "German";
      case "ar":
        return "Arabic";
      case "as":
        return "Assamese";
      case "si":
        return "Sinhala";
      case "ja":
        return "Japanese";
      case "ko":
        return "Korean";
      default:
        return "English";
    }
  }
}
