import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,

    HttpErrorResponse
} from '@angular/common/http';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';
import { AccountAPI } from '../../shared/apis/account.api';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public storage: StorageService, private accountAPI: AccountAPI, private route: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'X-Client-Type': 'AngularWebApp'
      }
    });
    if (this.accountAPI.getJwtToken()) {
      request = this.addToken(request, this.accountAPI.getJwtToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (window.location.pathname === '/login' ||
        window.location.pathname === '/account/registration' ||
        window.location.pathname === '/account/reset-password'
        ) {
          //alert("You don't have access to this feature!");
          //this.gotoLogin();
          //return next.handle(request);
        }
        else {
          return this.handle401Error(request, next);
        }
      }
      else if (error instanceof HttpErrorResponse && error.status === 400) {
        console.log(error);
        if (error.error && error.error.error === 'invalid_grant' && error.error.error_description !== 'invalid_username_or_password') {
          this.gotoLogin();
        }
        return next.handle(request);
      }
      else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private gotoLogin() {
    localStorage.clear();
    //window.location.reload();
    this.route.navigate(['login']);
    this.refreshTokenSubject.next("");

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.accountAPI.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          return next.handle(this.addToken(request, token.access_token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
