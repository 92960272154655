import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from '@app/core/services/shared.service';
import { AllQuestionsArrayModel } from '@app/core/models/survey.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyAPI {
  private apiUrl = '';
  private stsUrl = '';
  public allQuestionsArray: AllQuestionsArrayModel;
  public actionPoints: any;
  public feedbackRecall: any;
  public moodFlagStatus: boolean;
  public moodId: any;

  constructor(private sharedService: SharedService, private http: HttpClient) {
    this.apiUrl = this.sharedService.getAPIUrl();
    this.stsUrl = this.sharedService.getSTSUrl();
    this.allQuestionsArray = {questions:[]};
    this.actionPoints = [];
    this.feedbackRecall = [];
  }

  /*************************************************************
        Description: This method will get all qutions with options
    **************************************************************/
  getQuestions() {
    const utcOffset = new Date().getTimezoneOffset();
    const languageId = 6;
    const httpRequestObject = {
      utcOffset, languageId
    };
    return this.http.post(this.apiUrl + '/api/SurveyApp/GetQuestions', httpRequestObject);
  }

  /*************************************************************
        Description: This method will submit survey with selected options
    **************************************************************/
  submitSurveyResponse(submitArray) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(submitArray);
    // console.log(body);
    return this.http.post(
      this.apiUrl + '/api/SurveyApp/SubmitSurveyResponse',
      body,
      options
    );
  }

  /*************************************************************
        Description: This method will get survey data
    **************************************************************/
  getSurvey(id) {
    return this.http.get(this.apiUrl + '/api/Survey/GetSurveyDetails?id=' + id);
  }

  /*************************************************************
        Description: This method will get employee information
    **************************************************************/
  getSurveyEmployeeInfo() {
    return this.http.get(this.apiUrl + '/api/Survey/GetSurveyEmployeeInfo');
  }

  /*************************************************************
        Description: This method will get instructions and thank you content
        https://gptwapi.azurewebsites.net//api/templates/getall
    **************************************************************/
  getInstructions(object) {
    return this.http.post(this.apiUrl + '/api/templates/getall', object);
  }

  /*************************************************************
        Description: This method will get positive scores month wise for the logged in employee
        https://gptwapi.azurewebsites.net/api/survey/getIndividualEngagementTrend
    **************************************************************/
  getIndividualEngagementTrend(object) {
    // console.log(object);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.http.post(
      this.apiUrl + '/api/SurveyApp/getIndividualEngagementTrend',
      object,
      options
    );
  }

  GetSlicedScoresExcludingSpecificEmployee(object) {
    // console.log(object);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.http.post(
      this.apiUrl + '/api/SurveyApp/GetSlicedScoresExcludingSpecificEmployee',
      object,
      options
    );
  }

  getAllSubDimensionsAndTags(clientID: number) {
    return this.http.get(
      this.apiUrl +
        '/api/Survey/GetAllSubDimensionsAndTagsESLL?clientId=' +
        clientID
    );
  }

  getSurveyEmployeeTags(employeeId: number) {
    return this.http.get(
      this.apiUrl +
        '/api/Survey/GetSurveyEmployeeTags?employeeId=' +
        employeeId
    );
  }

  // API to get EngagementTrend Flag for a client
  getEngagementTrendFlag(clientId: number) {
    return this.http.get(this.apiUrl + '/api/SurveyApp/GetEngagementTrendFlag?clientID=' + clientId);
  }

   // API to get Perception Flag for a client
   getPerceptionFlag(clientID: number) {
     return this.http.get(this.apiUrl + '/api/SurveyApp/GetPerceptionFlag?clientID=' + clientID);
  }


  // Api to set the preffered Languange of an employee
  setLanguage(object) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };
    return this.http.post(
      this.apiUrl + '/api/Employees/setPreferredLanguage',
      object,
       options
    );
  }


    // API to get the preffered Language of an employee
    getLanguage(employeeId: number) {
      return this.http.get(this.apiUrl + '/api/Employees/getPreferredLanguage?employeeId=' +
       employeeId, {responseType: 'text'} );
    }

    // API to get IP-Address
    public getIPAddress() {
      return this.http.get('http://api.ipify.org');
    }

  // This method adds the user log details (i.e. browser, OS, ip, device etc)
     addUserLog(object) {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return this.http.post(
        this.apiUrl + '/api/UserLog/EnterUserLog',
        object, options
      );
    }


     // API to get  random fact from the fact table
  getRandomFact() {
    return this.http.get(this.apiUrl + '/api/SurveyApp/GetRandomFact');
  }

  /*********************************************
     * Check client is enable to chat feature
     **********************************************/
   IsChatEnabledForClient(ClientId){
    return this.http.get(this.apiUrl + '/api/Chat/IsChatEnabledForClient?ClientId=' + ClientId);
}
GetChatDetails(ChatId, LoginEmpId, ClientId, RecEmployeeId) {
  return this.http.get(
    this.apiUrl +
      "/api/chat/GetChatDetails?ChatId=" +
      ChatId +
      "&UserId=" +
      LoginEmpId +
      "&ClientId=" +
      ClientId +
      "&RecEmployeeId=" +
      RecEmployeeId
  );
}
}
