import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { InternetConnectivityService } from './internet-connectivity.service';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectivityGuard implements CanActivate {
  constructor(
    private router: Router,
    private internetConnectivityService: InternetConnectivityService
  ) {}

  canActivate(): boolean {
    if (!this.internetConnectivityService.isOnline) {
      this.router.navigate(['/thank-you'], { queryParams: { isInternetLost: true }});
      return false;
    }
    return true;
  }
}
