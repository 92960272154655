import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  showProductBranding: any
  constructor() { }

  ngOnInit() {
this.showProductBranding = JSON.parse(localStorage.getItem('showBranding'));
  }

  mailUs() {
    const mailText = "mailto:admin@ceoculturedashboard.com";
    window.location.href = mailText;
  }
}
