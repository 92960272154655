
<div class="footer">
  <!-- <div class="row" fxLayoutAlign="end center">
    <img  src="../../../assets/images/medal.png"
    alt="badge" height="50" width="50">
  </div> -->
  <div style="width:100%" class="copyright">
    <div *ngIf="showProductBranding == true" fxLayout="row wrap" class="footer-class" fxLayoutGap="1rem" fxLayoutAlign="space-between">
      <!-- <a href="https://www.greatplacetowork.in/terms-and-conditions/" target="_blank">Terms & Conditions</a> -->
      <a href="https://www.greatplacetowork.in/privacy-policy/" target="_blank">Privacy Policy</a>
      <!-- <a href="https://www.greatplacetowork.in/brand-guidelines/" target="_blank">Brand Guidelines</a>
      <a href="https://www.greatplacetowork.in/frequently-asked-questions/" target="_blank">FAQ</a>
      <a href="tel: 02266670700"> <span style="color: black;text-decoration: none">Contact Number: </span> 022 -
        66670700</a> -->
      <a (click)="mailUs()" class="cursor-class" style="padding-right: 9px"><span style="color: black;text-decoration: none">Email:
        </span>admin@ceoculturedashboard.com</a>
    </div>
  </div>
</div>
