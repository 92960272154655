import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { SwPush } from "@angular/service-worker";

@Injectable({
  providedIn: "root"
})
export class NotificationMiddlewareService {
  public pushNotificationStatus = {
    isSubscribed: false,
    isSupported: false
  };
  public apiUrl = "";

  public sub = {
    endpoint: "",
    p256DH: "",
    auth: ""
  };

  public vapIdPublicKey =
    "BE5ogTaxhF_Cj6x6_UwtBdf21DCFgbX7hl5azSuCCG0gyIus7yAxTkql6g6uhV2afbmBjGn1A7nhnanSj4DjV2I";  

  constructor(private http: HttpClient, private swPush: SwPush) {
    this.apiUrl = environment.appConstants.apiUrl;
  }

  /*****************************************************************
subscribeToNotification()
purpose : this method will take user's conscent before displaying push notifications
*******************************************************************/
  public subscribeToNotification() {
    console.log("subscribing to notifications");
    if (this.swPush.isEnabled) {
      this.pushNotificationStatus.isSupported = true;
      this.swPush
        .requestSubscription({
          serverPublicKey: this.vapIdPublicKey
        })
        .then(response => {
          console.log(response);
          const jsonresponse = response.toJSON();
          console.log(jsonresponse);
          this.pushNotificationStatus.isSubscribed = true;
          this.sub.endpoint = response.endpoint;
          this.sub.p256DH = jsonresponse.keys["p256dh"];
          this.sub.auth = jsonresponse.keys["auth"];
          this.addPushSubscriber().subscribe(
            res => {
              console.log(res);
            },
            error => {
              console.log(error);
            }
          );
        })
        .catch(err =>
          console.error("Could not subscribe to notifications", err)
        );
    } else {
      console.log("Service worker is not supported");
    }
  }

  /*****************************************************************
addPushSubscriber()
purpose : this method will call api to save the browser credentials.
*******************************************************************/
  addPushSubscriber() {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    const body = JSON.stringify(this.sub);
    console.log(body);

    return this.http.post(
      this.apiUrl + "/api/Survey/Subscribe",
      body,
      options
    );
  }

/*****************************************************************
notify()
purpose : custom method to display push notification in browser.
*******************************************************************/
  notify(title: string, options?: PushNotification): any {
    let self = this;
    return new Observable(function(obs) {
      if (!("Notification" in window)) {
        console.log("Notifications are not available in this environment");
        obs.complete();
      }
      if (!self.pushNotificationStatus.isSubscribed) {
        console.log(
          "The user hasn't granted you permission to send push notifications"
        );
        obs.complete();
      }
      let _notify = new Notification(title, options);
      _notify.onshow = function(e) {
        return obs.next({
          notification: _notify,
          event: e
        });
      };
      _notify.onclick = function(e) {
        window.open('https://app.ceoculturedashboard.com', '_blank');
        return obs.next({
          notification: _notify,
          event: e
        });
      };
      _notify.onerror = function(e) {
        return obs.error({
          notification: _notify,
          event: e
        });
      };
      _notify.onclose = function() {
        return obs.complete();
      };
    });
  }

  generateNotification(title, body): void {
    let options = {
      body: body,
      requireInteraction: true,
      sticky: true
    };
    let response = this.notify(title, options).subscribe();
  }

  showNotification(title, body) {
    this.generateNotification(title, body);
  }
}
export interface PushNotification {
  body?: string;
  icon?: string;
  tag?: string;
  data?: any;
  renotify?: boolean;
  silent?: boolean;
  sound?: string;
  noscreen?: boolean;
  sticky?: boolean;
  dir?: "auto" | "ltr" | "rtl";
  lang?: string;
  vibrate?: number[];
  requireInteraction?: boolean;
}
