<p>{{message}}</p>

<!-- 

<div class="center-container">
    <div class="login-card">
      <div class="card-content">
        <h2 class="error-message">Incorrect Login Details</h2>
        <p class="error-description">
          The username or password you entered is incorrect. Please check for extra spaces in between or after your username or password. If the issue persists, please try again after some time.
        </p>
        <button class="login-button" (click)="goToLoginPage()">Go to Login Page</button>
      </div>
    </div>
  </div>
   -->
  
  