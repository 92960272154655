import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class EmployeeDataService {
    public employeeName = "";
    hideLogout = new BehaviorSubject<boolean>(false);

    public employeeDataEventEmitter: EventEmitter<any> = new EventEmitter();

    constructor() {

    }
    public employeeDataEventListner() {
        return this.employeeDataEventEmitter;
    }

    setHideLogout(value: boolean) {
        this.hideLogout.next(value);
      }
}