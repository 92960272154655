import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { LayoutModule } from "@app/layout/layout.module";
import { CoreModule } from "@app/core/core.module";
import { RoutingState } from "./core/services/history-routing";
import { MdePopoverModule } from "@material-extended/mde";
import { PageNotFoundComponent } from "./modules/page-not-found/page-not-found.component";
import { PerceptionModule } from "./modules/perception/perception.module";
import { EngagementTrendModule } from "./modules/engagement-trend/engagement-trend.module";
import { SidenavModule } from "./modules/sidenav/sidenav.module";
import { ToastrModule } from "ngx-toastr";
import { NgApexchartsModule } from "ng-apexcharts";
import { ManagerProfileService } from "./core/services/managerprofile.service";

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    CoreModule,
    LayoutModule,
    MdePopoverModule,
    PerceptionModule,
    EngagementTrendModule,
    SidenavModule,
    NgApexchartsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
  ],
  providers: [RoutingState, ManagerProfileService],
  bootstrap: [AppComponent],
})
export class AppModule {}
