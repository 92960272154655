import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuard } from "@app/core/router-guards/auth-guard";
import { PageNotFoundComponent } from "./modules/page-not-found/page-not-found.component";
import { IncorrectlogindetailsComponent } from './modules/incorrectlogindetails/incorrectlogindetails.component';
import { InternetConnectivityGuard } from "./internet-connectivity.guard";

const routes: Routes = [
  { path: "", redirectTo: "survey-dashboard", pathMatch: "full" },
  // { path: "login", loadChildren: "./modules/login/login.module#LoginModule" },
  { path: "login", loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)},
  // { path: 'passwordlesslogin', loadChildren: './modules/passwordlesslogin/passwordlesslogin.module#PasswordlessloginModule' },
  { path: 'passwordlesslogin', loadChildren:  () => import('./modules/passwordlesslogin/passwordlesslogin.module').then(m => m.PasswordlessloginModule)},
  {
    path: "survey",
    loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  },
  {
    path: "thank-you",
    loadChildren: () => import('./modules/thank-you-page/thank-you.module').then(m => m.ThankYouModule),
    canActivate: [AuthGuard],
  },
  {
    path: "survey-dashboard",
    loadChildren:
      () => import('./modules/welcome-page/welcome-page.module').then(m => m.WelcomePageModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  },
  {
    path: "account",
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
  },
  { path: "poll", loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule) },
  {
    path: "badges",
    loadChildren: () => import('./modules/badges/badges.module').then(m => m.BadgesModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  },

  {
    path: "perception",
    loadChildren: () => import('./modules/perception/perception.module').then(m => m.PerceptionModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  },
  {
    path: "engagement-trend",
    loadChildren:
      () => import('./modules/engagement-trend/engagement-trend.module').then(m => m.EngagementTrendModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  },

  {
    path: "actioncorner",
    loadChildren:
      () => import('./modules/actioncorner/actioncorner.module').then(m => m.ActionCornerModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  },
  // { path: 'sidenav', loadChildren: './modules/sidenav/sidenav.module#SidenavModule'},
  {
    path: "chat",
    loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  },
  {
    path: "incorrectlogindetails",
    component: IncorrectlogindetailsComponent
  },
       
  {
    path: "managerprofile",
    loadChildren:() => import('./modules/managerprofile/managerprofile.module').then(m => m.ManagerProfileModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  },
  {
    path: "error-page",
    loadChildren: () => import('./modules/error-page/error-page.module').then(m => m.ErrorPageModule),
    canActivate: [AuthGuard,InternetConnectivityGuard],
  }, 
  { path: "**", component: PageNotFoundComponent },

  // { path: 'layout', loadChildren: './layout/layout.module#LayoutModule' },
  // {path: 'layout', component: LayoutComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: false,
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
